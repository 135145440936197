import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  Stack,
  Tooltip,
  useMediaQuery,
  Snackbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/navbar/Layout";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import timerIcon from "../../assets/timer.png";
import info from "../../assets/info.png";
import image1 from "../../assets/game board.png";
import SearchBar from "../../components/Searchbar";
import { useDispatch, useSelector } from "react-redux";
import { setLevel1Data } from "../../redux/slices/level1DataSlice";
import {
  setLevel2Coins,
  setLevel2CoinsWithSticker,
  setLevel2PlacedCoins,
} from "../../redux/slices/level2DataSlice";
import { useTheme } from "@emotion/react";

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

// -------------------- DRAGGABLE COIN (List) --------------------
const DraggableCoin = ({ label, index }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "COIN",
    // When dragging from the list, no sourcePlaceholder is provided.
    item: { label, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        fill: "#FBFFFF",
        strokeWidth: "1.01px",
        stroke: "#0F1621",
        filter: "drop-shadow(2.02px 2.02px 0px #0F1621)",
        width: "clamp(60px, 15vw, 85px)",
        height: "clamp(60px, 15vw, 85px)",
        flexShrink: "0",
        borderRadius: "50%",
        backgroundColor: "#FBFFFF",
        fontSize: "clamp(10px, 2vw, 14px)",
        fontWeight: "bold",
        color: "#0F1621",
        transition: "all 0.3s ease-in-out",
        whiteSpace: "normal",
        wordWrap: "break-word",
        lineHeight: "1.2",
        padding: "5px",
        boxSizing: "border-box",
        overflow: "hidden",
        maxWidth: "100%",
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <Typography
        sx={{
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: {
            xs: "10px",
            sm: "10px",
            md: "12px",
            lg: "12px",
          },
          fontWeight: { xs: 500, md: 600 },
        }}
      >
        {label}
      </Typography>
    </div>
  );
};

// -------------------- DRAGGABLE COIN (Within Placeholder) --------------------
const DraggableCoinPlaceholder = ({
  coinLabel, // the actual coin label for drag/drop logic
  displayLabel, // what is shown to the user (count if multiple)
  sourcePlaceholder,
  onDragEnd,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "COIN",
    item: { label: coinLabel, sourcePlaceholder },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (!dropResult) {
        onDragEnd(item.label, sourcePlaceholder);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <Box
      ref={drag}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        transition: "all 0.3s ease-in-out",
        whiteSpace: "normal",
        boxSizing: "border-box",
        opacity: isDragging ? 0.5 : 1,
        color: "black",
        fontSize: "7pt",
        wordWrap: "break-word",
        overflowWrap: "break-word",
        width: { xs: "40px", sm: "50px", md: "50px", lg: "60px" },
        height: { xs: "40px", sm: "50px", md: "50px", lg: "60px" },
        lineHeight: "1.1",
        borderRadius: "50%",
        "&:hover": {
          background: "rgba(0, 255, 0, 0.2)",
        },
      }}
    >
      <Typography
        sx={{
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: { xs: "8px", sm: "10px", md: "10px" },
        }}
      >
        {displayLabel}
      </Typography>
    </Box>
  );
};

// -------------------- PLACEHOLDER DROP TARGET --------------------
// This component renders a drop target (the circle on the image).
const CoinDropPlaceholder = ({
  x,
  y,
  placeholderId,
  coins,
  onDrop,
  onUndo,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: "COIN",
    drop: (item) => {
      onDrop(item, placeholderId);
      // Return a drop result so that the drag "end" callback can detect a valid drop.
      return { droppedOn: placeholderId };
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <Box
      ref={drop}
      sx={{
        position: "absolute",
        top: y,
        left: x,
        width: { xs: "50px", sm: "50px", md: "60px" },
        height: { xs: "50px", sm: "50px", md: "60px" },
        borderRadius: "50%",
        backgroundColor: isOver ? "rgba(0, 255, 0, 0.2)" : "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {coins && coins.length > 0 && (
        <Tooltip
          title={coins.join(", ") || null}
          arrow
          sx={{
            "& .MuiTooltip-tooltip": {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              color: "white",
              fontSize: "12px",
              maxWidth: "200px",
              padding: "8px",
              lineHeight: "1.4",
            },
          }}
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: { offset: [0, -8] },
              },
            ],
          }}
        >
          <div>
            {isOver && coins?.length > 1 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                  fontSize: "12px",
                  whiteSpace: "nowrap",
                  backgroundColor: isOver
                    ? "rgba(0, 0, 0, 0.5)"
                    : "rgba(0, 0, 0, 0.3)",
                  borderRadius: "4px",
                  padding: "4px 10px",
                }}
              >
                <Typography variant="caption">
                  {isOver
                    ? coins.join(", ")
                    : coins.length > 1
                    ? coins.length
                    : coins[0]}
                </Typography>
              </Box>
            )}

            {/* Only show the last placed coin */}
            <DraggableCoinPlaceholder
              coinLabel={coins[coins.length - 1]}
              displayLabel={
                !isOver &&
                (coins.length > 1 ? coins.length : coins[coins.length - 1])
              }
              sourcePlaceholder={placeholderId}
              onDragEnd={onUndo}
            />
          </div>
        </Tooltip>
      )}
    </Box>
  );
};

// -------------------- DROPPABLE IMAGE (Game Board) --------------------
const DroppableImage = ({
  imageSrc,
  placeholders,
  setCoins,
  placedCoins,
  setPlacedCoins,
}) => {
  // When a coin is dropped (from list or another placeholder) onto a new placeholder.
  const handleCoinDrop = (item, targetPlaceholderId) => {
    // If the coin comes from another placeholder, remove only the last placed coin
    // from that source placeholder.
    if (
      item.sourcePlaceholder &&
      item.sourcePlaceholder !== targetPlaceholderId
    ) {
      setPlacedCoins((prev) => {
        const updated = { ...prev };
        const sourceArr = updated[item.sourcePlaceholder];
        if (sourceArr && sourceArr.length > 0) {
          // Remove only the last occurrence (if it matches, pop; otherwise remove the last index of that label)
          if (sourceArr[sourceArr.length - 1] === item.label) {
            sourceArr.pop();
          } else {
            const idx = sourceArr.lastIndexOf(item.label);
            if (idx !== -1) {
              sourceArr.splice(idx, 1);
            }
          }
          if (sourceArr.length === 0) {
            delete updated[item.sourcePlaceholder];
          }
        }
        return updated;
      });
    }
    // Add the coin to the target placeholder.
    setPlacedCoins((prev) => ({
      ...prev,
      [targetPlaceholderId]: [...(prev[targetPlaceholderId] || []), item.label],
    }));
    // Also remove the coin from the available coins list (if it exists there).
    setCoins((prev) => prev.filter((coin) => coin.label !== item.label));
  };

  // Undo: when a coin is dragged out (not dropped on a valid target),
  // remove only the last placed coin from the source placeholder and
  // add it back to the coins list if it isn’t already there.
  const handleUndo = (coinLabel, placeholderId) => {
    setPlacedCoins((prev) => {
      const updated = { ...prev };
      const arr = updated[placeholderId] || [];
      if (arr.length > 0) {
        if (arr[arr.length - 1] === coinLabel) {
          arr.pop();
        } else {
          const idx = arr.lastIndexOf(coinLabel);
          if (idx !== -1) {
            arr.splice(idx, 1);
          }
        }
        if (arr.length === 0) delete updated[placeholderId];
      }
      return updated;
    });
    setCoins((prev) => {
      // Avoid duplication if the coin already exists in the list.
      if (prev.some((c) => c.label === coinLabel)) return prev;
      return [...prev, { label: coinLabel }];
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {/* Game Board Image */}
      <img
        src={imageSrc}
        alt="Game Board"
        style={{
          width: "100%",
          height: "auto",
          pointerEvents: "none",
          borderRadius: "12px",
          boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
        }}
      />

      {/* Render each placeholder */}
      {placeholders.map((pos) => (
        <CoinDropPlaceholder
          key={pos.id}
          x={pos.x}
          y={pos.y}
          placeholderId={pos.id}
          coins={placedCoins[pos.id] || []}
          onDrop={handleCoinDrop}
          onUndo={handleUndo}
        />
      ))}
    </Box>
  );
};

const coinPlaceholderKeys = [
  "customer_1_coins",
  "customer_2_coins",
  "customer_3_coins",
  "customer_4_coins",
  "customer_employee_coins",
  "customer_employee_strategy_coins",
  "customer_strategy_coins",
  "employee_1_coins",
  "employee_2_coins",
  "employee_3_coins",
  "employee_4_coins",
  "employee_strategy_coins",
  "strategy_1_coins",
  "strategy_2_coins",
  "strategy_3_coins",
  "strategy_4_coins",
];

const Page5 = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const level2Coins = useSelector(
    (state) => state?.level2Data?.level2Data?.level2Coins
  );
  const level2PlacedCoins = useSelector(
    (state) => state?.level2Data?.level2Data?.level2PlacedCoins
  );
  const level2CoinswithSticker = useSelector(
    (state) => state.level2Data?.level2Data?.level2CoinswithSticker
  );
  const [timer, setTimer] = useState(900);
  const [showModal, setShowModal] = useState(false);
  const [newCoin, setNewCoin] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const placeholders = [
    { id: "customer_1_coins", x: "27%", y: "8%" },
    { id: "customer_2_coins", x: "13%", y: "17%" },
    { id: "customer_3_coins", x: "12%", y: "35%" },
    { id: "customer_4_coins", x: "24%", y: "27%" },
    { id: "customer_employee_coins", x: "43%", y: "19%" },
    { id: "customer_employee_strategy_coins", x: "43%", y: "37%" },
    { id: "customer_strategy_coins", x: "28%", y: "44%" },
    { id: "employee_1_coins", x: "59%", y: "9%" },
    { id: "employee_2_coins", x: "73%", y: "17%" },
    { id: "employee_3_coins", x: "62%", y: "27%" },
    { id: "employee_4_coins", x: "74%", y: "35%" },
    { id: "employee_strategy_coins", x: "57%", y: "44%" },
    { id: "strategy_1_coins", x: "43%", y: "59%" },
    { id: "strategy_2_coins", x: "27%", y: "63%" },
    { id: "strategy_3_coins", x: "59%", y: "62%" },
    { id: "strategy_4_coins", x: "43%", y: "74%" },
  ];
  const [coins, setCoins] = useState(level2Coins || []);
  const [selectedLetter, setSelectedLetter] = useState("A");
  const [placedCoins, setPlacedCoins] = useState(level2PlacedCoins || null);
  const employeeDetails = JSON.parse(localStorage.getItem("employee"));

  const filteredCoins = selectedLetter
    ? coins?.filter((coin) =>
        coin?.label?.toLowerCase()?.startsWith(selectedLetter?.toLowerCase())
      )
    : [];

  useEffect(() => {
    setCoins(level2Coins);
  }, [level2Coins]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleAddCoin = () => {
    if (newCoin.trim() && newCoin?.length < 15) {
      const newCoinObj = {
        id: `${newCoin.trim()}`,
        label: newCoin?.trim(),
      };
      setCoins((prev) => [...prev, newCoinObj]);
      setNewCoin("");
      // setShowModal(false);
    }
  };

  const handleSaveCoin = () => {};
  const handleDeleteCoin = (index) => {
    setCoins(coins?.filter((_, i) => i !== index));
  };

  const handleProceed = () => {
    dispatch(setLevel2PlacedCoins(placedCoins));

    dispatch(setLevel2Coins(coins));

    // set null for new coin else set the previous sticker
    const transformedData = Object.fromEntries(
      Object.entries(placedCoins)?.map(([key, values]) => [
        key,
        values.map((value, index) => {
          // Determine the label: if value is a string, use it directly;
          // otherwise, use its label property.
          const label =
            typeof value === "string" ? value : value?.label || value;

          // If there's a corresponding coin in level2CoinswithSticker and it already has a non-null sticker,
          // use that sticker; otherwise, set it to null.
          const existingSticker =
            level2CoinswithSticker &&
            level2CoinswithSticker[key] &&
            level2CoinswithSticker[key][index] &&
            level2CoinswithSticker[key][index].sticker;
          const sticker =
            existingSticker !== undefined && existingSticker !== null
              ? existingSticker
              : null;

          // Return the new coin object without spreading if value is a string.
          if (typeof value === "string") {
            return { label, sticker };
          } else {
            // For objects, spread the existing properties but override label and sticker as needed.
            return { ...value, label, sticker };
          }
        }),
      ])
    );

    dispatch(setLevel2CoinsWithSticker(transformedData));
    navigate("/level2/page6", { state: { placedCoins } });
  };

  const handleBack = () => {
    dispatch(setLevel2Coins(coins));
    navigate("/level2/page4");
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
          marginBottom: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            marginBottom: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            {/* Welcome Message */}
            <Box
              sx={{
                width: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  padding: "24px 0",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  justifyContent: "space-around",
                  width: "100%",
                  gap: { xs: "16px", sm: "0" },
                  maxWidth: "500px",
                }}
              >
                {employeeDetails?.employeeKey && (
                  <Stack
                    direction="row"
                    sx={{
                      background: "#FDEED2",
                      border: "2px solid #F7B73B",
                      borderRadius: "999px",
                      alignItems: "center",
                      padding: "4px 16px",
                      fontSize: "16px",
                    }}
                  >
                    Level 2
                    <img
                      src={info}
                      alt="info-icon"
                      style={{
                        verticalAlign: "middle",
                        marginLeft: "4px",
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  </Stack>
                )}

                <Stack
                  direction="row"
                  sx={{
                    background: "#F1FFEE",
                    color: "#157D0C",
                    boxShadow: "1.508px 1.508px 0px 0px #157D0C",
                    border: "0.754px solid #157D0C",
                    borderRadius: "6px",
                    alignItems: "center",
                    padding: "4px 12px",
                    fontSize: "16px",
                  }}
                >
                  <img
                    src={timerIcon}
                    alt="time-icon"
                    style={{
                      verticalAlign: "middle",
                      marginRight: "4px",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                  {formatTime(timer)}
                </Stack>
              </Box>
              {isLargeScreen && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <Button
                    onClick={handleProceed}
                    variant="contained"
                    sx={{
                      alignSelf: "center",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      borderRadius: "8px",
                      width: { xs: "100%", sm: "120px" },
                      height: "32px",
                    }}
                  >
                    Proceed
                  </Button>
                  <Button
                    onClick={handleBack}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid var(--Primary-black, #0F1621)",
                      background: "#FFF",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      color: "#0F1621",
                      width: { xs: "100%", sm: "120px" },
                      height: "32px",
                      marginTop: "12px",
                      ":hover": {
                        background: "#FFF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Back
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          {/* Game Board */}
          <DndProvider backend={HTML5Backend}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "center",
                alignItems: { xs: "center", md: "start" },
                gap: 8,
                width: "100%",
                padding: "20px 0",
              }}
            >
              {/* Image Container */}
              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "500px",
                  maxHeight: "500px",
                }}
              >
                <DroppableImage
                  imageSrc={image1}
                  placeholders={placeholders}
                  setCoins={setCoins}
                  placedCoins={placedCoins}
                  setPlacedCoins={setPlacedCoins}
                />
              </Box>
              {/* Coins Section */}
              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: { xs: "center", md: "start" },
                  maxWidth: "500px",
                }}
              >
                <Stack
                  sx={{
                    paddingX: { xs: 0, sm: 2, md: 4 },
                    gap: 2,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      maxWidth: "500px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#0F1621",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Coins
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowModal(true)}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#FBFFFF",
                          borderRadius: "50%",
                          width: "32px",
                          height: "32px",
                          border: "2px solid #407BFF",
                        }}
                      >
                        <Typography sx={{ color: "#407BFF", fontSize: "18px" }}>
                          +
                        </Typography>
                      </div>
                      <Typography
                        sx={{
                          color: "#407BFF",
                          fontSize: "16px",
                          marginLeft: "10px",
                        }}
                      >
                        Add coins
                      </Typography>
                    </Box>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "2%",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      maxWidth: "500px",
                      marginBottom: "16px",
                    }}
                  >
                    {alphabet.map((letter) => (
                      <Box
                        key={letter}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "24px",
                          height: "32px",
                          color: selectedLetter === letter ? "red" : "#000080",
                          fontWeight: "bold",
                          cursor: "pointer",
                          borderBottom:
                            selectedLetter === letter
                              ? "2px solid red"
                              : "2px solid #000080",
                        }}
                        onClick={() => setSelectedLetter(letter)}
                      >
                        {letter}
                      </Box>
                    ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "12px",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      maxWidth: "500px",
                    }}
                  >
                    {filteredCoins?.map((coin, index) => (
                      <DraggableCoin
                        key={coin.id}
                        label={coin.label}
                        index={index}
                      />
                    ))}
                  </div>
                </Stack>
              </Box>
            </Box>
            {!isLargeScreen && (
              <Box
                sx={{
                  margin: "40px 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Button
                  onClick={handleBack}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #0F1621",
                    background: "#FFF",
                    boxShadow: "3px 3px 0px 0px #0F1621",
                    color: "#0F1621",
                    width: { xs: "120px", sm: "160px" },
                    height: "40px",
                    ":hover": {
                      background: "#FFF",
                      boxShadow: "none",
                    },
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={handleProceed}
                  variant="contained"
                  sx={{
                    alignSelf: "center",
                    boxShadow: "3px 3px 0px 0px #0F1621",
                    borderRadius: "8px",
                    width: { xs: "120px", sm: "160px" },
                    height: "40px",
                  }}
                >
                  Proceed
                </Button>
              </Box>
            )}
          </DndProvider>
        </Box>
      </Box>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            boxShadow: 24,
            px: { xs: 1, sm: 2, md: 4 },
            py: { xs: 2, sm: 4, md: 6 },
            maxWidth: { xs: "100%", sm: "400px", md: "600px" },
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add the New Coin
            </Typography>
            <p
              onClick={() => setShowModal(false)}
              style={{
                cursor: "pointer",
                fontSize: "20px",
                fontWeight: "bold",
                margin: "0",
                transition: "color 0.3s ease",
              }}
            >
              X
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              borderRadius: "8px",
              border: "1px solid #B4B4B4",
              padding: "12px",
              marginBottom: "20px",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%", // Full width on small screens
                maxWidth: "calc(100% - 120px)", // Prevent the text field and button from getting too cramped
                borderRight: "1px solid #E1E1E1",
                paddingRight: "15px", // Add padding for small screens
              }}
            >
              <TextField
                fullWidth
                value={newCoin}
                onChange={(e) => setNewCoin(e.target.value)}
                sx={{
                  mb: 1,
                  maxWidth: "calc(100% - 60px)", // Allow input to take available space minus the count
                  "& .MuiInputBase-root": {
                    border: "none",
                    padding: "0",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    display: "none",
                  },
                  "& .MuiInputBase-input": {
                    color: "#333",
                    padding: "4px",
                  },
                }}
                placeholder="Enter coin name"
              />
              {/* coins Count */}
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  marginLeft: "8px",
                  whiteSpace: "nowrap", // Prevents text overflow
                }}
              >
                {newCoin?.length} / 15
              </Typography>
            </div>

            <Button
              onClick={handleAddCoin}
              sx={{
                minWidth: "auto",
                padding: "6px 12px",
                background: "transparent",
                color: "#0F1621",
                fontSize: "16px",
                fontWeight: 700,
                textTransform: "capitalize",
                ":hover": {
                  background: "#FFFFFF",
                },
                width: "100%", // Full width on smaller screens
                maxWidth: "120px", // Limit the size of the button
              }}
              disabled={newCoin.length === 0 || newCoin.length > 15}
            >
              Add Coin
            </Button>
          </div>

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
            {coins?.map((coin, index) => (
              <Box
                key={coin.id}
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  width: "85px",
                  height: "85px",
                  borderRadius: "50%",
                  backgroundColor: "#FBFFFF",
                  boxShadow: "2px 2px 0px #0F1621",
                  color: "#0F1621",
                  fontWeight: "bold",
                  textAlign: "center",
                  padding: "10px",
                  wordWrap: "break-word",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  lineHeight: "1.2",
                }}
              >
                <span
                  style={{
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "13px",
                  }}
                >
                  {coin?.label}
                </span>
                <Button
                  onClick={() => handleDeleteCoin(index)}
                  sx={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    fontSize: "12px",
                    backgroundColor: "red",
                    color: "white",
                    minWidth: "auto",
                    padding: "2px 5px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "darkred" },
                  }}
                >
                  ×
                </Button>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              onClick={handleSaveCoin}
              variant="contained"
              sx={{
                borderRadius: "8px",
                boxShadow: "3px 3px 0px 0px #0F1621",
                textTransform: "capitalize",
                padding: "8px 24px",
              }}
            >
              Save Coins
            </Button>
          </Box>
        </Box>
      </Modal>
    </Layout>
  );
};

export default Page5;
