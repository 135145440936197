import { configureStore } from "@reduxjs/toolkit";
import level1CoinsReducer from "./slices/level1CoinsSlice";
import level1DataReducer from "./slices/level1DataSlice";
import level2DataReducer from "./slices/level2DataSlice";
import level3DataReducer from "./slices/level3DataSlice";
import managementDataReducer from "./slices/managementDataSlice";

const store = configureStore({
  reducer: {
    level1Coins: level1CoinsReducer,
    level1Data: level1DataReducer,
    level2Data: level2DataReducer,
    level3Data: level3DataReducer,
    managementData: managementDataReducer,
  },
});

export default store;
