import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Grid,
  CircularProgress,
  Stack,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";

const isValidUrl = (url) => url && !url.includes("/null");

const ProfileDashboard = () => {
  const companyDetails = JSON.parse(localStorage.getItem("companyData"));

  // State for media URLs
  const [logoUrl, setLogoUrl] = useState(companyDetails?.logo);
  const [video1Url, setVideo1Url] = useState(companyDetails?.video1);
  const [video2Url, setVideo2Url] = useState(companyDetails?.video2);

  // Loading states for uploads
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [loadingVideo1, setLoadingVideo1] = useState(false);
  const [loadingVideo2, setLoadingVideo2] = useState(false);
  const [openSurveyDialog, setOpenSurveyDialog] = useState(false);

  // State for company details (inline edit)
  const [editMode, setEditMode] = useState(false);
  const [adminName, setAdminName] = useState(companyDetails?.admin_name || "");
  const [adminEmail, setAdminEmail] = useState(
    companyDetails?.admin_email || ""
  );
  const [adminPhone, setAdminPhone] = useState(
    companyDetails?.admin_phone || ""
  );
  const [loadingReport, setLoadingReport] = useState(false);

  const fetchCompanyDetails = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/company/${companyDetails?.id}`
      );
      localStorage.setItem("companyData", JSON.stringify(res.data));
      window.location.reload();
    } catch (error) {
      console.error("Error fetching company details:", error);
    }
  };

  // Immediately upload file on selection
  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (!file) return;
    if (type === "logo") {
      handleUploadLogo(file);
    } else if (type === "video1") {
      handleUploadVideo1(file);
    } else if (type === "video2") {
      handleUploadVideo2(file);
    }
  };

  const handleUploadLogo = async (file) => {
    if (!file) return;
    setLoadingLogo(true);
    try {
      const formData = new FormData();
      formData.append("logofile", file);
      formData.append("company_id", companyDetails?.id);
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/loadCompanyLogo`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      fetchCompanyDetails();
    } catch (error) {
      console.error("Error uploading logo:", error);
      alert(
        error.response?.data?.message ||
          "An error occurred while uploading the logo."
      );
    } finally {
      setLoadingLogo(false);
    }
  };

  const handleUploadVideo1 = async (file) => {
    if (!file) return;
    setLoadingVideo1(true);
    try {
      const formData = new FormData();
      formData.append("video1file", file);
      formData.append("company_id", companyDetails?.id);
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/uploadVideo1`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      fetchCompanyDetails();
    } catch (error) {
      console.error("Error uploading video 1:", error);
      alert(
        error.response?.data?.message ||
          "An error occurred while uploading video 1."
      );
    } finally {
      setLoadingVideo1(false);
    }
  };

  const handleUploadVideo2 = async (file) => {
    if (!file) return;
    setLoadingVideo2(true);
    try {
      const formData = new FormData();
      formData.append("video2file", file);
      formData.append("company_id", companyDetails?.id);
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/uploadVideo2`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      fetchCompanyDetails();
    } catch (error) {
      console.error("Error uploading video 2:", error);
      alert(
        error.response?.data?.message ||
          "An error occurred while uploading video 2."
      );
    } finally {
      setLoadingVideo2(false);
    }
  };

  const handleUpdateCompany = async () => {
    try {
      // const payload = {
      //   admin_name: adminName,
      //   admin_phone: adminPhone,
      // };
      const urlEncodedData = new URLSearchParams();
      urlEncodedData.append("admin_name", adminName);
      urlEncodedData.append("admin_phone", adminPhone);
      // urlEncodedData.append("company_name", companyDetails?.company_name);
      urlEncodedData.append("company_id", companyDetails?.id);

      Object.keys(companyDetails)?.forEach((key) => {
        if (
          key != "logo" &&
          key != "video1" &&
          key != "video2" &&
          !urlEncodedData.has(key)
        ) {
          urlEncodedData.append(key, companyDetails[key]);
        }
      });

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/updateCompany`,
        urlEncodedData,
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );
      fetchCompanyDetails();
    } catch (error) {
      console.error("Error updating company details:", error);
      alert(
        error.response?.data?.message ||
          "An error occurred while updating company details."
      );
    }
  };

  const handleSurveyClick = () => {
    // setOpenSurveyDialog(true);/
    window.open(
      `https://strategiq.kayawellbeingindex.com/level3/welcome`,
      "_blank"
    );
  };

  const handleCloseSurveyDialog = () => {
    setOpenSurveyDialog(false);
  };

  const handleManagementView = () => {
    // window.open(
    //   `https://strategiq.kayawellbeingindex.com/level2/welcome/${99999999}`,
    //   "_blank"
    // );
    window.open(
      `https://strategiq.kayawellbeingindex.com/level2/page1`,
      "_blank"
    );
  };
  const handleViewData = () => {
    // window.open(
    //   `https://strategiq.kayawellbeingindex.com/level2/welcome/${99999999}`,
    //   "_blank"
    // );
    window.open(
      `https://strategiq.kayawellbeingindex.com/data-management-view/page1`,
      "_blank"
    );
  };

  const handleGenerateReport = async () => {
    setLoadingReport(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getLeaderReport/${companyDetails?.id}`
      );
      setTimeout(() => {
        window.open(
          `https://strategiqapi.kayawellbeingindex.com/api/showPdf/${response.data}`,
          "_blank"
        );
        setLoadingReport(false);
      }, 7000);
    } catch (error) {
      console.error("Error generating report:", error);
      setLoadingReport(false);
    }
  };

  return (
    <Box p={4}>
      <Grid container spacing={4}>
        {/* Company Details Card  */}
        <Grid item xs={12} md={6} lg={4} sx={{ display: "flex" }}>
          <Card
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <CardHeader title="Company Details" />
            <CardContent sx={{ flex: 1 }}>
              <Box textAlign="center" mb={2}>
                <Typography variant="h6">
                  {companyDetails?.company_name}
                </Typography>
              </Box>
              <Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <Typography
                    variant="body1"
                    sx={{ width: 80, fontWeight: "bold" }}
                  >
                    Admin:
                  </Typography>
                  <TextField
                    variant="standard"
                    value={adminName}
                    fullWidth
                    disabled={!editMode}
                    onChange={(e) => setAdminName(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                      sx: { fontSize: "1rem" },
                    }}
                  />
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <Typography
                    variant="body1"
                    sx={{ width: 80, fontWeight: "bold" }}
                  >
                    Email:
                  </Typography>
                  <TextField
                    variant="standard"
                    value={adminEmail}
                    fullWidth
                    disabled={true}
                    onChange={(e) => setAdminEmail(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                      sx: { fontSize: "1rem" },
                    }}
                  />
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="body1"
                    sx={{ width: 80, fontWeight: "bold" }}
                  >
                    Phone:
                  </Typography>
                  <TextField
                    variant="standard"
                    value={adminPhone}
                    fullWidth
                    disabled={!editMode}
                    onChange={(e) => setAdminPhone(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                      sx: { fontSize: "1rem" },
                    }}
                  />
                </Box>
              </Box>
            </CardContent>
            <CardActions sx={{ justifyContent: "center", pb: 2 }}>
              {editMode ? (
                <>
                  <Button
                    variant="contained"
                    onClick={handleUpdateCompany}
                    size="small"
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setEditMode(false)}
                    size="small"
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => setEditMode(true)}
                  size="small"
                >
                  Edit
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>

        {/* Company Logo Card */}
        <Grid item xs={12} md={6} lg={4} sx={{ display: "flex" }}>
          <Card
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <CardHeader title="Company Logo" />
            <CardContent sx={{ flex: 1, textAlign: "center" }}>
              {isValidUrl(logoUrl) ? (
                <img
                  src={logoUrl}
                  alt="Logo"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: 160,
                    marginBottom: 16,
                    objectFit: "contain",
                  }}
                />
              ) : (
                <Typography>No Logo Uploaded</Typography>
              )}
            </CardContent>

            <CardActions sx={{ justifyContent: "center", pb: 2 }}>
              <Button
                variant="contained"
                component="label"
                startIcon={!loadingLogo && <CloudUploadIcon />}
                disabled={loadingLogo}
              >
                {loadingLogo ? (
                  <CircularProgress size={24} color="inherit" />
                ) : isValidUrl(logoUrl) ? (
                  "Update Logo"
                ) : (
                  "Upload Logo"
                )}
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleFileChange(e, "logo")}
                />
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Company Videos Card */}
        <Grid item xs={12} md={6} lg={4} sx={{ display: "flex" }}>
          <Card
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <CardHeader title="Company Videos" />
            <CardContent sx={{ flex: 1 }}>
              <Stack spacing={3} alignItems="center">
                {/* Video 1 */}
                <Box
                  display="flex"
                  alignItems="center"
                  gap={2}
                  width="100%"
                  justifyContent="center"
                >
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={!loadingVideo1 && <CloudUploadIcon />}
                    disabled={loadingVideo1}
                  >
                    {loadingVideo1 ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : isValidUrl(video1Url) ? (
                      "Reload Video 1"
                    ) : (
                      "Upload Video 1"
                    )}
                    <input
                      type="file"
                      hidden
                      onChange={(e) => handleFileChange(e, "video1")}
                    />
                  </Button>
                  {isValidUrl(video1Url) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => window.open(video1Url, "_blank")}
                    >
                      View Video 1
                    </Button>
                  )}
                </Box>
                {/* Video 2 */}
                <Box
                  display="flex"
                  alignItems="center"
                  gap={2}
                  width="100%"
                  justifyContent="center"
                >
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={!loadingVideo2 && <CloudUploadIcon />}
                    disabled={loadingVideo2}
                  >
                    {loadingVideo2 ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : isValidUrl(video2Url) ? (
                      "Reload Video 2"
                    ) : (
                      "Upload Video 2"
                    )}
                    <input
                      type="file"
                      hidden
                      onChange={(e) => handleFileChange(e, "video2")}
                    />
                  </Button>
                  {isValidUrl(video2Url) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => window.open(video2Url, "_blank")}
                    >
                      View Video 2
                    </Button>
                  )}
                </Box>
              </Stack>
            </CardContent>
            <CardActions
              sx={{
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                pb: 2,
                gap: 2,
              }}
            >
              {/* btn */}
              {companyDetails?.level2_id && companyDetails.level2_id > 0 ? (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    width="100%"
                    justifyContent="center"
                  >
                    <Button variant="contained" onClick={handleViewData}>
                      View Data{" "}
                    </Button>
                    <Button variant="contained" onClick={handleGenerateReport}>
                      {loadingReport ? "Working..." : "Generate Report"}
                    </Button>
                  </Box>
                </>
              ) : (
                <Button variant="contained" onClick={handleManagementView}>
                  Management View
                </Button>
              )}
              <Button variant="contained" onClick={handleSurveyClick}>
                Do level 3 survey
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={openSurveyDialog}
        onClose={handleCloseSurveyDialog}
        aria-labelledby="survey-dialog-title"
        aria-describedby="survey-dialog-description"
      >
        <DialogTitle id="survey-dialog-title">
          Survey Access Restricted
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="survey-dialog-description">
            The survey will be available after February 28.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSurveyDialog} variant="contained">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProfileDashboard;
