import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// const keys
const placeholderKeys = [
  "customer_1_coins",
  "customer_2_coins",
  "customer_3_coins",
  "customer_4_coins",
  "customer_employee_coins",
  "customer_employee_strategy_coins",
  "customer_strategy_coins",
  "employee_1_coins",
  "employee_2_coins",
  "employee_3_coins",
  "employee_4_coins",
  "employee_strategy_coins",
  "strategy_1_coins",
  "strategy_2_coins",
  "strategy_3_coins",
  "strategy_4_coins",
];
// ------------------- local functions ----------------------------------------------
// 1. available coins
const adjustManagementCoins = ({ apiResponse, managementCoins }) => {
  // store placedcoins in an array
  const updatedManagementPlacedCoins = {};

  placeholderKeys.forEach(
    (placeholder) =>
      (updatedManagementPlacedCoins[placeholder] =
        apiResponse[placeholder].split(",") || null)
  );

  const placedCoins = Object.values(updatedManagementPlacedCoins)?.reduce(
    (acc, item) => {
      if (Array.isArray(item)) acc.push(...item);
      return acc;
    },
    []
  ); // ['placedCoin1', 'placedCoin2']

  // state to return the remaining coins.
  const remainingCoins = managementCoins.filter(
    (coin) => !placedCoins.includes(coin.label)
  );

  return remainingCoins;
};

// 2. update placed coins
const adjustManagementPlacedCoins = (apiResponse) => {
  const updatedManagementPlacedCoins = {};

  placeholderKeys.forEach(
    (placeholder) =>
      (updatedManagementPlacedCoins[placeholder] =
        (apiResponse[placeholder] != "null" &&
          apiResponse[placeholder].split(",")) ||
        null)
  );

  return updatedManagementPlacedCoins;
};

// 3. update placedcoinswith sticker
const adjustManagementPlacedCoinsWithSticker = (
  apiResponse,
  placedCoins,
  angelCount = 0,
  demonCount = 0
) => {
  const placedCoinsWithSticker = placeholderKeys.reduce((acc, placeholder) => {
    acc[placeholder] = (placedCoins[placeholder] || [])?.map((coin) => {
      const foundCoin = apiResponse?.coindetails?.find(
        (obj) => obj?.coinName === coin
      );
      if (foundCoin) {
        if (foundCoin?.type == "angel") {
          angelCount++;
        } else if (foundCoin.type == "demon") {
          demonCount++;
        }
      }
      return {
        label: coin,
        sticker: foundCoin ? foundCoin?.type : null,
      };
    });
    return acc;
  }, {});
  return { placedCoinsWithSticker, angelCount, demonCount };
};

// 4. update coins details
const adjustManagementCoinDetails = (apiResponse) => {
  const updateCoinDetails = apiResponse?.coindetails?.reduce((acc, obj) => {
    const { type, effected_by, whatStart, whatStop, whatContinue } = obj;
    acc[obj.coinName] = {
      type: type,
      effected_by,
      whatStart,
      whatStop,
      whatContinue,
    };
    return acc;
  }, {});

  return updateCoinDetails;
};

// 5. update comments
const adjustManagementComments = (apiResponse) => {
  const updatedComments = apiResponse?.areacomments?.reduce((acc, obj) => {
    const { comment_1, comment_2 } = obj;
    acc[obj?.area] = { comment_1: comment_1, comment_2: comment_2 };
    return acc;
  }, {});

  return updatedComments;
};

// ----------------------------------------------------------------------------------

// Note: fetch standard coins
export const fetchManagementCoins = createAsyncThunk(
  "managementData/fetchManagementCoins",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getLevel1Coins` //fetch standard coins
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch management coins"
      );
    }
  }
);

// fetch intial data and update the state
export const fetchManagementViewData = createAsyncThunk(
  "managementData/fetchManagementViewData",
  async (company_id, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getLeaderviewData/${company_id}`
      );
      const apiResponse = response.data;
      // Ensure managementCoins is already populated
      const { managementData } = getState().managementData;

      // step 1: find available coins
      const adjustedCoins = adjustManagementCoins({
        apiResponse,
        managementCoins: managementData.managementCoins || [],
      });
      // step 2: set placed coins
      const placedCoins = adjustManagementPlacedCoins(apiResponse);

      // step 3: set placed coins with sticker
      const { placedCoinsWithSticker, angelCount, demonCount } =
        adjustManagementPlacedCoinsWithSticker(apiResponse, placedCoins);

      // step 4: set management Coins Details (type, effected_by, whatStart, whatStop, whatContinue)
      const coinDetails = adjustManagementCoinDetails(apiResponse);

      // step 5: set comments
      const comments = adjustManagementComments(apiResponse);

      return {
        apiResponse,
        adjustedCoins,
        placedCoins,
        placedCoinsWithSticker,
        angelCount,
        demonCount,
        coinDetails,
        comments,
      };
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch management data"
      );
    }
  }
);

const managementDataSlice = createSlice({
  name: "managementData",
  initialState: {
    updateId: null,
    availableAngelSticker: 10,
    availableDemonSticker: 10,
    managementData: {
      managementCoins: [],
      managementPlacedCoins: {}, // to be posted
      managementCoinswithSticker: {},
      managementCoinsDetails: {}, // to be posted
      managementComments: {}, // to be posted
    },
    loading: {
      fetchManagementCoins: false,
      fetchManagementViewData: false,
    },
    error: {
      fetchManagementCoins: null,
      fetchManagementViewData: null,
    },
  },
  reducers: {
    setManagementCoins: (state, action) => {
      state.managementData.managementCoins = action.payload;
    },
    setManagementPlacedCoins: (state, action) => {
      state.managementData.managementPlacedCoins = action.payload;
    },
    setManagementCoinsWithSticker: (state, action) => {
      state.managementData.managementCoinswithSticker = action.payload;
    },
    setManagementCoinsDetails: (state, action) => {
      state.managementData.managementCoinsDetails = action.payload;
    },
    setManagementComments: (state, action) => {
      state.managementData.managementComments = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchManagementCoins.pending, (state) => {
        state.loading.fetchManagementCoins = true;
        state.error.fetchManagementCoins = null; // Reset error when a new request is made
      })
      .addCase(fetchManagementCoins.fulfilled, (state, action) => {
        state.loading.fetchManagementCoins = false;
        state.managementData.managementCoins = action.payload;
      })
      .addCase(fetchManagementCoins.rejected, (state, action) => {
        state.loading.fetchManagementCoins = false;
        state.error.fetchManagementCoins = action.payload;
      })
      // fetch view data
      .addCase(fetchManagementViewData.pending, (state) => {
        state.loading.fetchManagementViewData = true;
        state.error.fetchManagementViewData = null;
      })
      .addCase(fetchManagementViewData.fulfilled, (state, action) => {
        const {
          apiResponse,
          adjustedCoins,
          placedCoins,
          placedCoinsWithSticker,
          angelCount,
          demonCount,
          coinDetails,
          comments,
        } = action.payload;
        state.loading.fetchManagementViewData = false;
        state.updateId = apiResponse.id;

        // Update state with adjusted data
        state.managementData.managementCoins = adjustedCoins;
        state.managementData.managementPlacedCoins = placedCoins;
        state.managementData.managementCoinswithSticker =
          placedCoinsWithSticker;
        state.managementData.managementCoinsDetails = coinDetails;
        state.managementData.managementComments = comments;
        state.availableAngelSticker = Math.max(0, 10 - angelCount);
        state.availableDemonSticker = Math.max(0, 10 - demonCount);
      })
      .addCase(fetchManagementViewData.rejected, (state, action) => {
        state.loading.fetchManagementViewData = false;
        state.error.fetchManagementViewData = action.payload;
      });
  },
});

export const {
  setManagementCoins,
  setManagementPlacedCoins,
  setManagementCoinsWithSticker,
  setManagementCoinsDetails,
  setManagementComments,
} = managementDataSlice.actions;
export default managementDataSlice.reducer;
