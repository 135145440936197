import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchLevel1Data = createAsyncThunk(
  "level1Data/fetchLevel1Data",
  async (companyId, { rejectWithValue }) => {
    try {
      // const response = await axios.get(
      //   `${process.env.REACT_APP_BASE_URL}/api/getLevel1Data/${companyId}`
      // );
      // console.log("/getlevel1data", response.data);
      // if (response.data?.error) {
      //   return {}; // Return an empty object if no data is found
      // }

      // return response.data;
      return {};
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Failed to fetch level 1 data";
      return rejectWithValue(errorMessage);
    }
  }
);

const level1DataSlice = createSlice({
  name: "level1Data",
  initialState: {
    level1Data: {},
    loading: false,
    error: null,
  },
  reducers: {
    setLevel1Data: (state, action) => {
      state.level1Data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLevel1Data.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLevel1Data.fulfilled, (state, action) => {
        state.loading = false;
        state.level1Data = action.payload;
      })
      .addCase(fetchLevel1Data.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setLevel1Data } = level1DataSlice.actions;
export default level1DataSlice.reducer;
