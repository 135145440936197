import React, { useState, useEffect } from "react";
import { Button, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Layout from "../../components/navbar/Layout";
import image1 from "../../assets/game board.png";
import { fetchLevel1Coins } from "../../redux/slices/level1CoinsSlice";
import { useDispatch } from "react-redux";
import { setLevel1Data } from "../../redux/slices/level1DataSlice";

const GettingStarted = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(3);

  const handleNext = () => {
    dispatch(fetchLevel1Coins());
    dispatch(setLevel1Data({})); // reset the state
    navigate("/level1/page1");
  };

  useEffect(() => {
    if (countdown === 1) {
      handleNext();
    } else {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [countdown, navigate]);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
          justifyContent: "center",
          padding: "20px 20px",
        }}
      >
        {/* Countdown Timer Section */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, ease: "easeIn" }}
          style={{
            fontSize: "120px", // Making it bigger
            fontWeight: "bold",
            color: "#F7B73B",
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FDEED2",
            borderRadius: "50%",
            width: "160px",
            height: "160px",
            boxShadow: "0 0 20px rgba(0, 0, 0, 0.3)",
            animation: "pulse 1.5s ease-out infinite", // Added pulse animation
          }}
        >
          {countdown}
        </motion.div>

        {/* Content Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "center",
            gap: 6,
            width: { xs: "100%", md: "80%" },
          }}
        >
          {/* Image Section */}
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeOut" }}
            style={{ maxWidth: "400px", width: "100%" }}
          >
            <img
              src={image1}
              alt="Game Board"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "12px",
                boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
              }}
            />
          </motion.div>

          {/* Text Section */}
          <motion.div
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeOut" }}
            style={{ maxWidth: "400px", textAlign: "left" }}
          >
            <Typography
              sx={{
                color: "#333",
                fontSize: "24px",
                fontWeight: "bold",
                mb: 1,
              }}
            >
              Let’s Get Started!
            </Typography>
            <Typography sx={{ color: "#555", fontSize: "18px", mb: 2 }}>
              Get ready to have fun and enjoy the process. Let’s begin Level 1!
            </Typography>

            {/* Buttons */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                gap: 2,
                mt: 4,
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Button
                onClick={() => navigate("/level1/how-to-play")}
                sx={{
                  borderRadius: "8px",
                  border: "1px solid #0F1621",
                  background: "#FFF",
                  boxShadow: "3px 3px 0px #0F1621",
                  color: "#0F1621",
                  width: { xs: "100%", sm: "160px" },
                  height: "40px",
                  transition: "all 0.2s",
                  ":hover": {
                    transform: "translateY(-2px)",
                    boxShadow: "5px 5px 0px #0F1621",
                  },
                }}
              >
                Back
              </Button>
              <Button
                onClick={handleNext}
                variant="contained"
                sx={{
                  borderRadius: "8px",
                  boxShadow: "3px 3px 0px #0F1621",
                  width: { xs: "100%", sm: "160px" },
                  height: "40px",
                  transition: "all 0.2s",
                  ":hover": {
                    transform: "translateY(-2px)",
                    boxShadow: "5px 5px 0px #0F1621",
                  },
                }}
              >
                Next
              </Button>
            </Box>
          </motion.div>
        </Box>
      </Box>
    </Layout>
  );
};

export default GettingStarted;
