import React, { createContext, useState, useContext } from "react";

// Context for the sticker counts
const StickerContext = createContext();

// Provider component that holds the global state for stickers
export const StickerProvider = ({ children }) => {
  const [availableAngels, setAvailableAngels] = useState(10);
  const [availableDemons, setAvailableDemons] = useState(10);

  return (
    <StickerContext.Provider
      value={{
        availableAngels,
        setAvailableAngels,
        availableDemons,
        setAvailableDemons,
      }}
    >
      {children}
    </StickerContext.Provider>
  );
};

// Custom hook for easy access to the sticker context
export const useStickerContext = () => useContext(StickerContext);
