import React from "react";

const Coin = ({ label }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        fill: "#FBFFFF",
        strokeWidth: "1.01px",
        stroke: "#0F1621",
        filter: "drop-shadow(2.02px 2.02px 0px #0F1621)",
        width: "clamp(60px, 15vw, 85px)",
        height: "clamp(60px, 15vw, 85px)",
        flexShrink: "0",
        borderRadius: "50%",
        backgroundColor: "#FBFFFF",
        fontSize: "clamp(10px, 2vw, 14px)",
        fontWeight: "bold",
        color: "#0F1621",
        transition: "all 0.3s ease-in-out",
        whiteSpace: "normal",
        wordWrap: "break-word",
        lineHeight: "1.2",
        padding: "5px",
        boxSizing: "border-box",
        overflow: "hidden",
        maxWidth: "100%",
      }}
    >
      <span
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {label}
      </span>
    </div>
  );
};

export default Coin;
