import { Box, Typography } from "@mui/material";
import React from "react";

export default function Index() {
  return (
    <Box
      p={2}
      display={"flex"}
      width={"100%"}
      justifyContent={"center"}
      className="footer2"
    >
      <Typography textAlign={"center"}>
        Click here to view{" "}
        <a
          href={`${process.env.REACT_APP_BASE_URL_TOS}/assets/PLATFORM_TOS.pdf`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#007bff" }}
        >
          Terms of Services
        </a>{" "}
        and{" "}
        <a
          href={`${process.env.REACT_APP_BASE_URL_TOS}/assets/Privacy-policy-Company.pdf`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#007bff" }}
        >
          Privacy Policy
        </a>
      </Typography>
    </Box>
  );
}
