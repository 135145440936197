import React, { useState } from "react";
import {
  Stack,
  Button,
  Typography,
  Box,
  Tabs,
  Tab,
  Container,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/navbar/Layout";
import image1 from "../../assets/level2 page1.png";
import topArrow from "../../assets/top arrow.png";
import bottomArrow from "../../assets/bottom arrow.png";
import rightArrow from "../../assets/right arrow.png";
import leftArrow from "../../assets/left arrow.png";
import ReactAudioPlayer from "react-audio-player";
import { useTheme } from "@emotion/react";

const Page1 = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const employeeDetails = JSON.parse(localStorage.getItem("employee"));
  const [tabIndex, setTabIndex] = useState(0);
  const companyDetails = JSON.parse(localStorage.getItem("companyData"));

  const handleTabChange = (_, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleNext = () => {
    navigate("/level2/page2");
  };

  const handleBack = () => {
    const key = employeeDetails?.employeeKey; // get key from the local storage
    navigate(`/level2/welcome/${key}`);
    // navigate("/level2/welcome");
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          width: "100%",
          marginBottom: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
          }}
        >
          {/* Welcome Message */}
          <Box sx={{ width: "100%", position: "relative" }}>
            <Box
              sx={{
                display: "flex",
                padding: "24px 0",
                maxWidth: "680px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                justifySelf: "center",
                position: "relative",
              }}
            >
              {employeeDetails?.employeeKey && (
                <div
                  style={{
                    padding: "4px 16px",
                    borderRadius: "999px",
                    background: "#FDEED2",
                    border: "2px solid #F7B73B",
                    marginBottom: "16px",
                  }}
                >
                  <p>Level 2</p>
                </div>
              )}
              <Typography variant="h5" fontWeight="bold">
                {employeeDetails?.employeeKey
                  ? "Congratulations on Completing Level 1! 🎉"
                  : `Welcome ${
                      companyDetails?.admin_name || ""
                    }, to the StrategIq board game!`}
              </Typography>
              <Typography sx={{ margin: "16px 0", fontSize: "16px" }}>
                Before we move forward, let me explain the different areas of
                the board game and what you should consider before placing a
                coin in each section:
              </Typography>
            </Box>
            {/* Buttons */}
            {isLargeScreen && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                <Button
                  onClick={handleNext}
                  variant="contained"
                  sx={{
                    alignSelf: "center",
                    boxShadow: "3px 3px 0px 0px #0F1621",
                    borderRadius: "8px",
                    width: { xs: "100%", sm: "160px" },
                    height: "40px",
                  }}
                >
                  Next
                </Button>
                {employeeDetails?.employeeKey && (
                  <Button
                    onClick={handleBack}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid var(--Primary-black, #0F1621)",
                      background: "#FFF",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      color: "#0F1621",
                      width: { xs: "100%", sm: "160px" },
                      height: "40px",
                      marginTop: "12px",
                      ":hover": {
                        background: "#FFF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Back
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Box>

        {/* body */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            marginTop: "40px",
            marginBottom: { xs: "60px", sm: "60px", md: "10px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "center",
              },
              alignItems: { xs: "center", sm: "center", md: "start" },
              gap: 8,
              width: "100%",
            }}
          >
            {/* First Div (Image) */}
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "500px",
                maxHeight: "500px",
              }}
            >
              <img
                src={image1}
                alt="Game Board"
                style={{
                  width: "90%",
                  height: "auto",
                  pointerEvents: "none",
                  borderRadius: "12px",
                }}
              />
            </Box>

            {/* Second Div (Title and Time) */}
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: { xs: "center", sm: "center", md: "start" },
                maxWidth: "500px",
              }}
            >
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="Instructions tabs"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#EFE4B0",
                  },
                }}
                sx={{
                  borderBottom: "2px solid #ccc",
                }}
              >
                <Tab
                  label="Read Instructions"
                  sx={{
                    padding: "10px 20px",
                    fontWeight: "bold",
                    backgroundColor: tabIndex === 0 ? "#EFE4B0" : "white",
                    color: tabIndex === 0 ? "black" : "black",
                    borderRadius: "5px",
                    "&.Mui-selected": {
                      backgroundColor: "#EFE4B0",
                      color: "black",
                    },
                  }}
                />
                <Tab
                  label="Listen to Instructions"
                  sx={{
                    padding: "10px 20px",
                    fontWeight: "bold",
                    backgroundColor: tabIndex === 1 ? "#EFE4B0" : "white",
                    color: tabIndex === 1 ? "black" : "black",
                    borderRadius: "5px",
                    "&.Mui-selected": {
                      backgroundColor: "#EFE4B0",
                      color: "black",
                    },
                  }}
                />
              </Tabs>
              {/* content */}
              <Box sx={{ width: "100%" }}>
                {tabIndex == 0 && (
                  <>
                    <Stack sx={{ mt: 4, gap: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                        }}
                      >
                        <img src={topArrow} />
                        <Stack direction={"column"}>
                          <Typography
                            sx={{
                              marginLeft: "10px",
                              marginBottom: "4px",
                              fontWeight: 600,
                            }}
                          >
                            Delivery (Employee & Customer Overlap):
                          </Typography>
                          <div style={{ paddingLeft: "8%" }}>
                            <ol style={{ listStyleType: "circle" }}>
                              <li>
                                <Typography
                                  variant="body2"
                                  sx={{ marginBottom: "4px", lineHeight: 1.3 }}
                                >
                                  This is where the Employee and Customer
                                  circles overlap.
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  variant="body2"
                                  sx={{ marginBottom: "4px", lineHeight: 1.3 }}
                                >
                                  Place coins here that represent what employees
                                  need to have or do in order to effectively
                                  serve customers.
                                </Typography>
                              </li>
                            </ol>
                          </div>
                        </Stack>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                        }}
                      >
                        <img src={rightArrow} />
                        <Stack direction={"column"}>
                          <Typography
                            sx={{
                              marginLeft: "10px",
                              marginBottom: "4px",
                              fontWeight: 600,
                            }}
                          >
                            Engagement (Employee & Strategy Overlap):
                          </Typography>
                          <div style={{ paddingLeft: "8%" }}>
                            <ol style={{ listStyleType: "circle" }}>
                              <li>
                                <Typography
                                  variant="body2"
                                  sx={{ marginBottom: "4px", lineHeight: 1.3 }}
                                >
                                  This area lies at the intersection of Employee
                                  and Strategy.
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  variant="body2"
                                  sx={{ marginBottom: "4px", lineHeight: 1.3 }}
                                >
                                  Place coins here that focus on motivating and
                                  enabling employees to successfully deliver the
                                  strategy.
                                </Typography>
                              </li>
                            </ol>
                          </div>
                        </Stack>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                        }}
                      >
                        <img src={leftArrow} />
                        <Stack direction={"column"}>
                          <Typography
                            sx={{
                              marginLeft: "10px",
                              marginBottom: "4px",
                              fontWeight: 600,
                            }}
                          >
                            Alignment (Customer & Strategy Overlap):
                          </Typography>
                          <div style={{ paddingLeft: "8%" }}>
                            <ol style={{ listStyleType: "circle" }}>
                              <li>
                                <Typography
                                  variant="body2"
                                  sx={{ marginBottom: "4px", lineHeight: 1.3 }}
                                >
                                  This is where Customer and Strategy intersect.
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  variant="body2"
                                  sx={{ marginBottom: "4px", lineHeight: 1.3 }}
                                >
                                  Place coins here to represent how the strategy
                                  or approach aligns with customer needs and
                                  expectations.
                                </Typography>
                              </li>
                            </ol>
                          </div>
                        </Stack>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                        }}
                      >
                        <img src={bottomArrow} />
                        <Stack direction={"column"}>
                          <Typography
                            sx={{
                              marginLeft: "10px",
                              marginBottom: "4px",
                              fontWeight: 600,
                            }}
                          >
                            Core (Intersection of All Three Circles):
                          </Typography>
                          <div style={{ paddingLeft: "8%" }}>
                            <ol style={{ listStyleType: "circle" }}>
                              <li>
                                <Typography
                                  variant="body2"
                                  sx={{ marginBottom: "4px", lineHeight: 1.3 }}
                                >
                                  This is the central area where Employee,
                                  Customer, and Strategy all overlap.
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  variant="body2"
                                  sx={{ marginBottom: "4px", lineHeight: 1.3 }}
                                >
                                  Place coins here to represent the driving
                                  force behind your company, such as your
                                  vision, purpose, or core values.
                                </Typography>
                              </li>
                            </ol>
                          </div>
                        </Stack>
                      </div>
                      <Typography
                        sx={{
                          marginTop: "8px",
                          marginBottom: "4px",
                          fontWeight: 600,
                          lineHeight: 1.5,
                        }}
                      >
                        Take some time to carefully consider the placement of
                        each coin to ensure it fits the purpose of the area it’s
                        in!{" "}
                      </Typography>
                    </Stack>
                  </>
                )}
                {tabIndex === 1 && (
                  <>
                    <Box>
                      <ReactAudioPlayer
                        src={`${process.env.REACT_APP_BASE_URL}/videos/Venn_diagram_explanation_Video1.mp3`}
                        autoPlay
                        controls
                        style={{
                          width: "100%",
                          marginTop: "16px",
                        }}
                      />
                      <Stack sx={{ mt: 4, gap: 1 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <img src={topArrow} />
                          <Typography sx={{ marginLeft: "10px" }}>
                            Helps to{" "}
                            <span style={{ fontWeight: "600" }}>
                              {" "}
                              increase interactions like “service”
                            </span>
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <img src={bottomArrow} />
                          <Typography sx={{ marginLeft: "10px" }}>
                            Represents the
                            <span style={{ fontWeight: "600" }}>
                              {" "}
                              action that connect all 3 areas
                            </span>
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <img src={rightArrow} />
                          <Typography sx={{ marginLeft: "10px" }}>
                            Helps to
                            <span style={{ fontWeight: "600" }}>
                              {" "}
                              connects Employee & strategy
                            </span>
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <img src={leftArrow} />
                          <Typography sx={{ marginLeft: "10px" }}>
                            Help to
                            <span style={{ fontWeight: "600" }}>
                              {" "}
                              increase sales or customer problems
                            </span>
                          </Typography>
                        </div>
                      </Stack>
                    </Box>
                  </>
                )}
              </Box>
              {/* buttons */}
              {!isLargeScreen && (
                <Box
                  sx={{
                    margin: "40px 0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                    flexDirection: { xs: "column", sm: "row" },
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={handleBack}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid var(--Primary-black, #0F1621)",
                      background: "#FFF",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      color: "#0F1621",
                      width: { xs: "100%", sm: "160px" },
                      height: "40px",
                      ":hover": {
                        background: "#FFF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    sx={{
                      alignSelf: "center",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      borderRadius: "8px",
                      width: { xs: "100%", sm: "160px" },
                      height: "40px",
                    }}
                  >
                    Next
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Page1;
