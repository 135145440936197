import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  IconButton,
  TextareaAutosize,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/navbar/Layout";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import demon from "../../assets/demon.png";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { setLevel2CoinsDetails } from "../../redux/slices/level2DataSlice";
import { useTheme } from "@emotion/react";
import peopleTeal from "../../assets/people teal.png";
import settingsTeal from "../../assets/settings teal.png";

const displayEffectedBy = (option) => {
  const renderBlock = () => {
    switch (option) {
      case "people":
        return (
          <Box
            sx={{
              border: "1px solid #E2E2E2",
              borderRadius: "8px",
              width: "116px",
              height: "79px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              background: "#4799A9",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={peopleTeal}
                style={{ height: "24px", width: "24px" }}
                alt="People"
              />
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "16px",
                }}
              >
                People
              </Typography>
            </Stack>
          </Box>
        );
      case "process":
        return (
          <Box
            sx={{
              border: "1px solid #E2E2E2",
              borderRadius: "8px",
              width: "116px",
              height: "79px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              background: "#4799A9",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={settingsTeal}
                style={{ height: "24px", width: "24px" }}
                alt="Process"
              />
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "16px",
                }}
              >
                Process
              </Typography>
            </Stack>
          </Box>
        );
      default:
        return (
          <Box
            sx={{
              border: "1px solid #E2E2E2",
              borderRadius: "8px",
              width: "128px",
              height: "79px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              background: "#4799A9",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", gap: "4px" }}>
                <img
                  src={peopleTeal}
                  style={{ height: "24px", width: "24px" }}
                  alt="People"
                />
                <p
                  style={{
                    color: "white",
                  }}
                >
                  +
                </p>
                <img
                  src={settingsTeal}
                  style={{ height: "24px", width: "24px" }}
                  alt="Process"
                />
              </div>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#FFF",
                  lineHeight: 1.1,
                  marginTop: "4px",
                }}
              >
                People & Process
              </Typography>
            </Stack>
          </Box>
        );
    }
  };
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", margin: "20px 12px" }}>
      {renderBlock()}
    </Box>
  );
};

const Page9 = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employeeDetails = JSON.parse(localStorage.getItem("employee"));

  // Retrieve level2CoinsDetails from Redux
  const level2CoinsDetails = useSelector(
    (state) => state.level2Data?.level2Data?.level2CoinsDetails
  );

  // Maintain a local copy to allow modifications
  const [localCoinDetails, setLocalCoinDetails] = useState(
    level2CoinsDetails || {}
  );
  useEffect(() => {
    setLocalCoinDetails(level2CoinsDetails || {});
  }, [level2CoinsDetails]);

  // Filter to get up to 10 demon coins
  const demonEntries = Object.entries(localCoinDetails)
    ?.filter(([label, details]) => details.type === "demon")
    ?.slice(0, 10);
  const totalCoins = demonEntries.length;

  // Check that for every demon coin, all three additional fields are filled
  const allCoinsComplete =
    totalCoins >= 0 &&
    demonEntries.every(
      ([label, details]) =>
        details?.whatStart?.trim() &&
        details?.whatStop?.trim() &&
        details?.whatContinue?.trim()
    );

  // Carousel state
  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleCoins = (() => {
    if (totalCoins === 0) return [];
    if (totalCoins >= 3) {
      return [
        demonEntries[currentIndex % totalCoins],
        demonEntries[(currentIndex + 1) % totalCoins],
        demonEntries[(currentIndex + 2) % totalCoins],
      ];
    }
    // For 1 or 2 coins, pad with nulls so that the active coin is always at index 1.
    const activeCoin = demonEntries[currentIndex];
    if (totalCoins === 1) {
      return [null, activeCoin, null];
    }
    if (totalCoins === 2) {
      // When currentIndex is 0, active coin is demonEntries[0] → pad to [null, coin0, coin1]
      // When currentIndex is 1, active coin is demonEntries[1] → pad to [coin0, coin1, null]
      return currentIndex === 0
        ? [null, demonEntries[0], demonEntries[1]]
        : [demonEntries[0], demonEntries[1], null];
    }
  })();

  // Navigation handlers
  const goToNext = () => {
    if (totalCoins > 0) {
      setCurrentIndex((prev) => (prev + 1) % totalCoins);
    }
  };

  const goToPrevious = () => {
    if (totalCoins > 0) {
      setCurrentIndex((prev) => (prev - 1 + totalCoins) % totalCoins);
    }
  };

  // Determine the center coin (if three are visible, take index 1; otherwise, the first one).
  const centerCoin =
    visibleCoins.length === 3 ? visibleCoins[1] : visibleCoins[0];
  const centerCoinLabel = centerCoin ? centerCoin[0] : null;
  // Retrieve the center coin’s additional fields (or use empty strings if not defined)
  const centerCoinData = centerCoinLabel
    ? localCoinDetails[centerCoinLabel] || {}
    : {};

  // Handler to update a given field for the center coin.
  const updateCenterCoinField = (field, value) => {
    if (centerCoinLabel) {
      setLocalCoinDetails((prev) => ({
        ...prev,
        [centerCoinLabel]: {
          ...prev[centerCoinLabel],
          [field]: value,
        },
      }));
    }
  };

  const handleNext = () => {
    dispatch(setLevel2CoinsDetails(localCoinDetails));
    navigate("/level2/page14");
  };

  const handleBack = () => {
    dispatch(setLevel2CoinsDetails(localCoinDetails));
    navigate("/level2/page8");
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
          marginBottom: "40px",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "0px 0",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
              gap: { xs: "16px", sm: "0" },
            }}
          >
            <Stack
              direction="row"
              sx={{
                background: "#FDEED2",
                border: "2px solid #F7B73B",
                borderRadius: "999px",
                alignItems: "center",
                padding: "4px 16px",
                fontSize: "16px",
              }}
            >
              {employeeDetails?.employeeKey && "Level 2 - "}
              Q&A Session
            </Stack>
          </Box>
          {isLargeScreen && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <Button
                onClick={handleNext}
                variant="contained"
                sx={{
                  alignSelf: "center",
                  boxShadow: "3px 3px 0px 0px #0F1621",
                  borderRadius: "8px",
                  width: { xs: "100%", sm: "120px" },
                  height: "32px",
                }}
              >
                Next
              </Button>
              <Button
                onClick={handleBack}
                sx={{
                  borderRadius: "8px",
                  border: "1px solid var(--Primary-black, #0F1621)",
                  background: "#FFF",
                  boxShadow: "3px 3px 0px 0px #0F1621",
                  color: "#0F1621",
                  width: { xs: "100%", sm: "120px" },
                  height: "32px",
                  marginTop: "12px",
                  ":hover": {
                    background: "#FFF",
                    boxShadow: "none",
                  },
                }}
              >
                Back
              </Button>
            </Box>
          )}
        </Box>
        {/* Main Container */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            marginTop: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: 8,
              width: { sm: "100%", md: "80%" },
              padding: "20px 0",
            }}
          >
            {/* Left: Input Fields for the Center Coin */}
            <Stack
              sx={{
                width: { xs: "100%", sm: "80%", md: "70%" },
                paddingX: { xs: 1, sm: 2, md: 4 },
                maxWidth: { xs: "350px", sm: "400px", md: "460px" },
                gap: 2,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    width: "24px",
                    height: "24px",
                    flexShrink: 0,
                    background: "#ECECEC",
                    borderRadius: "50%",
                    marginRight: "10px",
                  }}
                >
                  4
                </div>
                <Stack sx={{ gap: 2, textAlign: "start" }}>
                  <Typography
                    sx={{
                      color: "#2D2C2C",
                      fontSize: { xs: "12px", sm: "16px" },
                      fontWeight: 700,
                    }}
                  >
                    What can you do to improve or address this area of weakness?
                  </Typography>
                  <Typography
                    sx={{
                      color: "#2D2C2C",
                      fontSize: { xs: "16px", sm: "20px" },
                      fontWeight: 700,
                    }}
                  >
                    What do you want to start?
                  </Typography>
                  <TextareaAutosize
                    minRows={3}
                    value={centerCoinData.whatStart || ""}
                    onChange={(e) =>
                      updateCenterCoinField("whatStart", e.target.value)
                    }
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "16px",
                      padding: "8px",
                      boxSizing: "border-box",
                      width: "100%",
                      maxWidth: "350px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "#2D2C2C",
                      fontSize: { xs: "16px", sm: "20px" },
                      fontWeight: 700,
                    }}
                  >
                    What do you want to stop?
                  </Typography>
                  <TextareaAutosize
                    minRows={3}
                    value={centerCoinData.whatStop || ""}
                    onChange={(e) =>
                      updateCenterCoinField("whatStop", e.target.value)
                    }
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "16px",
                      padding: "8px",
                      boxSizing: "border-box",
                      width: "100%",
                      maxWidth: "350px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "#2D2C2C",
                      fontSize: { xs: "16px", sm: "20px" },
                      fontWeight: 700,
                    }}
                  >
                    What do you want to continue?
                  </Typography>
                  <TextareaAutosize
                    minRows={3}
                    value={centerCoinData.whatContinue || ""}
                    onChange={(e) =>
                      updateCenterCoinField("whatContinue", e.target.value)
                    }
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "16px",
                      padding: "8px",
                      boxSizing: "border-box",
                      width: "100%",
                      maxWidth: "350px",
                    }}
                  />
                </Stack>
              </div>
            </Stack>
            {/* Right: Carousel Section */}
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                maxWidth: "500px",
                maxHeight: "500px",
                mx: "auto",
                background: "#F9F9F9",
                borderRadius: "16px",
                p: "60px 0",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              {/* <Box
                sx={{
                  padding: "16px 8px",
                  borderRadius: "8px",
                  border: "1px solid #F26F21",
                  mb: 8,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    color: "#F26F21",
                    fontSize: "16px",
                  }}
                >
                  What would it look like if the demon were to be improved? How
                  could this be achieved?
                </Typography>
              </Box> */}

              {/* Carousel Content */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                {visibleCoins?.map((coin, index) => {
                  console.log("coin", coin);
                  if (!coin) {
                    return (
                      <Box
                        key={index}
                        sx={{ flex: "0 0 calc(100%/3)", px: 1 }}
                      />
                    );
                  }
                  const [label, details] = coin;
                  // The active (selected) coin is always at index 1.
                  const isActive = index === 1;

                  const coinComplete =
                    details?.whatStart?.trim() &&
                    details?.whatStop?.trim() &&
                    details?.whatContinue?.trim();
                  return (
                    <Stack direction={"column"}>
                      {isActive ? (
                        displayEffectedBy(details?.effected_by)
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            margin: "20px 12px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "116px",
                              height: "79px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "transparent",
                            }}
                          ></Box>
                        </Box>
                      )}
                      <Box
                        key={label}
                        sx={{
                          flex: "0 0 calc(100%/3)",
                          textAlign: "center",
                          transition: "transform 0.3s ease",
                          opacity: isActive ? 1 : 0.6,
                          transform: isActive ? "scale(1)" : "scale(0.9)",
                          px: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: "115px",
                            height: "115px",
                            borderRadius: "50%",
                            border: coinComplete
                              ? "3px solid #00AA00"
                              : isActive
                              ? "3px solid #F88C8C"
                              : "3px solid #D3D3D3",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            mb: 2,
                            mx: "auto",
                            boxShadow: coinComplete
                              ? "2px 2px 4px #00AA00"
                              : isActive
                              ? "2px 2px 4px #F88C8C"
                              : "2px 2px 4px #D3D3D3",
                            textAlign: "center",
                            p: 1,
                            position: "relative",
                          }}
                        >
                          <img
                            src={demon}
                            alt="demon"
                            style={{
                              width: "24px",
                              height: "24px",
                              marginBottom: "4px",
                            }}
                          />
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              fontSize: "12px",
                              wordBreak: "break-word",
                              overflowWrap: "break-word",
                              textAlign: "center",
                              overflow: "hidden",
                              lineHeight: "1.2",
                              marginBottom: "4px",
                              color: "#F88C8C",
                            }}
                          >
                            {label}
                          </Typography>
                          {coinComplete && (
                            <CheckIcon
                              sx={{
                                position: "absolute",
                                top: 4,
                                right: 4,
                                color: "#00AA00",
                                fontSize: "20px",
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    </Stack>
                  );
                })}
              </Box>

              {/* Carousel Navigation */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                {totalCoins > 1 && (
                  <>
                    <IconButton
                      onClick={goToPrevious}
                      sx={{
                        mx: 1,
                        border: "1px solid #535353",
                        borderRadius: "50%",
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                    <Typography sx={{ fontSize: "14px", color: "#535353" }}>
                      {(currentIndex % totalCoins) + 1}/{totalCoins}
                    </Typography>
                    <IconButton
                      onClick={goToNext}
                      sx={{
                        mx: 1,
                        border: "1px solid #535353",
                        borderRadius: "50%",
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          {/* Bottom Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-around",
              alignItems: "flex-start",
              gap: { xs: 4, sm: 8 },
              width: { xs: "100%", sm: "100%", md: "80%" },
              padding: "20px 0",
            }}
          >
            <Box
              sx={{
                marginTop: "40px",
                display: "flex",
                alignItems: "center",
                width: 220,
                maxHeight: 100,
              }}
            >
              {/* <Typography
                sx={{ paddingLeft: 2, fontSize: 16, marginRight: "10px" }}
              >
                5/5
              </Typography>
              <Box
                sx={{
                  height: "10px",
                  borderRadius: "1rem",
                  width: 100,
                  backgroundColor: "#0F1621",
                }}
              />
              <Box
                sx={{
                  marginLeft: "4px",
                  height: "10px",
                  borderRadius: "1rem",
                  width: 100,
                  backgroundColor: "#0F1621",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              />
              <Box
                sx={{
                  marginLeft: "4px",
                  height: "10px",
                  borderRadius: "1rem",
                  width: 100,
                  backgroundColor: "#0F1621",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              />
              <Box
                sx={{
                  marginLeft: "4px",
                  height: "10px",
                  borderRadius: "1rem",
                  width: 100,
                  backgroundColor: "#0F1621",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              />
              <Box
                sx={{
                  marginLeft: "4px",
                  height: "10px",
                  borderRadius: "1rem",
                  width: 100,
                  backgroundColor: "#0F1621",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              /> */}
            </Box>
            {/* Navigation Buttons */}
            {!isLargeScreen && (
              <Box
                sx={{
                  margin: "40px 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  flexDirection: { xs: "column", sm: "row" },
                  width: "100%",
                }}
              >
                <Button
                  onClick={handleBack}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid var(--Primary-black, #0F1621)",
                    background: "#FFF",
                    boxShadow: "3px 3px 0px 0px #0F1621",
                    color: "#0F1621",
                    width: { xs: "100%", sm: "160px" },
                    height: "40px",
                    ":hover": {
                      background: "#FFF",
                      boxShadow: "none",
                    },
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={handleNext}
                  variant="contained"
                  sx={{
                    alignSelf: "center",
                    boxShadow: "3px 3px 0px 0px #0F1621",
                    borderRadius: "8px",
                    width: { xs: "100%", sm: "160px" },
                    height: "40px",
                  }}
                >
                  Next
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Page9;
