import React, { useContext, useEffect, useState } from "react";
import { Stack, Button, Typography, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/navbar/Layout";
import { useDispatch } from "react-redux";
import { fetchLevel1Coins } from "../../redux/slices/level1CoinsSlice";
import { fetchLevel1Data } from "../../redux/slices/level1DataSlice";
import axios from "axios";
import EmployeeContext from "../../context/EmployeeContext";

const isValidUrl = (url) => url && !url.includes("/null");

const Welcome = () => {
  const { key } = useParams();
  const { employeeDetails, setEmployeeDetails } = useContext(EmployeeContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [imageFile, setImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchCompanyDetails = async (companyId) => {
    if (!companyId) return;
    // Fetch updated company details
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/company/${companyId}`
    );

    // Update local storage
    localStorage.setItem("companyData", JSON.stringify(res.data));
  };

  useEffect(() => {
    if (!key) return;
    const fetchEmployeeDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/getEmployee/${key}`
        );
        localStorage.setItem("employee", JSON.stringify(response.data));
        setEmployeeDetails(response.data);
        // fetch company details to get the video1
        fetchCompanyDetails(response.data?.company_id);
        // fetch coins & level 1 intial data
        dispatch(fetchLevel1Coins());
        dispatch(fetchLevel1Data(response.data?.company_id));
      } catch (err) {
        console.error("Error fetching employee details", err);
      }
    };
    fetchEmployeeDetails();
  }, [key]);

  useEffect(() => {
    if (employeeDetails?.level1_id) {
      const key = employeeDetails?.employeeKey;
      navigate(`/level2/welcome/${key}`);
    }
  }, [employeeDetails]);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setIsLoading(true);

      const formData = new FormData();
      formData.append("photofile", file);
      formData.append("member_id", employeeDetails?.id);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/uploadPhoto`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Profile Photo updated successfully:", response.data);

        // Update the employee details
        setEmployeeDetails((prevDetails) => ({
          ...prevDetails,
          photo: response.data?.fileurl,
        }));

        setCurrentStep(2);
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleVideoView = () => {
    setCurrentStep(3);
  };

  const handleProceed = () => {
    navigate("/level1/how-to-play");
  };

  const steps = [
    { label: "Upload photo" },
    { label: "View Video" },
    { label: "Proceed" },
  ];

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "60px",
          minHeight: "100%",
        }}
      >
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center", // Ensure all content inside the stack is centered
            textAlign: "center",
            padding: "20px",
            borderRadius: "16px",
            width: "100%", // Ensure stack takes full width
            maxWidth: "680px", // Restrict width for readability
          }}
        >
          {/* Welcome Message */}
          <Box
            sx={{
              padding: "24px 0",
              width: "90%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ marginBottom: "16px" }}
            >
              Welcome {employeeDetails?.associate_name}!
            </Typography>
            <Typography sx={{ margin: "20px 0", fontSize: "16px" }}>
              Welcome to the game portal! This game helps you overcome strategic
              challenges as a team.
            </Typography>
          </Box>

          {/* Step Indicators */}
          <Box sx={{ marginBottom: "20px", width: "100%" }}>
            <Stack direction="row" justifyContent="center" alignItems="center">
              {steps.map((step, index) => (
                <React.Fragment key={index}>
                  <Box
                    sx={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      border: "2px solid",
                      borderColor: currentStep > index ? "#4799A9" : "#ccc",
                      backgroundColor:
                        currentStep > index ? "#4799A9" : "transparent",
                      color: currentStep > index ? "#fff" : "#000",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {index + 1}
                  </Box>
                  {index < steps.length - 1 && (
                    <Box
                      sx={{
                        height: "4px",
                        width: {
                          xs: "50px", // Small screen
                          sm: "75px", // Medium screen
                          md: "100px", // Large screen
                        },
                        backgroundColor:
                          currentStep > index ? "#4799A9" : "#ccc",
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </Stack>
            <Stack
              direction="row"
              spacing={10}
              justifyContent="center"
              marginTop="10px"
            >
              {steps.map((step, index) => (
                <Stack key={step.label} alignItems="center">
                  <Typography
                    sx={{
                      color: currentStep > index ? "#00796b" : "#777",
                    }}
                  >
                    {step.label}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Box>

          {/* Step 1: Upload Image */}
          {currentStep === 1 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                marginTop: "16px",
                width: "100%",
                maxWidth: "300px",
                borderRadius: "8px",
                textAlign: "center",
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                Step 1: Upload a Profile Photo
              </Typography>
              <input
                id="file-upload"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: "none" }}
              />
              <label htmlFor="file-upload">
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    width: "200px",
                    height: "40px",
                    borderRadius: "8px",
                  }}
                >
                  {isValidUrl(employeeDetails?.photo)
                    ? "Update Image"
                    : "Upload Image"}
                </Button>
              </label>
              <Button
                onClick={() => setCurrentStep(2)}
                variant="contained"
                component="span"
                sx={{
                  marginTop: "20px",
                  width: "200px",
                  height: "40px",
                  borderRadius: "8px",
                }}
              >
                Next
              </Button>
            </Box>
          )}

          {/* Step 2: View Video */}
          {currentStep === 2 && (
            <Box sx={{ marginBottom: "20px", width: "100%" }}>
              <Typography variant="h6">
                Step 2: View Introduction Video
              </Typography>
              <video
                width="100%"
                height="auto"
                controls
                style={{
                  marginTop: "16px",
                  borderRadius: "16px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <source
                  src={JSON.parse(localStorage.getItem("companyData"))?.video1}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <Button
                onClick={handleProceed}
                variant="contained"
                sx={{
                  marginTop: "16px",
                  width: "200px",
                  height: "40px",
                  boxShadow: "3px 3px 0px 0px #0F1621",
                  borderRadius: "8px",
                }}
              >
                Proceed
              </Button>
            </Box>
          )}

          {/* Step 3: Proceed Button */}
          {currentStep === 3 && (
            <Box sx={{ marginBottom: "20px", width: "100%" }}>
              <Typography variant="h6">Step 3: Ready to Play?</Typography>
              <Button
                onClick={handleProceed}
                variant="contained"
                sx={{
                  marginTop: "16px",
                  width: "200px",
                  height: "40px",
                  boxShadow: "3px 3px 0px 0px #0F1621",
                  borderRadius: "8px",
                }}
              >
                Proceed
              </Button>
            </Box>
          )}
        </Stack>
      </div>
    </Layout>
  );
};

export default Welcome;
