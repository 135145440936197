import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  InputAdornment,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LogoHeader from "../../components/LogoHeader";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmRegistration = () => {
  const navigate = useNavigate();
  const { key } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [validationError, setValidationError] = useState("");
  const [companyDetails, setCompanyDetails] = useState({});
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/confirmRegistration/${key}`
        );
        setCompanyDetails(response.data);
        setEmail(response.data?.admin_email);
      } catch (err) {
        setError(
          err.response?.data?.message ||
            "Failed to fetch company details. Please check the confirmation link."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [key]);

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      setValidationError("Passwords do not match!");
      return;
    }
    if (email === password) {
      setValidationError("Password cannot be the same as the email!");
      return;
    }

    setValidationError("");
    setError("");
    setLoading(true);

    try {
      const payload = new URLSearchParams({
        username: email,
        password: password,
        key: key,
      }).toString();

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/confirmCompany`,
        payload,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.data.success === true) {
        setOpenSuccessDialog(true);
        setSuccessText(response.data?.message);
      } else {
        response.data.error && setError(response.data.error);
      }
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "Failed to set password. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
    navigate("/");
  };

  return (
    <>
      <LogoHeader />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#f5f5f5",
          padding: 3,
        }}
      >
        <Card
          sx={{
            maxWidth: 500,
            width: "100%",
            borderRadius: 3,
          }}
        >
          <CardContent>
            <Box sx={{ textAlign: "center", marginBottom: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginBottom: 3,
                }}
              >
                Confirm Your Registration for{" "}
                <span style={{ color: "#24a9dd" }}>
                  {companyDetails?.company_name}
                </span>
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  fontSize: "16px",
                  marginBottom: 3,
                  color: "#555",
                }}
              >
                Welcome, <strong>{companyDetails?.admin_name}</strong>! Please
                set your password to complete your registration process.
              </Typography>
            </Box>

            {error && (
              <Alert severity="error" sx={{ marginBottom: 2 }}>
                {error}
              </Alert>
            )}

            {validationError && (
              <Alert severity="warning" sx={{ marginBottom: 2 }}>
                {validationError}
              </Alert>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <TextField
                label="Email"
                type="email"
                placeholder="Please enter your email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "14px", fontWeight: 500 },
                }}
              />

              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                placeholder="Please enter your password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "14px", fontWeight: 500 },
                }}
              />
              <TextField
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm your password"
                fullWidth
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "14px", fontWeight: 500 },
                }}
              />

              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading}
                sx={{
                  textTransform: "none",
                  padding: 1.5,
                  fontSize: "16px",
                  fontWeight: 600,
                  background: "#3a569a",
                  color: "white",
                  ":hover": {
                    background: "#3a569a",
                    color: "#fff",
                  },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Confirm Registration"
                )}
              </Button>
            </Box>
          </CardContent>
        </Card>
        {/* Success Dialog */}
        <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog}>
          <DialogContent
            sx={{
              backgroundColor: "#24a9dd",
              color: "white",
              padding: {
                xs: "50px 10px 8px 10px",
                sm: "50px 15px 8px 15px",
                md: "50px 20px 8px 20px",
              },
              minWidth: "300px",
            }}
          >
            <Typography variant="body1" sx={{ fontSize: "16px" }}>
              {successText}
            </Typography>
            <IconButton
              onClick={handleCloseSuccessDialog}
              sx={{
                position: "absolute",
                top: "8px",
                right: "8px",
                border: "2px solid black",
                p: "2px",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: "#24a9dd",
            }}
          >
            <Button
              onClick={handleCloseSuccessDialog}
              sx={{
                color: "black",
                background: "white",
                ":hover": { background: "white", color: "black" },
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ConfirmRegistration;
