import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchLevel1Coins = createAsyncThunk(
  "level1Coins/fetchLevel1Coins",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getLevel1Coins`
      );
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Failed to fetch coins";
      return rejectWithValue(errorMessage);
    }
  }
);

const level1CoinsSlice = createSlice({
  name: "level1Coins",
  initialState: {
    level1Coins: [],
    loading: false,
    error: null,
  },
  reducers: {
    setLevel1Coins: (state, action) => {
      state.level1Coins = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLevel1Coins.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLevel1Coins.fulfilled, (state, action) => {
        state.loading = false;
        state.level1Coins = action.payload;
      })
      .addCase(fetchLevel1Coins.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setLevel1Coins } = level1CoinsSlice.actions;
export default level1CoinsSlice.reducer;
