import React from "react";
import { useNavigate } from "react-router-dom";

const DashboardNavbar = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const companyDetails = JSON.parse(localStorage.getItem("companyData"));

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="bg-white p-3 sm:p-4 md:p-5 lg:p-6 flex justify-between items-center">
      <div className="flex items-center">
        {/* Hamburger icon for mobile */}
        <button
          onClick={toggleSidebar}
          className="md:hidden mr-3 focus:outline-none"
        >
          <svg
            className="w-6 h-6 sm:w-7 sm:h-7 md:w-8 md:h-8"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
        {companyDetails?.logo && (
          <img
            src={companyDetails.logo}
            alt="Logo"
            className="h-10 sm:h-12 md:h-16 lg:h-20 mr-3"
          />
        )}
        <span className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold text-gray-800">
          {companyDetails?.company_name}
        </span>
      </div>

      <button
        className="bg-red-500 text-white py-1 px-3 sm:py-2 sm:px-4 md:py-3 md:px-5 lg:py-3 lg:px-6 rounded hover:bg-red-600 focus:outline-none text-sm sm:text-base md:text-lg"
        onClick={handleLogout}
      >
        Logout
      </button>
    </div>
  );
};

export default DashboardNavbar;
