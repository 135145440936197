import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/navbar/Layout";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import angel from "../../assets/angel.png";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupsIcon from "@mui/icons-material/Groups";
import { Add } from "@mui/icons-material";

const data = [
  { title: "Discoverability", additionalInfo: "⭐ 🔆" },
  { title: "Visibility", additionalInfo: "✨ ☀️" },
  { title: "Growth", additionalInfo: "🌿 🌳" },
  { title: "Stability", additionalInfo: "🏡 🌟" },
  { title: "Innovation", additionalInfo: "🌌 💡" },
];

const Page13 = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    if (currentIndex > 0) setCurrentIndex((prev) => prev - 1);
  };

  const goToNext = () => {
    if (currentIndex < data.length - 3) setCurrentIndex((prev) => prev + 1);
  };

  const handleNext = () => {
    navigate("/level2/page14");
  };

  const handleBack = () => {
    navigate("/level2/page9");
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
          marginBottom: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            marginBottom: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            {/* Welcome Message */}
            <Box
              sx={{
                display: "flex",
                padding: "24px 0",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
                gap: { xs: "16px", sm: "0" },
              }}
            >
              <Stack
                direction="row"
                sx={{
                  background: "#FDEED2",
                  border: "2px solid #F7B73B",
                  borderRadius: "999px",
                  alignItems: "center",
                  padding: "4px 16px",
                  fontSize: "16px",
                }}
              >
                Level 2 - Q&A Session
              </Stack>
            </Box>
          </Box>
          {/* main container */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
              marginTop: "40px",
            }}
          >
            {/* middle div */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: 8,
                width: { sm: "100%", md: "80%" },
                padding: "20px 0",
              }}
            >
              {/* left */}
              <Stack
                sx={{
                  width: { xs: "100%", sm: "80%", md: "70%" },
                  paddingX: { xs: 1, sm: 2, md: 4 },
                  maxWidth: { xs: "350px", sm: "400px", md: "460px" },
                  gap: 2,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      width: "24px",
                      height: "24px",
                      flexShrink: 0,
                      background: "#ECECEC",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  >
                    4
                  </div>
                  <Stack sx={{ gap: 2, textAlign: "start" }}>
                    <Typography
                      sx={{
                        color: "#747474",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      About sustainability
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2D2C2C",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 700,
                      }}
                    >
                      What do you want to start?
                    </Typography>
                    <TextareaAutosize
                      minRows={3}
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "16px",
                        padding: "8px",
                        boxSizing: "border-box",
                        maxWidth: { xs: "300px", sm: "350px", md: "auto" },
                      }}
                    />
                    <Typography
                      sx={{
                        color: "#2D2C2C",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 700,
                      }}
                    >
                      What do you want to stop?
                    </Typography>
                    <TextareaAutosize
                      minRows={3}
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "16px",
                        padding: "8px",
                        boxSizing: "border-box",
                        maxWidth: { xs: "300px", sm: "350px", md: "auto" },
                      }}
                    />
                    <Typography
                      sx={{
                        color: "#2D2C2C",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 700,
                      }}
                    >
                      What do you want to continue?
                    </Typography>
                    <TextareaAutosize
                      minRows={3}
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "16px",
                        padding: "8px",
                        boxSizing: "border-box",
                        maxWidth: { xs: "300px", sm: "350px", md: "auto" },
                      }}
                    />
                  </Stack>
                </div>
              </Stack>
              {/* carousel */}
              <Box
                sx={{
                  width: "100%",
                  maxWidth: { xs: "350px", sm: "400px", md: "450px" },
                  mx: "auto",
                  background: "#F9F9F9",
                  borderRadius: "16px",
                  p: "60px 0",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    padding: "16px 8px",
                    borderRadius: "8px",
                    border: "1px solid #F26F21",
                    mb: 8,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      color: "#F26F21",
                      fontSize: "16px",
                    }}
                  >
                    What would ensure that the angel is sustainable ?
                  </Typography>
                </Box>

                {/* Carousel Content */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignItems: "center",
                    overflow: "hidden",
                    mb: 4,
                  }}
                >
                  {data
                    .slice(currentIndex, currentIndex + 3)
                    .map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          flex: "0 0 33.33%",
                          textAlign: "center",
                          transition: "transform 0.3s ease",
                          opacity: index === 1 ? 1 : 0.6,
                          transform: index === 1 ? "scale(1)" : "scale(0.9)",
                          px: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: "115px",
                            height: "115px",
                            borderRadius: "50%",
                            border: "3px solid #a6dba6",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            mb: 2,
                            mx: "auto",
                            boxShadow: "2px 2px 4px #47A968",
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                            textAlign: "center",
                          }}
                        >
                          <img
                            src={angel}
                            alt="Angel"
                            style={{
                              width: "24px",
                              height: "24px",
                              marginBottom: "4px",
                            }}
                          />
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              fontSize: "16px",
                              overflowWrap: "break-word",
                              wordBreak: "break-word",
                              textAlign: "center",
                              overflow: "hidden",
                              lineHeight: "1.2",
                              marginBottom: "4px",
                            }}
                          >
                            {item.title}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              gap: "2px",
                              alignItems: "center",
                              color: "#F7B73B",
                            }}
                          >
                            <GroupsIcon style={{ fontSize: "18px" }} />
                            <Add style={{ fontSize: "14px" }} />
                            <SettingsIcon style={{ fontSize: "18px" }} />
                          </div>
                        </Box>
                      </Box>
                    ))}
                </Box>

                {/* Navigation */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <IconButton
                    onClick={goToPrevious}
                    sx={{
                      mx: 1,
                      border: "1px solid #535353",
                      borderRadius: "50%",
                      backgroundColor:
                        currentIndex === 0 ? "#E0E0E0" : "#FFFFFF",
                    }}
                    disabled={currentIndex === 0}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                  <Typography sx={{ fontSize: "14px", color: "#535353" }}>
                    {currentIndex + 1}/{data.length}
                  </Typography>
                  <IconButton
                    onClick={goToNext}
                    sx={{
                      mx: 1,
                      border: "1px solid #535353",
                      borderRadius: "50%",
                      backgroundColor:
                        currentIndex === data.length - 3
                          ? "#E0E0E0"
                          : "#FFFFFF",
                    }}
                    disabled={currentIndex === data.length - 3}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            {/* bottom div */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-around",
                alignItems: "flex-start",
                gap: { xs: 4, sm: 8 },
                width: { xs: "100%", sm: "100%", md: "80%" },
                padding: "20px 0",
              }}
            >
              <Box
                sx={{
                  marginTop: "40px",
                  display: "flex",
                  alignItems: "center",
                  width: 220,
                  maxHeight: 100,
                }}
              >
                <Typography
                  sx={{ paddingLeft: 2, fontSize: 16, marginRight: "10px" }}
                >
                  4/5
                </Typography>
                <Box
                  sx={{
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#0F1621",
                  }}
                />
                <Box
                  sx={{
                    marginLeft: "4px",
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#0F1621",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                />
                <Box
                  sx={{
                    marginLeft: "4px",
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#0F1621",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                />
                <Box
                  sx={{
                    marginLeft: "4px",
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#0F1621",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                />
                <Box
                  sx={{
                    marginLeft: "4px",
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#D9D9D9",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                />
              </Box>
              {/* Buttons */}
              <Box
                sx={{
                  marginTop: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: 2,
                }}
              >
                <Button
                  onClick={handleBack}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid var(--Primary-black, #0F1621)",
                    background: "#FFF",
                    boxShadow: "3px 3px 0px 0px #0F1621",
                    color: "#0F1621",
                    width: { xs: "100%", sm: "160px" },
                    height: "40px",
                    ":hover": {
                      background: "#FFF",
                      boxShadow: "none",
                    },
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={handleNext}
                  variant="contained"
                  sx={{
                    alignSelf: "center",
                    boxShadow: "3px 3px 0px 0px #0F1621",
                    borderRadius: "8px",
                    width: { xs: "100%", sm: "160px" },
                    height: "40px",
                  }}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Page13;
