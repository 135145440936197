import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/navbar/Layout";
import peopleImage from "../../assets/people.png";
import settingsImage from "../../assets/settings.png";
import peopleTeal from "../../assets/people teal.png";
import settingsTeal from "../../assets/settings teal.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import angel from "../../assets/angel.png";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupsIcon from "@mui/icons-material/Groups";
import { Add } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { setLevel2CoinsDetails } from "../../redux/slices/level2DataSlice";
import { useTheme } from "@emotion/react";

const Page7 = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employeeDetails = JSON.parse(localStorage.getItem("employee"));

  // Get the Redux state.
  const level2CoinsDetails = useSelector(
    (state) => state.level2Data?.level2Data?.level2CoinsDetails
  );

  // Local state for modifications.
  const [localCoinDetails, setLocalCoinDetails] = useState(
    level2CoinsDetails || {}
  );
  useEffect(() => {
    setLocalCoinDetails(level2CoinsDetails || {});
  }, [level2CoinsDetails]);

  // Filter to get up to 10 angel coins from localCoinDetails.
  const angelEntries = Object.entries(localCoinDetails)
    ?.filter(([label, details]) => details.type === "angel")
    ?.slice(0, 10);

  const totalCoins = angelEntries.length;

  const allCoinsSelected =
    angelEntries?.length >= 0 &&
    angelEntries?.every(([label, details]) => details.effected_by);

  // Carousel state: we now use cyclic logic.
  const [currentIndex, setCurrentIndex] = useState(0);

  // Compute the visible coins (always an array of 3 items)
  const visibleCoins = (() => {
    if (totalCoins === 0) return [];
    if (totalCoins >= 3) {
      return [
        angelEntries[currentIndex % totalCoins],
        angelEntries[(currentIndex + 1) % totalCoins],
        angelEntries[(currentIndex + 2) % totalCoins],
      ];
    }
    // For 1 or 2 coins, pad with nulls so that the active coin is always at index 1.
    const activeCoin = angelEntries[currentIndex];
    if (totalCoins === 1) {
      return [null, activeCoin, null];
    }
    if (totalCoins === 2) {
      // When currentIndex is 0, active coin is angelEntries[0] → pad to [null, coin0, coin1]
      // When currentIndex is 1, active coin is angelEntries[1] → pad to [coin0, coin1, null]
      return currentIndex === 0
        ? [null, angelEntries[0], angelEntries[1]]
        : [angelEntries[0], angelEntries[1], null];
    }
  })();

  // Navigation handlers (cyclic)
  const goToNext = () => {
    if (totalCoins > 0) {
      setCurrentIndex((prev) => (prev + 1) % totalCoins);
    }
  };

  const goToPrevious = () => {
    if (totalCoins > 0) {
      setCurrentIndex((prev) => (prev - 1 + totalCoins) % totalCoins);
    }
  };

  // Determine the center coin.
  const activeCoin = visibleCoins[1] || angelEntries[currentIndex];
  const selectedOption = activeCoin ? activeCoin[1].effected_by : null;

  // When a selection (People/Process/Both) is made, update the center coin.
  const handleSelection = (selection) => {
    if (activeCoin) {
      const [coinLabel] = activeCoin;
      setLocalCoinDetails((prev) => ({
        ...prev,
        [coinLabel]: {
          ...prev[coinLabel],
          effected_by: selection,
        },
      }));
    }
  };

  // Returns the proper icon(s) based on the selection.
  const getEffectedByIcon = (effected_by) => {
    switch (effected_by) {
      case "people":
        return <GroupsIcon style={{ fontSize: "16px", color: "#F7B73B" }} />;
      case "process":
        return <SettingsIcon style={{ fontSize: "16px", color: "#F7B73B" }} />;
      case "both":
        return (
          <span>
            <GroupsIcon
              style={{ fontSize: "16px", color: "#F7B73B", marginRight: "2px" }}
            />
            <SettingsIcon style={{ fontSize: "16px", color: "#F7B73B" }} />
          </span>
        );
      default:
        return null;
    }
  };

  // On clicking Next, update Redux state if needed and navigate.
  const handleNext = () => {
    if (
      JSON.stringify(localCoinDetails) !== JSON.stringify(level2CoinsDetails)
    ) {
      dispatch(setLevel2CoinsDetails(localCoinDetails));
    }
    navigate("/level2/page8");
  };

  const handleBack = () => {
    navigate("/level2/page6");
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
          marginBottom: "40px",
        }}
      >
        <Box sx={{ width: "100%", position: "relative" }}>
          {/* welcome */}
          <Box
            sx={{
              display: "flex",
              padding: "24px 0",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
              gap: { xs: "16px", sm: "0" },
            }}
          >
            <Stack
              direction="row"
              sx={{
                background: "#FDEED2",
                border: "2px solid #F7B73B",
                borderRadius: "999px",
                alignItems: "center",
                padding: "4px 16px",
                fontSize: "16px",
              }}
            >
              {employeeDetails?.employeeKey && " Level 2 - "}
              Angel & Demons
            </Stack>
          </Box>
          {isLargeScreen && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                position: "absolute",
                top: 6,
                right: 0,
              }}
            >
              <Button
                onClick={handleNext}
                variant="contained"
                sx={{
                  alignSelf: "center",
                  boxShadow: "3px 3px 0px 0px #0F1621",
                  borderRadius: "8px",
                  width: { xs: "100%", sm: "120px" },
                  height: "32px",
                }}
              >
                Next
              </Button>
              <Button
                onClick={handleBack}
                sx={{
                  borderRadius: "8px",
                  border: "1px solid var(--Primary-black, #0F1621)",
                  background: "#FFF",
                  boxShadow: "3px 3px 0px 0px #0F1621",
                  color: "#0F1621",
                  width: { xs: "100%", sm: "120px" },
                  height: "32px",
                  marginTop: "12px",
                  ":hover": {
                    background: "#FFF",
                    boxShadow: "none",
                  },
                }}
              >
                Back
              </Button>
            </Box>
          )}
        </Box>
        {/* Main Container */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            marginTop: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: 8,
              width: { sm: "100%", md: "80%" },
              padding: "20px 0",
            }}
          >
            {/* Left: Selection Options */}
            <Stack
              sx={{
                width: { xs: "100%", sm: "80%", md: "70%" },
                paddingX: { xs: 1, sm: 2, md: 4 },
                maxWidth: { xs: "350px", sm: "400px", md: "460px" },
                gap: 2,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    width: "24px",
                    height: "24px",
                    flexShrink: 0,
                    background: "#ECECEC",
                    borderRadius: "50%",
                    marginRight: "10px",
                  }}
                >
                  1
                </div>
                <Stack sx={{ gap: 2, textAlign: "start" }}>
                  <Typography
                    sx={{ color: "#747474", fontSize: "14px", fontWeight: 400 }}
                  >
                    About strength
                  </Typography>
                  <Typography
                    sx={{
                      color: "#2D2C2C",
                      fontSize: { xs: "16px", sm: "20px" },
                      fontWeight: 700,
                    }}
                  >
                    Tell us whether these coins are strengthened by
                  </Typography>
                </Stack>
              </div>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                {/* People Option */}
                <Box
                  onClick={() => handleSelection("people")}
                  sx={{
                    border: "1px solid #E2E2E2",
                    borderRadius: "8px",
                    width: "116px",
                    height: "79px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    background:
                      selectedOption === "people" ? "#4799A9" : "#FFF",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        selectedOption === "people" ? peopleTeal : peopleImage
                      }
                      style={{ height: "24px", width: "24px" }}
                      alt="People"
                    />
                    <Typography
                      sx={{
                        color: selectedOption === "people" ? "#FFF" : "#0F1621",
                        fontSize: "16px",
                      }}
                    >
                      People
                    </Typography>
                  </Stack>
                </Box>
                {/* Process Option */}
                <Box
                  onClick={() => handleSelection("process")}
                  sx={{
                    border: "1px solid #E2E2E2",
                    borderRadius: "8px",
                    width: "116px",
                    height: "79px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    background:
                      selectedOption === "process" ? "#4799A9" : "#FFF",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        selectedOption === "process"
                          ? settingsTeal
                          : settingsImage
                      }
                      style={{ height: "24px", width: "24px" }}
                      alt="Process"
                    />
                    <Typography
                      sx={{
                        color:
                          selectedOption === "process" ? "#FFF" : "#0F1621",
                        fontSize: "16px",
                      }}
                    >
                      Process
                    </Typography>
                  </Stack>
                </Box>
                {/* Both Option */}
                <Box
                  onClick={() => handleSelection("both")}
                  sx={{
                    border: "1px solid #E2E2E2",
                    borderRadius: "8px",
                    width: "116px",
                    height: "79px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    background: selectedOption === "both" ? "#4799A9" : "#FFF",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", gap: "4px" }}>
                      <img
                        src={
                          selectedOption === "both" ? peopleTeal : peopleImage
                        }
                        style={{ height: "24px", width: "24px" }}
                        alt="People"
                      />
                      <p
                        style={{
                          color: selectedOption === "both" ? "white" : "black",
                        }}
                      >
                        +
                      </p>
                      <img
                        src={
                          selectedOption === "both"
                            ? settingsTeal
                            : settingsImage
                        }
                        style={{ height: "24px", width: "24px" }}
                        alt="Process"
                      />
                    </div>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: selectedOption === "both" ? "#FFF" : "#0F1621",
                      }}
                    >
                      Both
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Stack>

            {/* Carousel Section */}
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                maxWidth: "500px",
                maxHeight: "500px",
                mx: "auto",
                background: "#F9F9F9",
                borderRadius: "16px",
                p: "12px 0",
                textAlign: "center",
                overflow: "hidden",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: 2,
                  fontWeight: 600,
                  color: "#0F1621",
                  fontSize: "16px",
                }}
              >
                Chosen Angels
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                {visibleCoins?.map((coin, index) => {
                  if (!coin) {
                    return (
                      <Box
                        key={index}
                        sx={{ flex: "0 0 calc(100%/3)", px: 1 }}
                      />
                    );
                  }
                  const [label, details] = coin;
                  // The active (selected) coin is always at index 1.
                  const isActive = index === 1;
                  return (
                    <Box
                      key={label}
                      sx={{
                        flex: "0 0 calc(100%/3)",
                        textAlign: "center",
                        transition: "transform 0.3s ease",
                        opacity: isActive ? 1 : 0.6,
                        transform: isActive ? "scale(1)" : "scale(0.9)",
                        px: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: "115px",
                          height: "115px",
                          borderRadius: "50%",
                          border: isActive
                            ? "3px solid #a6dba6"
                            : "3px solid #D3D3D3",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          mb: 2,
                          mx: "auto",
                          boxShadow: isActive
                            ? "2px 2px 4px #47A968"
                            : "2px 2px 4px #D3D3D3",
                          textAlign: "center",
                          p: 1,
                        }}
                      >
                        <img
                          src={details.type === "angel" ? angel : ""}
                          alt="Angel"
                          style={{
                            width: "24px",
                            height: "24px",
                            marginBottom: "4px",
                          }}
                        />
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: 600,
                            fontSize: "12px",
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                          }}
                        >
                          {label}
                        </Typography>
                        {details.effected_by && (
                          <Box sx={{ mt: 1 }}>
                            {getEffectedByIcon(details.effected_by)}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              {/* Navigation Buttons */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                {totalCoins > 1 && (
                  <>
                    <IconButton
                      onClick={goToPrevious}
                      sx={{
                        mx: 1,
                        border: "1px solid #535353",
                        borderRadius: "50%",
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                    <Typography sx={{ fontSize: "14px", color: "#535353" }}>
                      {(currentIndex % totalCoins) + 1}/{totalCoins}
                    </Typography>
                    <IconButton
                      onClick={goToNext}
                      sx={{
                        mx: 1,
                        border: "1px solid #535353",
                        borderRadius: "50%",
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </Box>
          </Box>

          {/* Bottom Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-around",
              alignItems: "flex-start",
              gap: { xs: 4, sm: 8 },
              width: { xs: "100%", sm: "100%", md: "80%" },
              padding: "20px 0",
            }}
          >
            <Box
              sx={{
                marginTop: "40px",
                display: "flex",
                alignItems: "center",
                width: 220,
                maxHeight: 100,
              }}
            >
              <Typography
                sx={{
                  paddingLeft: { xs: "0", sm: "0", md: 2 },
                  fontSize: 16,
                  marginRight: "10px",
                }}
              >
                1/2
              </Typography>
              <Box
                sx={{
                  height: "10px",
                  borderRadius: "1rem",
                  width: 100,
                  backgroundColor: "#0F1621",
                }}
              />
              <Box
                sx={{
                  marginLeft: "4px",
                  height: "10px",
                  borderRadius: "1rem",
                  width: 100,
                  backgroundColor: "#D9D9D9",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              />
            </Box>
            {/* buttons */}
            {!isLargeScreen && (
              <Box
                sx={{
                  margin: "40px 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  flexDirection: { xs: "column", sm: "row" },
                  width: "100%",
                }}
              >
                <Button
                  onClick={handleBack}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid var(--Primary-black, #0F1621)",
                    background: "#FFF",
                    boxShadow: "3px 3px 0px 0px #0F1621",
                    color: "#0F1621",
                    width: { xs: "100%", sm: "160px" },
                    height: "40px",
                    ":hover": {
                      background: "#FFF",
                      boxShadow: "none",
                    },
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={handleNext}
                  variant="contained"
                  sx={{
                    alignSelf: "center",
                    boxShadow: "3px 3px 0px 0px #0F1621",
                    borderRadius: "8px",
                    width: { xs: "100%", sm: "160px" },
                    height: "40px",
                  }}
                >
                  Next
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Page7;
