import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  TextareaAutosize,
  Tooltip,
  CircularProgress,
  useMediaQuery,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/navbar/Layout";
import image1 from "../../assets/game board.png";
import angelImg from "../../assets/angel.png";
import demonImg from "../../assets/demon.png";
import { useDispatch, useSelector } from "react-redux";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import { setLevel2Comments } from "../../redux/slices/level2DataSlice";
import axios from "axios";
import { useTheme } from "@emotion/react";

const placeholderKeys = [
  "customer_1_coins",
  "customer_2_coins",
  "customer_3_coins",
  "customer_4_coins",
  "customer_employee_coins",
  "customer_employee_strategy_coins",
  "customer_strategy_coins",
  "employee_1_coins",
  "employee_2_coins",
  "employee_3_coins",
  "employee_4_coins",
  "employee_strategy_coins",
  "strategy_1_coins",
  "strategy_2_coins",
  "strategy_3_coins",
  "strategy_4_coins",
];

const placeholders = [
  { id: "customer_1_coins", x: "27%", y: "8%" },
  { id: "customer_2_coins", x: "13%", y: "17%" },
  { id: "customer_3_coins", x: "12%", y: "35%" },
  { id: "customer_4_coins", x: "24%", y: "27%" },
  { id: "customer_employee_coins", x: "43%", y: "19%" },
  { id: "customer_employee_strategy_coins", x: "43%", y: "37%" },
  { id: "customer_strategy_coins", x: "28%", y: "44%" },
  { id: "employee_1_coins", x: "59%", y: "9%" },
  { id: "employee_2_coins", x: "73%", y: "17%" },
  { id: "employee_3_coins", x: "62%", y: "27%" },
  { id: "employee_4_coins", x: "74%", y: "35%" },
  { id: "employee_strategy_coins", x: "57%", y: "44%" },
  { id: "strategy_1_coins", x: "43%", y: "59%" },
  { id: "strategy_2_coins", x: "27%", y: "63%" },
  { id: "strategy_3_coins", x: "59%", y: "62%" },
  { id: "strategy_4_coins", x: "43%", y: "74%" },
];

const DraggableCoinPlaceholder = ({ label, sticker, effected_by }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "black",
        fontSize: "7pt",
        width: { xs: "42px", sm: "50px", md: "50px", lg: "60px" },
        height: { xs: "42px", sm: "50px", md: "50px", lg: "60px" },
        borderRadius: "50%",
        position: "relative",
        padding: "2px",
      }}
    >
      {/* Sticker (Middle) */}
      {sticker && (
        <Box
          sx={{
            width: "12px",
            height: "12px",
            mt: "1px",
          }}
        >
          <img
            src={sticker === "angel" ? angelImg : demonImg}
            alt={sticker}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      )}
      {/* Label (Top) */}
      <Typography
        sx={{
          maxWidth: "100%",
          textAlign: "center",
          fontSize: { xs: "7px", sm: "8px", md: "9px" },
          marginTop: "2px",
          lineHeight: 1,
          wordBreak: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {label}
      </Typography>

      {/* Effected By Icons (Bottom) */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "2px",
          mt: "1px", // Reduced margin
        }}
      >
        {effected_by === "people" && (
          <PeopleIcon sx={{ fontSize: "9px", color: "#F7B73B" }} />
        )}
        {effected_by === "process" && (
          <SettingsIcon sx={{ fontSize: "9px", color: "#F7B73B" }} />
        )}
        {effected_by === "both" && (
          <>
            <PeopleIcon sx={{ fontSize: "9px", color: "#F7B73B" }} />
            <SettingsIcon sx={{ fontSize: "9px", color: "#F7B73B" }} />
          </>
        )}
      </Box>
    </Box>
  );
};

const CoinDropPlaceholder = ({ x, y, placeholderId, coins }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: y,
        left: x,
        width: { xs: "50px", sm: "50px", md: "60px" },
        height: { xs: "50px", sm: "50px", md: "60px" },
        borderRadius: "50%",
        backgroundColor: "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: coins && coins.length > 1 ? "pointer" : "default",
      }}
    >
      {coins && coins.length > 0 && (
        <Tooltip
          title={coins
            .map((coin) => {
              let effectedByText = "";
              if (coin.effected_by === "people")
                effectedByText = "Effected by: People";
              if (coin.effected_by === "process")
                effectedByText = "Effected by: Process";
              if (coin.effected_by === "both")
                effectedByText = "Effected by: Both";

              return `${coin.label} ${
                coin.sticker ? `(${coin.sticker})` : ""
              } ${effectedByText ? `- ${effectedByText}` : ""}`;
            })
            .join(", ")}
          arrow
        >
          <div>
            {coins.length > 1 ? (
              // For multi-coin placeholders, display the count.
              <DraggableCoinPlaceholder label={coins.length} />
            ) : (
              // For a single coin, pass its sticker and effected_by.
              <DraggableCoinPlaceholder
                label={coins[0].label}
                sticker={coins[0].sticker}
                effected_by={coins[0].effected_by}
              />
            )}
          </div>
        </Tooltip>
      )}
    </Box>
  );
};

// Droppable Image Component that holds the coins
const DroppableImage = ({
  imageSrc,
  placeholders,
  updatedCoinsWithStickerAndEffectedby,
}) => {
  return (
    <Box sx={{ position: "relative" }}>
      <img
        src={imageSrc}
        alt="Game Board"
        style={{
          width: "100%",
          height: "auto",
          borderRadius: "12px",
          boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
        }}
      />
      {placeholders?.map((pos) => (
        <CoinDropPlaceholder
          key={pos.id}
          x={pos.x}
          y={pos.y}
          placeholderId={pos.id}
          coins={updatedCoinsWithStickerAndEffectedby[pos.id] || []}
        />
      ))}
    </Box>
  );
};

const Page14 = () => {
  const companyDetails = JSON.parse(localStorage.getItem("companyData"));
  const employeeDetails = JSON.parse(localStorage.getItem("employee"));
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const level2CoinsDetails = useSelector(
    (state) => state.level2Data?.level2Data?.level2CoinsDetails
  );
  const level2CoinswithSticker = useSelector(
    (state) => state.level2Data?.level2Data?.level2CoinswithSticker
  );

  const level2Comments = useSelector(
    (state) => state.level2Data?.level2Data?.level2Comments
  );

  const level2PlacedCoins = useSelector(
    (state) => state.level2Data?.level2Data?.level2PlacedCoins
  );

  // Add effected_by in level2CoinwithSticker
  const updatedCoinsWithStickerAndEffectedby = Object.entries(
    level2CoinswithSticker
  )?.reduce((acc, [key, coins]) => {
    acc[key] = coins?.map((coin) => ({
      ...coin,
      effected_by: level2CoinsDetails[coin.label]?.effected_by ?? null,
    }));
    return acc;
  }, {});

  // Tabs definitions
  const tabs = [
    "customer",
    "employee",
    "strategy",
    "delivery",
    "alignment",
    "engagement",
    "core",
  ];

  // Define tab colors for selected state
  const tabColors = {
    customer: "#F1D6BB",
    employee: "#CEEAE1",
    strategy: "#DBEFFD",
    delivery: "#D3FBFB",
    alignment: "#EEE9CE",
    engagement: "#E6D7E8",
    core: "#BFBDD2",
  };

  const getLabels = (key) =>
    level2CoinswithSticker[key]
      ? level2CoinswithSticker[key]?.map((c) => c.label)
      : [];

  const tabsData = {
    customer: [
      ...getLabels("customer_1_coins"),
      ...getLabels("customer_2_coins"),
      ...getLabels("customer_3_coins"),
      ...getLabels("customer_4_coins"),
    ],
    employee: [
      ...getLabels("employee_1_coins"),
      ...getLabels("employee_2_coins"),
      ...getLabels("employee_3_coins"),
      ...getLabels("employee_4_coins"),
    ],
    strategy: [
      ...getLabels("strategy_1_coins"),
      ...getLabels("strategy_2_coins"),
      ...getLabels("strategy_3_coins"),
      ...getLabels("strategy_4_coins"),
    ],
    delivery: getLabels("customer_employee_coins"),
    core: getLabels("customer_employee_strategy_coins"),
    alignment: getLabels("customer_strategy_coins"),
    engagement: getLabels("employee_strategy_coins"),
  };

  // State for selected tab and its comment
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [tabComments, setTabComments] = useState(level2Comments || {});
  const [loading, setLoading] = useState(false);

  const postLevel2Comments = async (record_id) => {
    const dataToPost = new URLSearchParams();

    dataToPost.append("level2comments", JSON.stringify(tabComments));
    dataToPost.append(
      "company_id",
      employeeDetails?.company_id || companyDetails?.id
    );
    dataToPost.append("employeeKey", employeeDetails?.employeeKey || 99999999);
    dataToPost.append("recordId", record_id);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/addLevel2Comments`,
        dataToPost,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("API Call Error", error);
      setErrorMessage("Something went wrong!");
      return null;
    }
  };

  const postLevel2CoinDetails = async (record_id) => {
    const dataToPost = new URLSearchParams();
    // const dataToPost = {
    //   level2coins_details: JSON.stringify(level2CoinsDetails),
    //   company_id: employeeDetails?.company_id,
    //   employeeKey: employeeDetails?.employeeKey,
    //   recordId: record_id,
    // };

    dataToPost.append(
      "level2coins_details",
      JSON.stringify(level2CoinsDetails)
    );
    dataToPost.append(
      "company_id",
      employeeDetails?.company_id || companyDetails?.id
    );
    dataToPost.append("employeeKey", employeeDetails?.employeeKey || 99999999);
    dataToPost.append("recordId", record_id);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/addLevel2CoinDetails`,
        dataToPost,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("API Call Error", error);
      setErrorMessage("Something went wrong!");
      return null;
    }
  };

  const postLevel2Data = async () => {
    const updatedLevel2PlacedCoins = {
      ...level2PlacedCoins,
      company_id: employeeDetails?.company_id || companyDetails?.id,
      employeeKey: employeeDetails?.employeeKey,
    };

    // add remaining placeholders
    let dataToPost = {};

    placeholderKeys?.forEach((placeholder) => {
      dataToPost[placeholder] = updatedLevel2PlacedCoins[placeholder] || null; // each placeholder is required
    });
    dataToPost.company_id = employeeDetails?.company_id || companyDetails?.id;
    dataToPost.employeeKey = employeeDetails?.employeeKey || 99999999;
    // convert values from array of strings to comma separated values
    Object.keys(dataToPost)?.forEach((key) => {
      if (Array.isArray(dataToPost[key])) {
        dataToPost[key] = dataToPost[key].join(",");
      }
    });

    const urlEncodedData = new URLSearchParams(dataToPost).toString();

    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/addLevel2Coins`,
        urlEncodedData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.data?.success) {
        const record_id = response.data?.recordId;
        // post other two
        const [coinDetailsRes, commentsRes] = await Promise.all([
          postLevel2CoinDetails(record_id),
          postLevel2Comments(record_id),
        ]);
        if (coinDetailsRes?.success && commentsRes?.success) {
          // handle management view
          employeeDetails?.employeeKey
            ? navigate("/level2/result")
            : setSuccessMessage("Data saved Successfully!");
        } else {
          console.error("One of the API calls failed, not navigating.");
          setErrorMessage("Something went wrong!");
        }
      }
    } catch (error) {
      console.error("API Call Error", error);
      setErrorMessage("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const handleNext = async () => {
    dispatch(setLevel2Comments(tabComments));
    await postLevel2Data();
    // await postLevel2Comments(40); // remove it
  };

  const handleBack = () => {
    dispatch(setLevel2Comments(tabComments));
    navigate("/level2/page9");
  };

  // management view
  const handleSave = async () => {
    dispatch(setLevel2Comments(tabComments));
    await postLevel2Data();
    // await postLevel2Comments(40); // remove it
  };

  const handleClose = () => {
    dispatch(setLevel2Comments(tabComments));
    navigate("/level2/page1");
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
          marginBottom: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            marginBottom: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            {/* Welcome Message */}
            <Box
              sx={{
                display: "flex",
                padding: "24px 0",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
                gap: { xs: "16px", sm: "0" },
                position: "relative",
              }}
            >
              <Stack
                direction="row"
                sx={{
                  background: "#FDEED2",
                  border: "2px solid #F7B73B",
                  borderRadius: "999px",
                  alignItems: "center",
                  padding: "4px 16px",
                  fontSize: "16px",
                }}
              >
                {employeeDetails?.employeeKey && "Level 2 - "}
                Q&A session
              </Stack>
              {isLargeScreen &&
                (employeeDetails?.employeeKey ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                  >
                    <Button
                      onClick={handleNext}
                      variant="contained"
                      sx={{
                        alignSelf: "center",
                        boxShadow: "3px 3px 0px 0px #0F1621",
                        borderRadius: "8px",
                        width: { xs: "100%", sm: "120px" },
                        height: "32px",
                      }}
                    >
                      Next
                    </Button>
                    <Button
                      onClick={handleBack}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid var(--Primary-black, #0F1621)",
                        background: "#FFF",
                        boxShadow: "3px 3px 0px 0px #0F1621",
                        color: "#0F1621",
                        width: { xs: "100%", sm: "120px" },
                        height: "32px",
                        marginTop: "12px",
                        ":hover": {
                          background: "#FFF",
                          boxShadow: "none",
                        },
                      }}
                    >
                      Back
                    </Button>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                  >
                    <Button
                      onClick={handleSave}
                      variant="contained"
                      sx={{
                        alignSelf: "center",
                        boxShadow: "3px 3px 0px 0px #0F1621",
                        borderRadius: "8px",
                        width: { xs: "100%", sm: "120px" },
                        height: "32px",
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={handleClose}
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid var(--Primary-black, #0F1621)",
                        background: "#FFF",
                        boxShadow: "3px 3px 0px 0px #0F1621",
                        color: "#0F1621",
                        width: { xs: "100%", sm: "120px" },
                        height: "32px",
                        marginTop: "12px",
                        ":hover": {
                          background: "#FFF",
                          boxShadow: "none",
                        },
                      }}
                    >
                      Close
                    </Button>
                  </Box>
                ))}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: 8,
              width: { sm: "100%", md: "80%" },
              padding: "20px 0",
            }}
          >
            {/* Left Part with Tabs */}
            <Stack
              sx={{
                width: { xs: "100%", sm: "80%", md: "70%" },
                paddingX: { xs: 0, sm: 2, md: 4 },
                maxWidth: { xs: "350px", sm: "400px", md: "450px" },
                gap: 2,
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {/* Tabs Navigation */}
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flexWrap: "wrap",
                  justifyContent: "justify",
                }}
              >
                {tabs?.map((tab) => {
                  const isSelected = selectedTab === tab;
                  return (
                    <Button
                      key={tab}
                      onClick={() => setSelectedTab(tab)}
                      sx={{
                        backgroundColor: isSelected ? tabColors[tab] : "#ccc",
                        color: isSelected ? "black" : "#000",
                        textTransform: "capitalize",
                        borderRadius: "8px",
                        padding: "4px 12px",
                        "&:hover": {
                          backgroundColor: isSelected ? tabColors[tab] : "#aaa",
                        },
                      }}
                    >
                      {tab}
                    </Button>
                  );
                })}
              </Box>
              {/* Selected Tab Content */}
              <Typography
                sx={{
                  color: "#2D2C2C",
                  fontSize: { xs: "14px", sm: "16px" },
                  fontWeight: 600,
                  mt: 2,
                  textAlign: "left",
                }}
              >
                These are the coins you placed in {selectedTab} section
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "14px",
                  background: "#ccc",
                  p: "4px 8px",
                  borderRadius: "4px",
                  textAlign: "left",
                }}
              >
                {tabsData[selectedTab] && tabsData[selectedTab].length > 0
                  ? tabsData[selectedTab].join(", ")
                  : "No coins placed"}
              </Typography>

              <Typography
                sx={{
                  color: "#2D2C2C",
                  fontSize: { xs: "14px", sm: "16px" },
                  fontWeight: 600,
                  mt: 2,
                  textAlign: "left",
                }}
              >
                Is there anything that you want to add or comment?
              </Typography>
              {/* Textarea for Comment */}
              <TextareaAutosize
                minRows={8}
                value={tabComments[selectedTab]?.comment_1 || ""}
                onChange={(e) =>
                  setTabComments((prev) => ({
                    ...prev,
                    [selectedTab]: {
                      comment_1: e.target.value,
                      comment_2: null,
                    },
                  }))
                }
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "16px",
                  padding: "8px",
                  boxSizing: "border-box",
                  width: "100%",
                }}
              />
            </Stack>

            {/* Right Part with Image */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: { xs: "100%", md: "50%" },
                maxWidth: "500px",
                maxHeight: "500px",
                paddingX: { xs: "10px", sm: "16px" },
                paddingY: { xs: "16px", sm: "28px" },
              }}
            >
              <Box
                sx={{
                  flex: { xs: "1 1 auto", md: "1 1 50%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  maxWidth: "100%",
                }}
              >
                <DroppableImage
                  imageSrc={image1}
                  placeholders={placeholders}
                  updatedCoinsWithStickerAndEffectedby={
                    updatedCoinsWithStickerAndEffectedby
                  }
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: 8,
              width: { sm: "100%", md: "80%" },
              padding: "20px 0",
            }}
          >
            <Box
              sx={{
                marginTop: "40px",
                display: "flex",
                alignItems: "center",
                width: 220,
                maxHeight: 100,
              }}
            >
              {/* Progress Indicator (if needed) */}
            </Box>
            {!isLargeScreen && (
              <Box
                sx={{
                  margin: "40px 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  flexDirection: { xs: "column", sm: "row" },
                  width: "100%",
                }}
              >
                <Button
                  onClick={handleBack}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid var(--Primary-black, #0F1621)",
                    background: "#FFF",
                    boxShadow: "3px 3px 0px 0px #0F1621",
                    color: "#0F1621",
                    width: { xs: "100%", sm: "160px" },
                    height: "40px",
                    ":hover": {
                      background: "#FFF",
                      boxShadow: "none",
                    },
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={handleNext}
                  variant="contained"
                  sx={{
                    alignSelf: "center",
                    boxShadow: "3px 3px 0px 0px #0F1621",
                    borderRadius: "8px",
                    width: { xs: "100%", sm: "160px" },
                    height: "40px",
                  }}
                >
                  Next
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        <Snackbar
          open={!!successMessage}
          autoHideDuration={3000}
          onClose={() => setSuccessMessage("")}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setSuccessMessage("")}
            severity="success"
            variant="filled"
          >
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={3000}
          onClose={() => setErrorMessage("")}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setErrorMessage("")}
            severity="error"
            variant="filled"
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Layout>
  );
};

export default Page14;
