import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Note: update level1Coins api to level3Coins api
export const fetchLevel3Coins = createAsyncThunk(
  "level3Data/fetchLevel3Coins",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getLevel1Coins`
      );
      const newCoins = JSON.parse(localStorage.getItem("newCoins")) || [];
      const mergedCoins = [...(response.data || []), ...newCoins];
      return mergedCoins;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch level 3 coins"
      );
    }
  }
);

const level3DataSlice = createSlice({
  name: "level3Data",
  initialState: {
    level3Data: {
      level3Coins: [],
      level3PlacedCoins: {}, // to be posted
      level3CoinswithSticker: {},
      level3CoinsDetails: {}, // to be posted
      level3Comments: {}, // to be posted
    },
    loading: false,
    error: null,
  },
  reducers: {
    setLevel3Coins: (state, action) => {
      state.level3Data.level3Coins = action.payload;
    },
    setLevel3PlacedCoins: (state, action) => {
      state.level3Data.level3PlacedCoins = action.payload;
    },
    setLevel3CoinsWithSticker: (state, action) => {
      state.level3Data.level3CoinswithSticker = action.payload;
    },
    setLevel3CoinsDetails: (state, action) => {
      state.level3Data.level3CoinsDetails = action.payload;
    },
    setLevel3Comments: (state, action) => {
      state.level3Data.level3Comments = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLevel3Coins.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error when a new request is made
      })
      .addCase(fetchLevel3Coins.fulfilled, (state, action) => {
        state.loading = false;
        state.level3Data.level3Coins = action.payload;
      })
      .addCase(fetchLevel3Coins.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setLevel3Coins,
  setLevel3PlacedCoins,
  setLevel3CoinsWithSticker,
  setLevel3CoinsDetails,
  setLevel3Comments,
} = level3DataSlice.actions;
export default level3DataSlice.reducer;
