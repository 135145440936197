import React, { useContext, useEffect, useState } from "react";
import { Stack, Button, Typography, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/navbar/Layout";
import { Check } from "@mui/icons-material";
import EmployeeContext from "../../context/EmployeeContext";
import axios from "axios";

const Welcome = () => {
  const { key } = useParams();
  const { employeeDetails, setEmployeeDetails } = useContext(EmployeeContext);
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();
  const [companyDetails, setCompanyDetails] = useState(
    JSON.parse(localStorage.getItem("companyData"))
  );

  const fetchCompanyDetails = async (companyId) => {
    if (!companyId) return;
    // Fetch updated company details
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/company/${companyId}`
    );

    // Update local storage
    localStorage.setItem("companyData", JSON.stringify(res.data));
    setCompanyDetails(res.data);
  };

  useEffect(() => {
    if (!key) return;
    const fetchEmployeeDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/getEmployee/${key}`
        );
        localStorage.setItem("employee", JSON.stringify(response.data));
        setEmployeeDetails(response.data);
        // fetch company details to get the video2
        fetchCompanyDetails(response.data?.company_id);
      } catch (err) {
        console.error("Error fetching employee details", err);
      }
    };
    fetchEmployeeDetails();
  }, [key]);

  const handleProceed = () => {
    navigate("/level3/page1");
  };

  const steps = [
    { label: "Level 1", time: "15:00" },
    { label: "Level 2", time: "15:00" },
    { label: "Level 3", time: "Team meetup" },
  ];

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "60px",
        }}
      >
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center", // Ensure all content inside the stack is centered
            textAlign: "center",
            maxWidth: "750px",
          }}
        >
          {/* Welcome Message */}
          <Box
            sx={{
              padding: "24px 0",
              width: "90%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              Welcome{" "}
              {employeeDetails?.associate_name ||
                JSON.parse(localStorage.getItem("companyData"))?.admin_name}
              !
            </Typography>
            <Typography sx={{ margin: "20px 0", fontSize: "16px" }}>
              Congratulations on completing the first level. The Instructions to
              complete the profile is given below. In this level you need to
              create a picture of “How you will solve the problem or strategy if
              you are in charge”
            </Typography>
          </Box>

          {/* Step Indicators */}
          <Box sx={{ marginBottom: "20px" }}>
            <Stack direction="row" justifyContent="center" alignItems="center">
              {steps.map((step, index) => (
                <React.Fragment key={index}>
                  <Box
                    sx={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      border: "2px solid",
                      borderColor:
                        currentStep > index
                          ? "#4799A9"
                          : currentStep === index
                          ? "#4799A9"
                          : "#ccc",
                      backgroundColor:
                        currentStep > index
                          ? "#4799A9"
                          : currentStep === index
                          ? "#4799A9"
                          : "transparent",
                      color:
                        currentStep > index
                          ? "#fff"
                          : currentStep === index
                          ? "#fff"
                          : "#000",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                      position: "relative",
                    }}
                  >
                    {currentStep > index ? (
                      <Check sx={{ fontSize: "24px" }} />
                    ) : (
                      index + 1
                    )}
                  </Box>
                  {index < steps.length - 1 && (
                    <Box
                      sx={{
                        height: "4px",
                        width: {
                          xs: "50px",
                          sm: "75px",
                          md: "100px",
                        },
                        backgroundColor:
                          currentStep > index ? "#4799A9" : "#ccc",
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </Stack>
            <Stack
              direction="row"
              spacing={10}
              justifyContent="center"
              marginTop="10px"
            >
              {steps.map((step, index) => (
                <Stack key={step.label} alignItems="center">
                  <Typography
                    sx={{
                      color:
                        currentStep > index
                          ? "#00796b"
                          : currentStep === index
                          ? "#00796b"
                          : "#777",
                    }}
                  >
                    {step.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color:
                        currentStep > index
                          ? "#00796b"
                          : currentStep === index
                          ? "#00796b"
                          : "#aaa",
                    }}
                  >
                    {step.time}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Box>

          {/* Video Section */}
          <Box
            sx={{
              marginBottom: "20px",
              width: "90%",
              maxWidth: "846px",
            }}
          >
            <video
              width="100%"
              height="auto"
              autoPlay
              muted
              loop
              controls
              style={{
                borderRadius: "16px",
              }}
            >
              <source src={companyDetails?.video2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>

          {/* Proceed Button */}
          <Button
            onClick={handleProceed}
            variant="contained"
            sx={{
              alignSelf: "center",
              boxShadow: "3px 3px 0px 0px #0F1621",
              borderRadius: "8px",
              width: { xs: "100%", sm: "160px" },
              height: "40px",
            }}
          >
            Proceed
          </Button>
        </Stack>
      </div>
    </Layout>
  );
};

export default Welcome;
