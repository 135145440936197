import React from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  TextareaAutosize,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/navbar/Layout";
// import image1 from "../../assets/level2 page9.png";
// import image2 from "../../assets/level2 page10 image.png";

const Page10 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/level2/page11");
  };

  const handleBack = () => {
    navigate("/level2/page9");
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
          marginBottom: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            marginBottom: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            {/* Welcome Message */}
            <Box
              sx={{
                display: "flex",
                padding: "24px 0",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
                gap: { xs: "16px", sm: "0" },
              }}
            >
              <Stack
                direction="row"
                sx={{
                  background: "#FDEED2",
                  border: "2px solid #F7B73B",
                  borderRadius: "999px",
                  alignItems: "center",
                  padding: "4px 16px",
                  fontSize: "16px",
                }}
              >
                Level 2 - Q&A session
              </Stack>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
              marginTop: "40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: 8,
                width: { sm: "100%", md: "80%" },
                padding: "20px 0",
              }}
            >
              <Stack
                sx={{
                  width: { xs: "100%", sm: "80%", md: "70%" },
                  paddingX: { xs: 0, sm: 2, md: 4 },
                  maxWidth: { xs: "350px", sm: "400px", md: "450px" },
                  gap: 2,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      width: "24px",
                      height: "24px",
                      flexShrink: 0,
                      background: "#ECECEC",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  >
                    1
                  </div>
                  <Stack sx={{ gap: 2, textAlign: "start" }}>
                    <Typography
                      sx={{
                        color: "#747474",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      About the Venn diagram
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2D2C2C",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 700,
                      }}
                    >
                      Can you please explain this board
                    </Typography>
                  </Stack>
                </div>

                <TextareaAutosize
                  minRows={8}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "16px",
                    padding: "8px",
                    boxSizing: "border-box",
                    marginLeft: "34px",
                    maxWidth: { xs: "300px", sm: "350px", md: "auto" },
                  }}
                />
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  maxWidth: { xs: "360px", sm: "450px", md: "500px" },
                  maxHeight: { xs: "400px", sm: "450px", md: "500px" },
                  paddingX: { xs: "10px", sm: "16px" },
                  paddingY: { xs: "16px", sm: "28px" },
                  background: "#F9F9F9",
                }}
              >
                <Typography sx={{ marginBottom: "16px", fontWeight: 600 }}>
                  Level 2
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: { xs: "300px", sm: "350px", md: "380px" },
                    position: "relative", // Ensure relative positioning for the parent box
                  }}
                >
                  {/* Main Image */}
                  <img
                    // src={image1}
                    alt="Game Board"
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  {/* Top-right Image */}
                  <Box
                    component="img"
                    // src={image2}
                    alt="Top Right Icon"
                    sx={{
                      position: "absolute",
                      top: "-20px",
                      right: "-10px",
                      width: "156px",
                      height: "141px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: 8,
                width: { sm: "100%", md: "80%" },
                padding: "20px 0",
              }}
            >
              <Box
                sx={{
                  marginTop: "40px",
                  display: "flex",
                  alignItems: "center",
                  width: 220,
                  maxHeight: 100,
                }}
              >
                <Typography
                  sx={{ paddingLeft: 2, fontSize: 16, marginRight: "10px" }}
                >
                  1/5
                </Typography>
                <Box
                  sx={{
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#0F1621",
                  }}
                />
                <Box
                  sx={{
                    marginLeft: "4px",
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#D9D9D9",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                />
                <Box
                  sx={{
                    marginLeft: "4px",
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#D9D9D9",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                />
                <Box
                  sx={{
                    marginLeft: "4px",
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#D9D9D9",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                />
                <Box
                  sx={{
                    marginLeft: "4px",
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#D9D9D9",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                />
              </Box>
              {/* Buttons */}
              <Box
                sx={{
                  marginTop: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 2,
                }}
              >
                <Button
                  onClick={handleBack}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid var(--Primary-black, #0F1621)",
                    background: "#FFF",
                    boxShadow: "3px 3px 0px 0px #0F1621",
                    color: "#0F1621",
                    width: { xs: "100%", sm: "160px" },
                    height: "40px",
                    ":hover": {
                      background: "#FFF",
                      boxShadow: "none",
                    },
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={handleNext}
                  variant="contained"
                  sx={{
                    alignSelf: "center",
                    boxShadow: "3px 3px 0px 0px #0F1621",
                    borderRadius: "8px",
                    width: { xs: "100%", sm: "160px" },
                    height: "40px",
                  }}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Page10;
