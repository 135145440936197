import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import loginBg from "../../assets/login_bg.png";
import CloseIcon from "@mui/icons-material/Close";
import LogoHeader from "../../components/LogoHeader";

const Signup = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [formData, setFormData] = useState({
    company_name: "",
    admin_name: "",
    admin_phone: "",
    admin_email: "",
  });
  const [errors, setErrors] = useState({});
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [successText, setSuccessText] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.company_name)
      tempErrors.company_name = "Company name is required";
    if (!formData.admin_name) tempErrors.admin_name = "Admin name is required";
    if (!formData.admin_phone.match(/^\d{10}$/))
      tempErrors.admin_phone = "Enter a valid 10-digit phone number";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.admin_email)) {
      tempErrors.admin_email = "Enter a valid email";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const urlEncodedData = new URLSearchParams();
    Object.keys(formData)?.forEach((key) => {
      urlEncodedData.append(key, formData[key]);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/company`,
        urlEncodedData,
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );
      if (response.data.success) {
        setErrors({});
        setOpenSuccessDialog(true);
        setSuccessText(response.data?.message);
      } else {
        console.error("Registration failed");
      }
    } catch (error) {
      console.error("Signup failed", error);
    }
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
    navigate("/");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <LogoHeader />
      {/* Main Content */}
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: { xs: "column", md: "row" },
          overflow: "hidden",
        }}
      >
        {/* Left Container (Signup Form) */}
        <Box
          sx={{
            background: "linear-gradient(180deg, #3a569a, #0e2a6e)",
            color: "white",
            width: { xs: "100%", sm: "auto", md: "40%" },
            minWidth: { xs: "100%", sm: "70%", md: "auto" },
            minHeight: { xs: "100%", sm: "80%", md: "auto" },
            padding: { xs: 3, sm: 8, md: "50px 30px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "100vh", sm: "100%", md: "auto" },
            m: { xs: "auto", sm: "auto", md: 0 },
            borderRadius: { xs: 0, sm: "10px", md: 0 },
          }}
        >
          <Typography variant="h5" fontWeight="bold" mb={4}>
            Create Account
          </Typography>
          <Box
            component="form"
            sx={{ width: "100%", maxWidth: "300px" }}
            onSubmit={handleSignup}
          >
            {["company_name", "admin_name", "admin_phone", "admin_email"].map(
              (field) => (
                <TextField
                  key={field}
                  fullWidth
                  variant="filled"
                  label={field.replace("_", " ").toUpperCase()}
                  name={field}
                  type={
                    field.includes("phone")
                      ? "tel"
                      : field.includes("email")
                      ? "email"
                      : "text"
                  }
                  value={formData[field]}
                  onChange={handleChange}
                  error={!!errors[field]}
                  helperText={errors[field] || ""}
                  sx={{ mb: 2, backgroundColor: "white", borderRadius: 1 }}
                />
              )
            )}
            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: "#ff9800",
                ":hover": { background: "#ff9800" },
              }}
            >
              Sign Up
            </Button>
            <Typography textAlign="center" color="white" my={2}>
              - or -
            </Typography>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => navigate("/")}
              sx={{
                color: "white",
                borderColor: "white",
                background: "transparent",
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              Login
            </Button>
          </Box>
        </Box>
        {/* Right Container (Background Image with Overlay for Large Screens) */}
        {isLargeScreen && (
          <Box
            sx={{
              width: "60%",
              height: "100vh",
              background: `url(${loginBg}) center center / cover no-repeat`,
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                p: 2,
              }}
            >
              {/* <Typography variant="h4" color="white" mb={2} align="center">
                Welcome back!
              </Typography>
              <Typography variant="body1" color="white" mb={4} align="center">
                Login and start engaging your community!
              </Typography> */}
            </Box>
          </Box>
        )}
      </Box>
      {/* Success Dialog */}
      <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog}>
        <DialogContent
          sx={{
            backgroundColor: "#24a9dd",
            color: "white",
            p: {
              xs: "50px 10px 8px 10px",
              sm: "50px 15px 8px 15px",
              md: "50px 20px 8px 20px",
            },
            minWidth: "300px",
            position: "relative",
          }}
        >
          <Typography variant="body1" sx={{ fontSize: "16px" }}>
            {successText}
          </Typography>
          <IconButton
            onClick={handleCloseSuccessDialog}
            sx={{
              position: "absolute",
              top: "8px",
              right: "8px",
              border: "2px solid black",
              p: "2px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#24a9dd" }}>
          <Button
            onClick={handleCloseSuccessDialog}
            sx={{
              color: "black",
              background: "white",
              ":hover": { background: "white", color: "black" },
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Signup;
