import React, { useState } from "react";
import {
  Stack,
  Button,
  Typography,
  Box,
  Modal,
  TextField,
  Tabs,
  Tab,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/navbar/Layout";
import image1 from "../../assets/level2 page3.png";
import ReactAudioPlayer from "react-audio-player";
import angel from "../../assets/angel.png";
import demon from "../../assets/demon.png";
import { useTheme } from "@emotion/react";

const Page2 = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const employeeDetails = JSON.parse(localStorage.getItem("employee"));

  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (_, newIndex) => {
    setTabIndex(newIndex);
  };
  const handleNext = () => {
    navigate("/level3/page4");
  };

  const handleBack = () => {
    navigate("/level3/page2");
  };

  return (
    <Layout>
      {/* Welcome Message */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%", position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              padding: "12px 0",
              maxWidth: "700px",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              justifySelf: "center",
            }}
          >
            {employeeDetails?.employeeKey && (
              <div
                style={{
                  padding: "4px 16px",
                  borderRadius: "999px",
                  background: "#FDEED2",
                  border: "2px solid #F7B73B",
                  marginBottom: "8px",
                }}
              >
                <p>Level 3</p>
              </div>
            )}

            <Typography variant="h4" fontWeight="bold">
              Identify your strength & weakness
            </Typography>
            <Typography sx={{ margin: "8px 0", fontSize: "16px" }}>
              Identify your strength & weakness by using 10 Angel & demon
              stickers respectively. Place the appropriate sticker on coins that
              reflect your strength or weakness. You don’t have to use all 10
              stickers.
            </Typography>
          </Box>
          {/* Buttons */}
          {isLargeScreen && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <Button
                onClick={handleNext}
                variant="contained"
                sx={{
                  alignSelf: "center",
                  boxShadow: "3px 3px 0px 0px #0F1621",
                  borderRadius: "8px",
                  width: { xs: "100%", sm: "120px" },
                  height: "32px",
                }}
              >
                Next
              </Button>
              <Button
                onClick={handleBack}
                sx={{
                  borderRadius: "8px",
                  border: "1px solid var(--Primary-black, #0F1621)",
                  background: "#FFF",
                  boxShadow: "3px 3px 0px 0px #0F1621",
                  color: "#0F1621",
                  width: { xs: "100%", sm: "120px" },
                  height: "32px",
                  marginTop: "12px",
                  ":hover": {
                    background: "#FFF",
                    boxShadow: "none",
                  },
                }}
              >
                Back
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {/* main */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          marginBottom: "40px",
          gap: 2,
        }}
      >
        {/* left */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            width: { xs: "100%", sm: "100%", md: "40%" },
            marginBottom: { xs: "auto", sm: "auto", md: "60px" },
            borderRadius: "8px",
            height: "100%",
            overflowY: "auto",
          }}
        >
          <div>
            {/* Tabs for Read and Hear Instructions */}
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="Instructions tabs"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#EFE4B0",
                },
              }}
              sx={{
                borderBottom: "2px solid #ccc",
              }}
            >
              <Tab
                label="Read Instructions"
                sx={{
                  padding: "10px 20px",
                  fontWeight: "bold",
                  backgroundColor: tabIndex === 0 ? "#EFE4B0" : "white",
                  color: tabIndex === 0 ? "black" : "black",
                  borderRadius: "5px",
                  "&.Mui-selected": {
                    backgroundColor: "#EFE4B0",
                    color: "black",
                  },
                }}
              />
              <Tab
                label="Listen to Instructions"
                sx={{
                  padding: "10px 20px",
                  fontWeight: "bold",
                  backgroundColor: tabIndex === 1 ? "#EFE4B0" : "white",
                  color: tabIndex === 1 ? "black" : "black",
                  borderRadius: "5px",
                  "&.Mui-selected": {
                    backgroundColor: "#EFE4B0",
                    color: "black",
                  },
                }}
              />
            </Tabs>

            <Box sx={{ margin: "16px auto" }}>
              {/* Read Instructions Tab Content */}
              {tabIndex === 0 && (
                <>
                  <ol>
                    <li>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{ marginBottom: "8px" }}
                      >
                        <strong>Adding Angels and Demons Stickers:</strong>
                      </Typography>
                      <ul
                        style={{
                          paddingLeft: "8%",
                          listStyleType: "disc",
                          marginTop: "4px",
                        }}
                      >
                        <li>
                          <Typography
                            variant="body1"
                            paragraph
                            sx={{ marginBottom: "8px" }}
                          >
                            Once the coins are placed:
                          </Typography>
                        </li>
                        <ul
                          style={{ paddingLeft: "8%", listStyleType: "circle" }}
                        >
                          <li>
                            <Typography
                              variant="body1"
                              paragraph
                              sx={{ marginBottom: "8px" }}
                            >
                              Use Angel stickers to mark strengths in your
                              current approach:
                            </Typography>
                          </li>
                          <li>
                            <Typography
                              variant="body1"
                              paragraph
                              sx={{ marginBottom: "8px" }}
                            >
                              Use Demon stickers to identify weaknesses—areas
                              that may need to be addressed if you implement
                              this strategy or solution.
                            </Typography>
                          </li>
                        </ul>
                      </ul>
                    </li>
                    <li>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{ marginBottom: "8px" }}
                      >
                        <strong>Guidelines:</strong>
                      </Typography>
                      <ul
                        style={{ paddingLeft: "8%", listStyleType: "circle" }}
                      >
                        <li>
                          <Typography
                            variant="body1"
                            paragraph
                            sx={{ marginBottom: "8px" }}
                          >
                            Be honest about existing strengths and weaknesses to
                            ensure a realistic strategy.
                          </Typography>
                        </li>
                        <li>
                          <Typography
                            variant="body1"
                            paragraph
                            sx={{ marginBottom: "8px" }}
                          >
                            The stickers should help you reflect on what’s
                            already working and what might need improvement.
                          </Typography>
                        </li>
                      </ul>
                    </li>
                  </ol>
                </>
              )}

              {/* Hear Instructions Tab Content */}
              {tabIndex === 1 && (
                <ReactAudioPlayer
                  src={`${process.env.REACT_APP_BASE_URL}/videos/Angels_and_Demons_Level2_Video3.mp3`}
                  autoPlay
                  controls
                  style={{
                    width: "100%",
                    marginTop: "16px",
                  }}
                />
              )}
            </Box>
          </div>
        </Box>

        {/* right */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            textAlign: "center",
            width: { xs: "100%", sm: "100%", md: "60%" },
            marginBottom: "60px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: 8,
              padding: "30px 20px",
            }}
          >
            {/* First Div (Image) */}
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: "400px",
                maxHeight: "400px",
              }}
            >
              <img
                src={image1}
                alt="Game Board"
                style={{ width: "100%", height: "auto" }}
              />
              <Box
                sx={{
                  marginTop: "40px",
                  display: "flex",
                  alignItems: "center",
                  width: 220,
                  maxHeight: 100,
                }}
              >
                <Typography
                  sx={{ paddingLeft: 2, fontSize: 16, marginRight: "10px" }}
                >
                  2/3
                </Typography>
                <Box
                  sx={{
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#0F1621",
                  }}
                />
                <Box
                  sx={{
                    marginLeft: "4px",
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#0F1621",
                  }}
                />
                <Box
                  sx={{
                    marginLeft: "4px",
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#D9D9D9",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                />
              </Box>
            </Box>

            {/* Second Div  */}
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Typography
                sx={{
                  color: "#0F1621",
                  fontSize: "16px",
                  fontWeight: 600,
                  margin: "16px 0",
                }}
              >
                Angel{" "}
                <span style={{ color: "#747474", fontSize: "14px" }}>
                  (Strength)
                </span>
              </Typography>
              <div
                style={{ display: "flex", flexWrap: "wrap", gap: "20px 36px" }}
              >
                {Array.from({ length: 10 }, (_, index) => (
                  <img
                    key={index}
                    src={angel}
                    alt={`image-${index}`}
                    style={{ width: "40px", height: "40px" }}
                  />
                ))}
              </div>
              <Typography
                sx={{
                  color: "#0F1621",
                  fontSize: "16px",
                  fontWeight: 600,
                  margin: "16px 0",
                }}
              >
                Demon{" "}
                <span style={{ color: "#747474", fontSize: "14px" }}>
                  (Area that we can improve or is weak)
                </span>
              </Typography>
              <div
                style={{ display: "flex", flexWrap: "wrap", gap: "20px 36px" }}
              >
                {Array.from({ length: 10 }, (_, index) => (
                  <img
                    key={index}
                    src={demon}
                    alt={`image-${index}`}
                    style={{ width: "40px", height: "40px" }}
                  />
                ))}
              </div>
              {/* buttons */}
              {!isLargeScreen && (
                <Box
                  sx={{
                    margin: "40px 0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                    flexDirection: { xs: "column", sm: "row" },
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={handleBack}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid var(--Primary-black, #0F1621)",
                      background: "#FFF",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      color: "#0F1621",
                      width: { xs: "100%", sm: "160px" },
                      height: "40px",
                      ":hover": {
                        background: "#FFF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    sx={{
                      alignSelf: "center",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      borderRadius: "8px",
                      width: { xs: "100%", sm: "160px" },
                      height: "40px",
                    }}
                  >
                    Next
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Page2;
