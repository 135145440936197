import React from "react";
import logo from "../../assets/riddlebox.png";

const Sidebar = ({
  activeComponent,
  setActiveComponent,
  isMobile,
  onClose,
}) => {
  return (
    <div
      className="w-56 sm:w-64 lg:w-72 h-full text-white flex flex-col"
      style={{
        background: "linear-gradient(180deg, #3a569a, #0e2a6e)",
      }}
    >
      {isMobile && (
        <div className="p-2 sm:p-3 text-right">
          <button onClick={onClose} className="focus:outline-none">
            <svg
              className="w-5 h-5 sm:w-6 sm:h-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      )}

      {/* Logo Section */}
      <div className="p-3 sm:p-4 text-center border-b border-gray-500">
        <img src={logo} alt="Logo" className="w-3/4 mx-auto" />
      </div>

      {/* Navigation Options */}
      <nav className="flex-1 p-3 sm:p-4">
        <ul className="space-y-3">
          <li
            className={`p-2 sm:p-3 md:p-4 rounded cursor-pointer text-sm sm:text-base md:text-lg ${
              activeComponent === "profile" ? "bg-white text-black" : ""
            }`}
            onClick={() => {
              setActiveComponent("profile");
              if (isMobile && onClose) onClose();
            }}
          >
            Profile
          </li>
          <li
            className={`p-2 sm:p-3 md:p-4 rounded cursor-pointer text-sm sm:text-base md:text-lg ${
              activeComponent === "employeesList" ? "bg-white text-black" : ""
            }`}
            onClick={() => {
              setActiveComponent("employeesList");
              if (isMobile && onClose) onClose();
            }}
          >
            Employees List
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
