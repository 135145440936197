import React, { useState } from "react";
import {
  Stack,
  Button,
  Typography,
  Box,
  Modal,
  TextField,
  Tabs,
  Tab,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/navbar/Layout";
import ReactAudioPlayer from "react-audio-player";
import image1 from "../../assets/level1 page2.png";
import questionmarks from "../../assets/questionmarks.png";
import { useTheme } from "@emotion/react";

const Page4 = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const employeeDetails = JSON.parse(localStorage.getItem("employee"));

  const handleTabChange = (_, newIndex) => {
    setTabIndex(newIndex);
  };
  const handlePlay = () => {
    navigate("/level2/getting-started");
  };
  const handleBack = () => {
    navigate("/level2/page3");
  };

  return (
    <Layout>
      {/* Welcome Message */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%", position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              padding: "12px 0",
              maxWidth: "700px",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              justifySelf: "center",
            }}
          >
            {employeeDetails?.employeeKey && (
              <div
                style={{
                  padding: "4px 16px",
                  borderRadius: "999px",
                  background: "#FDEED2",
                  border: "2px solid #F7B73B",
                  marginBottom: "8px",
                }}
              >
                <p>Level 2</p>
              </div>
            )}
            <Typography variant="h4" fontWeight="bold">
              Q&A Session
            </Typography>
            <Typography sx={{ margin: "8px 0", fontSize: "16px" }}>
              {employeeDetails?.employeeKey &&
                "Answer a few questions based on your actions in level 1. Take your time to answer them. Instructions for the next level will follow once you complete the Level 1"}
            </Typography>
          </Box>
          {isLargeScreen && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                position: "absolute",
                top: 4,
                right: 0,
              }}
            >
              <Button
                onClick={handlePlay}
                variant="contained"
                sx={{
                  alignSelf: "center",
                  boxShadow: "3px 3px 0px 0px #0F1621",
                  borderRadius: "8px",
                  width: { xs: "100%", sm: "120px" },
                  height: "32px",
                }}
              >
                Play
              </Button>
              <Button
                onClick={handleBack}
                sx={{
                  borderRadius: "8px",
                  border: "1px solid var(--Primary-black, #0F1621)",
                  background: "#FFF",
                  boxShadow: "3px 3px 0px 0px #0F1621",
                  color: "#0F1621",
                  width: { xs: "100%", sm: "120px" },
                  height: "32px",
                  marginTop: "12px",
                  ":hover": {
                    background: "#FFF",
                    boxShadow: "none",
                  },
                }}
              >
                Back
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {/* main */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          marginBottom: "40px",
          gap: 2,
        }}
      >
        {/* left */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            width: { xs: "100%", sm: "100%", md: "40%" },
            marginBottom: { xs: "auto", sm: "auto", md: "60px" },
            borderRadius: "8px",
            height: "100%",
            overflowY: "auto",
          }}
        >
          <div>
            {/* Tabs for Read and Hear Instructions */}
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="Instructions tabs"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#EFE4B0",
                },
              }}
              sx={{
                borderBottom: "2px solid #ccc",
              }}
            >
              <Tab
                label="Read Instructions"
                sx={{
                  padding: "10px 20px",
                  fontWeight: "bold",
                  backgroundColor: tabIndex === 0 ? "#EFE4B0" : "white",
                  color: tabIndex === 0 ? "black" : "black",
                  borderRadius: "5px",
                  "&.Mui-selected": {
                    backgroundColor: "#EFE4B0",
                    color: "black",
                  },
                }}
              />
              <Tab
                label="Listen to Instructions"
                sx={{
                  padding: "10px 20px",
                  fontWeight: "bold",
                  backgroundColor: tabIndex === 1 ? "#EFE4B0" : "white",
                  color: tabIndex === 1 ? "black" : "black",
                  borderRadius: "5px",
                  "&.Mui-selected": {
                    backgroundColor: "#EFE4B0",
                    color: "black",
                  },
                }}
              />
            </Tabs>

            <Box sx={{ margin: "16px auto" }}>
              {/* Read Instructions Tab Content */}
              {tabIndex === 0 && (
                <>
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{ marginBottom: "8px" }}
                  >
                    <strong>Follow-Up Questions:</strong>
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{
                      marginBottom: "8px",
                      marginLeft: "8%",
                      marginTop: "4px",
                    }}
                  >
                    After placing the stickers, you’ll answer a set of questions
                    to reflect on your choices and complete this level
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ marginBottom: "8px", marginTop: "16px" }}
                  >
                    <strong>Note: </strong> While the coin placement is timed,
                    the questions are not timed, so take your time to think and
                    respond thoroughly.
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ marginBottom: "8px" }}
                  >
                    This level includes additional steps, but it’s designed to
                    help you explore your creativity and think critically about
                    your strategy.{" "}
                    {employeeDetails?.employeeKey &&
                      "Let’s get started on Level 2—you’ve got this! 🚀"}
                  </Typography>
                </>
              )}

              {/* Hear Instructions Tab Content */}
              {tabIndex === 1 && (
                <ReactAudioPlayer
                  src={`${process.env.REACT_APP_BASE_URL}/videos/Openendedquestions_level2_Video4.mp3`}
                  autoPlay
                  controls
                  style={{
                    width: "100%",
                    marginTop: "16px",
                  }}
                />
              )}
            </Box>
          </div>
        </Box>

        {/* right */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            textAlign: "center",
            width: { xs: "100%", sm: "100%", md: "60%" },
            marginBottom: "60px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: 8,
              padding: "30px 20px",
            }}
          >
            {/* First Div (Image) */}
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: { xs: "360px", sm: "400px" },
                maxHeight: { xs: "360px", sm: "400px" },
              }}
            >
              <img
                src={image1}
                alt="Game Board"
                style={{ width: "100%", height: "auto" }}
              />
              <Box
                sx={{
                  marginTop: "40px",
                  display: "flex",
                  alignItems: "center",
                  width: 220,
                  maxHeight: 100,
                }}
              >
                <Typography
                  sx={{ paddingLeft: 2, fontSize: 16, marginRight: "10px" }}
                >
                  3/3
                </Typography>
                <Box
                  sx={{
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#0F1621",
                  }}
                />
                <Box
                  sx={{
                    marginLeft: "4px",
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#0F1621",
                  }}
                />
                <Box
                  sx={{
                    marginLeft: "4px",
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#0F1621",
                  }}
                />
              </Box>
            </Box>

            {/* body */}
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                position: "relative",
                padding: { xs: "16px", sm: "24px" }, // Add padding for smaller screens
              }}
            >
              <img
                src={questionmarks}
                alt="Background"
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "50%", // Center the image horizontally
                  transform: "translateX(-50%)", // Adjust to center perfectly
                  height: "auto",
                  zIndex: -1,
                  width: "30%", // Make image smaller on smaller screens
                }}
              />

              {/* Q&A Section */}
              <Box
                sx={{
                  marginBottom: "24px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%", // Ensure full width on all screens
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: "20px",
                    fontSize: { xs: "18px", sm: "20px" },
                  }}
                >
                  Q&A session:
                </Typography>

                {/* Input Fields */}
                <TextField
                  variant="outlined"
                  fullWidth
                  sx={{
                    marginBottom: "16px",
                    maxWidth: "100%", // Ensure full width on smaller screens
                    background: "white",
                    "& .MuiInputBase-input": {
                      padding: "8px",
                    },
                    maxWidth: { xs: "100%", sm: "250px", md: "280px" }, // Responsive width
                  }}
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  sx={{
                    marginBottom: "16px",
                    maxWidth: "100%", // Ensure full width on smaller screens
                    background: "white",
                    "& .MuiInputBase-input": {
                      padding: "8px",
                    },
                    maxWidth: { xs: "100%", sm: "250px", md: "280px" }, // Responsive width
                  }}
                />

                <Typography
                  sx={{
                    marginTop: "16px",
                    fontSize: "14px",
                    color: "#606060",
                    maxWidth: "250px",
                    fontSize: { xs: "12px", sm: "14px" }, // Adjust font size for smaller screens
                  }}
                >
                  {employeeDetails?.employeeKey &&
                    "Relevant questions will be asked about your actions in Level 1."}
                </Typography>
              </Box>

              {/* buttons */}
              {!isLargeScreen && (
                <Box
                  sx={{
                    margin: "40px 0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                    flexDirection: { xs: "column", sm: "row" },
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={handleBack}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid var(--Primary-black, #0F1621)",
                      background: "#FFF",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      color: "#0F1621",
                      width: { xs: "100%", sm: "160px" },
                      height: "40px",
                      ":hover": {
                        background: "#FFF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handlePlay}
                    variant="contained"
                    sx={{
                      alignSelf: "center",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      borderRadius: "8px",
                      width: { xs: "100%", sm: "160px" },
                      height: "40px",
                    }}
                  >
                    Play
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Page4;
