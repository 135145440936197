import React, { useState } from "react";
import Navbar from "./DashboardNavbar";
import Footer from "./DashboardFooter";
import Sidebar from "./Sidebar";
import Profile from "./Profile";
import EmployeesList from "./EmployeesList";

export default function AdminDashboard() {
  const [activeComponent, setActiveComponent] = useState("profile");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const renderComponent = () => {
    switch (activeComponent) {
      case "profile":
        return <Profile />;
      case "employeesList":
        return <EmployeesList />;
      default:
        return <Profile />;
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex h-[100vh]">
      {/* Desktop/Tablet Sidebar */}
      <aside className="hidden md:block">
        <Sidebar
          activeComponent={activeComponent}
          setActiveComponent={setActiveComponent}
          isMobile={false}
        />
      </aside>

      {/* Mobile Sidebar */}
      {isSidebarOpen && (
        <div className="fixed inset-0 z-40 md:hidden" onClick={toggleSidebar}>
          {/* Overlay */}
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div
            className="absolute inset-y-0 left-0 max-w-[280px] w-56 sm:w-64 z-50"
            onClick={(e) => e.stopPropagation()}
          >
            <Sidebar
              activeComponent={activeComponent}
              setActiveComponent={setActiveComponent}
              isMobile={true}
              onClose={toggleSidebar}
            />
          </div>
        </div>
      )}

      {/* Main Content Area */}
      <div className="flex flex-col flex-1">
        <header className="bg-white shadow-md">
          <Navbar toggleSidebar={toggleSidebar} />
        </header>

        {/* Dynamic Body Content */}
        <main className="flex-1 py-4 sm:py-6 px-2 sm:px-6 lg:px-8 bg-gray-100 overflow-auto">
          {renderComponent()}
        </main>

        {/* Footer with mt-auto to stick at bottom */}
        <footer className="bg-white shadow-md p-3 sm:p-4 md:p-5 mt-auto">
          <Footer />
        </footer>
      </div>
    </div>
  );
}
