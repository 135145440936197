import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  TextareaAutosize,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/navbar/Layout";
import worriedPana from "../../assets/Worried-pana 2.png";
import { useDispatch, useSelector } from "react-redux";
import { setLevel1Data } from "../../redux/slices/level1DataSlice";
import axios from "axios";
import { useTheme } from "@emotion/react";

const placeholders = [
  "customer_1_coins",
  "customer_2_coins",
  "customer_3_coins",
  "customer_4_coins",
  "customer_employee_coins",
  "customer_employee_strategy_coins",
  "customer_strategy_coins",
  "employee_1_coins",
  "employee_2_coins",
  "employee_3_coins",
  "employee_4_coins",
  "employee_strategy_coins",
  "strategy_1_coins",
  "strategy_2_coins",
  "strategy_3_coins",
  "strategy_4_coins",
];

const Page5 = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employeeDetails = JSON.parse(localStorage.getItem("employee"));
  const existingLevel1Data = useSelector(
    (state) => state.level1Data?.level1Data
  );
  const [comments_4, setComments_4] = useState(existingLevel1Data?.comments_4);
  const [loading, setLoading] = useState(false);

  const handleNext = async () => {
    const updatedLevel1Data = {
      ...existingLevel1Data,
      comments_4: comments_4,
      company_id: employeeDetails?.company_id,
      employeeKey: employeeDetails?.employeeKey,
    };
    dispatch(setLevel1Data(updatedLevel1Data));

    // add remaining placeholders
    let dataToPost = {};

    placeholders?.forEach((placeholder) => {
      dataToPost[placeholder] = updatedLevel1Data[placeholder] || null; // each placeholder is required
    });
    dataToPost.comments_1 = updatedLevel1Data["comments_1"];
    dataToPost.comments_4 = comments_4;
    dataToPost.company_id = employeeDetails?.company_id;
    dataToPost.employeeKey = employeeDetails?.employeeKey;
    // convert values from array of strings to comma separated values
    Object.keys(dataToPost)?.forEach((key) => {
      if (Array.isArray(dataToPost[key])) {
        dataToPost[key] = dataToPost[key].join(",");
      }
    });
    const urlEncodedData = new URLSearchParams(dataToPost).toString();

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/addLevel1Data`,
        urlEncodedData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      // Navigate to the next page
      const key = employeeDetails?.employeeKey; // get key from the local storage
      navigate(`/level2/welcome/${key}`);
    } catch (error) {
      console.error("API Call Error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/level1/page2");
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
          marginBottom: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            marginBottom: "40px",
          }}
        >
          {/* Welcome Message */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "24px 0",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
                gap: { xs: "16px", sm: "0" },
                position: "relative",
              }}
            >
              <Stack
                direction="row"
                sx={{
                  background: "#FDEED2",
                  border: "2px solid #F7B73B",
                  borderRadius: "999px",
                  alignItems: "center",
                  padding: "4px 16px",
                  fontSize: "16px",
                }}
              >
                Level1 - Q&A session
              </Stack>
              {/* Buttons */}
              {isLargeScreen && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    sx={{
                      alignSelf: "center",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      borderRadius: "8px",
                      width: { xs: "100%", sm: "160px" },
                      height: "40px",
                    }}
                  >
                    Next
                  </Button>
                  <Button
                    onClick={handleBack}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid var(--Primary-black, #0F1621)",
                      background: "#FFF",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      color: "#0F1621",
                      width: { xs: "100%", sm: "160px" },
                      height: "40px",
                      marginTop: "12px",
                      ":hover": {
                        background: "#FFF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Back
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          {/* main */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
              marginTop: "40px",
            }}
          >
            {/* body */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                justifyContent: "center",
                alignItems: { xs: "center", sm: "center", md: "start" },
                gap: 8,
                width: "100%",
                padding: "20px 0",
              }}
            >
              {/* left text and textarea */}
              <Stack
                sx={{
                  width: { xs: "100%", sm: "80%", md: "70%" },
                  paddingX: { xs: 0, sm: 2, md: 4 },
                  maxWidth: { xs: "350px", sm: "400px", md: "450px" },
                  gap: 2,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      width: "24px",
                      height: "24px",
                      flexShrink: 0,
                      background: "#ECECEC",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  >
                    1
                  </div>
                  <Stack sx={{ gap: 2, textAlign: "start" }}>
                    <Typography
                      sx={{
                        color: "#747474",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      Challenges
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2D2C2C",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 700,
                      }}
                    >
                      What are the challenges you think the current approach has
                      ?
                    </Typography>
                  </Stack>
                </div>

                <TextareaAutosize
                  placeholder="Enter your comments here"
                  value={comments_4}
                  onChange={(e) => setComments_4(e.target.value)}
                  minRows={12}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "16px",
                    padding: "8px",
                    boxSizing: "border-box",
                    maxWidth: { xs: "300px", sm: "350px", md: "auto" },
                  }}
                />
              </Stack>
              {/* worried panda image */}
              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  maxWidth: { xs: "360px", sm: "400px", md: "450px" },
                  maxHeight: { xs: "360px", sm: "400px", md: "450px" },
                  paddingX: { xs: "10px", sm: "16px" },
                  paddingY: { xs: "16px", sm: "28px" },
                  background: "#F9F9F9",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "100%",
                  }}
                >
                  <img
                    src={worriedPana}
                    alt="worried pana"
                    style={{
                      width: "100%",
                      maxWidth: "500px",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </Box>
            </Box>
            {/* ḷevels & buttons */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: { sm: "center", md: "space-between" },
                alignItems: { sm: "center", md: "flex-start" },
                gap: 8,
                width: { sm: "100%", md: "80%" },
                padding: "20px 0",
              }}
            >
              {/* levels */}
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                  width: 220,
                  maxHeight: 100,
                }}
              >
                <Typography
                  sx={{ paddingLeft: 2, fontSize: 16, marginRight: "10px" }}
                >
                  2/2
                </Typography>
                <Box
                  sx={{
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#0F1621",
                  }}
                />
                <Box
                  sx={{
                    marginLeft: "4px",
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#0F1621",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                />
              </Box>
              {/* buttons */}
              {!isLargeScreen && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                    flexDirection: { xs: "column", sm: "row" },
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={handleBack}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid var(--Primary-black, #0F1621)",
                      background: "#FFF",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      color: "#0F1621",
                      width: { xs: "100%", sm: "160px" },
                      height: "40px",
                      marginBottom: { xs: "16px", sm: "0" },
                      ":hover": {
                        background: "#FFF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    sx={{
                      alignSelf: "center",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      borderRadius: "8px",
                      width: { xs: "100%", sm: "160px" },
                      height: "40px",
                    }}
                  >
                    Next
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Page5;
