import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  Link,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import LogoHeader from "../../components/LogoHeader";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import loginBg from "../../assets/login_bg.png";

const Login = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    setFormError("");
    if (!username.trim() || !password.trim()) {
      setFormError("Please enter username and password.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/checkLogin`,
        new URLSearchParams({
          username,
          password,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.data.success) {
        const companyData = response.data.company;
        localStorage.clear();
        localStorage.setItem("companyData", JSON.stringify(companyData));
        navigate("/admin-panel");
      } else {
        setFormError("Login failed. Please try again.");
      }
    } catch (error) {
      setFormError("An error occurred during login. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCreateAccount = () => {
    navigate("/register");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <LogoHeader />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          flexGrow: 1,
        }}
      >
        {/* Blue Container (Login Form) */}
        <Box
          sx={{
            background: "linear-gradient(180deg, #3a569a, #0e2a6e)",
            color: "white",
            width: { xs: "100%", sm: "auto", md: "40%" },
            minWidth: { xs: "100%", sm: "70%", md: "auto" },
            minHeight: { xs: "100%", sm: "80%", md: "auto" },
            padding: { xs: 3, sm: 8, md: "50px 30px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "100vh", sm: "100%", md: "auto" },
            m: { xs: "auto", sm: "auto", md: 0 },
            borderRadius: { xs: 0, sm: "10px", md: 0 },
          }}
        >
          <Typography variant="h5" fontWeight="bold" mb={4}>
            StrategIQ
          </Typography>
          <Box
            component="form"
            sx={{ width: "100%", maxWidth: "300px" }}
            onSubmit={handleLogin}
          >
            <Box sx={{ mb: 2 }}>
              <TextField
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                InputProps={{
                  style: { backgroundColor: "white" },
                }}
              />
            </Box>
            <Box sx={{ position: "relative", mb: 2 }}>
              <TextField
                type={showPassword ? "text" : "password"}
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                InputProps={{
                  style: { backgroundColor: "white" },
                }}
              />
              <Box
                onClick={togglePasswordVisibility}
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  color: "#6f6f6f",
                }}
              >
                {showPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
              </Box>
            </Box>
            {formError && (
              <Typography variant="body2" sx={{ color: "red", mb: 1 }}>
                {formError}
              </Typography>
            )}
            <Link
              href="/forgot-password"
              sx={{
                display: "block",
                color: "white",
                textAlign: "right",
                mb: 2,
              }}
            >
              Forgot password?
            </Link>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{
                mb: 2,
                backgroundColor: "#ff9800",
                "&:hover": { backgroundColor: "#e68900" },
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Login"
              )}
            </Button>
            <Typography textAlign="center" color="white" mb={2}>
              - or -
            </Typography>
            <Button
              fullWidth
              variant="outlined"
              onClick={handleCreateAccount}
              sx={{
                color: "white",
                borderColor: "white",
                background: "transparent",
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              Create a StrategIQ Account
            </Button>
          </Box>
        </Box>

        {/* Right Section: Image with Overlay and Text for large screens */}
        {isLargeScreen && (
          <Box
            sx={{
              width: "60%",
              height: "100vh",
              background: `url(${loginBg}) center center / cover no-repeat`,
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                p: 2,
              }}
            >
              {/* <Typography variant="h4" color="white" mb={2} align="center">
                Welcome back!
              </Typography>
              <Typography variant="body1" color="white" mb={4} align="center">
                Login and start engaging your community!
              </Typography> */}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Login;
