import React, { createContext, useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";

// Context for the sticker counts
const ManagementStickerContext = createContext();

// Provider component that holds the global state for stickers
const ManagementStickerProvider = ({ children }) => {
  const availableAngelCount = useSelector(
    (state) => state?.managementData?.availableAngelSticker
  );
  const availableDemonCount = useSelector(
    (state) => state?.managementData?.availableDemonSticker
  );

  const [availableAngels, setAvailableAngels] = useState(
    availableAngelCount ?? 10
  );
  const [availableDemons, setAvailableDemons] = useState(
    availableDemonCount ?? 10
  );

  // Sync local state when Redux state updates, ensuring undefined values don't override the default
  useEffect(() => {
    if (availableAngelCount !== undefined) {
      setAvailableAngels(availableAngelCount);
    }
  }, [availableAngelCount]);

  useEffect(() => {
    if (availableDemonCount !== undefined) {
      setAvailableDemons(availableDemonCount);
    }
  }, [availableDemonCount]);

  return (
    <ManagementStickerContext.Provider
      value={{
        availableAngels,
        setAvailableAngels,
        availableDemons,
        setAvailableDemons,
      }}
    >
      {children}
    </ManagementStickerContext.Provider>
  );
};

export default ManagementStickerProvider;
// Custom hook for easy access to the sticker context
export const useManagementStickerContext = () =>
  useContext(ManagementStickerContext);
