import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
  TextField,
  Stack, // for date inputs
} from "@mui/material";
import axios from "axios";
import { CloudUpload, Download } from "@mui/icons-material";
import CopyToClipboard from "react-copy-to-clipboard";

const EmployeesList = () => {
  const companyDetails = JSON.parse(localStorage.getItem("companyData"));
  const [csvFile, setCsvFile] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [reportLoading, setReportLoading] = useState({});
  const [showDateFields, setShowDateFields] = useState(false);

  const today = new Date().toISOString().split("T")[0];
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [dateSubmitLoading, setDateSubmitLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [copiedLinkWithKey, setCopiedLinkWithKey] = useState(null);

  useEffect(() => {
    getEmployeesList();
  }, []);

  // Handle File Selection
  const handleFileChange = (event) => {
    setCsvFile(event.target.files[0]);
  };

  // Upload CSV & Fetch Employees
  const handleUpload = async () => {
    if (!csvFile) {
      setErrorMessage("Please select a CSV file before uploading.");
      return;
    }

    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("listfile", csvFile);
      formData.append("company_id", companyDetails?.id);

      // Upload CSV API call
      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/loadEmployeesList`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (uploadResponse.status !== 200) {
        throw new Error("Failed to upload CSV");
      }
      setShowDateFields(true);
      setSuccessMessage("Employee list uploaded successfully!");
      await getEmployeesList();
    } catch (error) {
      console.error("Error uploading CSV:", error);
      setErrorMessage("An error occurred while uploading.");
    } finally {
      setIsLoading(false);
      setCsvFile(null);
    }
  };

  // Fetch Employees List
  const getEmployeesList = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getEmployeesList/${companyDetails?.id}`
      );
      setEmployees(data);
    } catch (error) {
      console.error("Error fetching employee list:", error);
      setErrorMessage("Failed to fetch employee list.");
    } finally {
      setIsLoading(false);
    }
  };

  // const handleTakeSurvey = (employee) => {
  //   if (employee?.level2_id) {
  //     return;
  //   } else if (employee?.level1_id) {
  //     window.open(
  //       `https://strategiq.kayawellbeingindex.com/level2/welcome/${employee?.employeeKey}`,
  //       "_blank"
  //     );
  //   } else {
  //     window.open(
  //       `https://strategiq.kayawellbeingindex.com/doSurvey/${employee?.employeeKey}`,
  //       "_blank"
  //     );
  //   }
  // };

  const copiedLinkText = (employee) => {
    if (!employee) return "";
    if (employee.level2_id) {
      return `https://strategiq.kayawellbeingindex.com/level2/welcome/${employee.employeeKey}`;
    }
    if (employee.level1_id) {
      return `https://strategiq.kayawellbeingindex.com/level2/welcome/${employee.employeeKey}`;
    }
    return `https://strategiq.kayawellbeingindex.com/doSurvey/${employee.employeeKey}`;
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  const handleGenerateReport = async (employee) => {
    if (employee?.level2_id) {
      setReportLoading((prev) => ({ ...prev, [employee.employeeKey]: true }));
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/individualReport/${employee.employeeKey}`
        );
        setTimeout(() => {
          window.open(
            `https://strategiqapi.kayawellbeingindex.com/api/showPdf/${response.data}`,
            "_blank"
          );
          setReportLoading((prev) => ({
            ...prev,
            [employee.employeeKey]: false,
          }));
        }, 7000);
      } catch (error) {
        console.error("Error generating report:", error);
        setErrorMessage("An error occurred while generating the report.");
        setReportLoading((prev) => ({
          ...prev,
          [employee.employeeKey]: false,
        }));
      }
    } else {
      return;
    }
  };

  // update the company details
  const fetchCompanyDetails = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/company/${companyDetails?.id}`
      );
      localStorage.setItem("companyData", JSON.stringify(res.data));
      // window.location.reload();
    } catch (error) {
      console.error("Error fetching company details:", error);
    }
  };

  // New function to handle the submission of dates using a dummy API and encoded data
  const handleDateSubmit = async () => {
    if (!startDate || !endDate) {
      setErrorMessage("Please enter both start and end dates.");
      return;
    }
    // Check that the end date is not before the start date
    if (new Date(endDate) < new Date(startDate)) {
      setErrorMessage("End date cannot be before start date.");
      return;
    }
    setDateSubmitLoading(true);
    try {
      const encodedData = new URLSearchParams();
      encodedData.append("survey_start_date", startDate);
      encodedData.append("survey_end_date", endDate);
      encodedData.append("company_id", companyDetails?.id);
      encodedData.append("admin_name", companyDetails?.admin_name);
      encodedData.append("admin_phone", companyDetails?.admin_phone);
      encodedData.append("company_name", companyDetails?.company_name);

      // Dummy API call for date submission
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/updateCompany`,
        encodedData,
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );
      fetchCompanyDetails();
      setTimeout(() => {
        setDateSubmitLoading(false);
        setSuccessMessage("Dates submitted successfully!");
      }, 1000);
    } catch (error) {
      setDateSubmitLoading(false);
      console.error("Error submitting dates:", error);
      setErrorMessage("Failed to submit dates.");
    }
  };

  // New function to send survey reminder email to all employees
  const handleSendReminderAll = async () => {
    if (allLevel2Done) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/sendSurveyLinkEmail/${companyDetails?.id}`
      );
      response.data?.sucess &&
        setSuccessMessage("Email to employees with survey link is sent!");
    } catch (error) {
      console.error("Error sending survey reminder emails to all:", error);
      setErrorMessage("Failed to send survey reminder emails.");
    }
  };

  const handleDeleteSurvey = async (employee) => {
    if (employee?.level1_id) {
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/deleteEmployee`,
        new URLSearchParams({ employeeKey: employee?.employeeKey })
      );
      // check if response is successfull update the employee list
      if (response.data) {
        setEmployees((prevEmployees) =>
          prevEmployees?.filter(
            (emp) => emp?.employeeKey != employee?.employeeKey
          )
        );
      }
    } catch (error) {
      console.log("error deleting employee", error);
      setErrorMessage(
        "An unexpected error occurred while deleting the employee."
      );
    }
  };

  // Function to generate and download the sample CSV
  const handleDownloadSampleCSV = () => {
    const csvRows = [
      ["Employee Name", "Email"],
      ["John Doe", "john.doe@example.com"],
      ["Jane Smith", "jane.smith@example.com"],
    ];

    // Convert the rows to CSV format
    const csvContent = csvRows?.map((row) => row.join(",")).join("\n");

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "sample_employee_list.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Compute if every employee has completed level 2 (i.e. level2_id exists)
  const allLevel2Done =
    employees.length > 0 && employees?.every((emp) => emp.level2_id != null);

  // Check if at least one employee has started (i.e., level1_id exists)
  const hasLevel1Responses =
    employees || []?.some((emp) => emp.level1_id != null);

  // Extract survey dates
  const surveyStartDate = new Date(
    companyDetails?.survey_start_date || "9999-12-31"
  )
    .toISOString()
    .split("T")[0];
  const surveyEndDate = new Date(
    companyDetails?.survey_end_date || "1970-01-01"
  )
    .toISOString()
    .split("T")[0];

  // Determine if button should be disabled
  const disableReminder = () => {
    if (surveyStartDate <= today && surveyEndDate >= today) return true;
    if (allLevel2Done) return true;
    return false;
  };

  // Determine button text
  const buttonText =
    surveyStartDate === today
      ? "Send Game Link"
      : hasLevel1Responses || surveyStartDate < today
      ? "Send Game Reminder"
      : "Send Game Link";

  return (
    <Box sx={{ width: "100%", p: { xs: 1, sm: 2, md: 3, lg: 4 } }}>
      <Typography
        variant="h5"
        mb={2}
        px={1}
        sx={{
          fontSize: { xs: "1.2rem", sm: "1.3rem", md: "1.4rem", lg: "1.4rem" },
        }}
      >
        Employee List
      </Typography>
      {/* File Upload Section */}
      <Stack
        spacing={2}
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
        >
          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUpload />}
            sx={{ fontSize: { xs: "0.8rem", sm: "0.85rem", md: "0.9rem" } }}
          >
            Load Employee List
            <input
              type="file"
              hidden
              accept=".csv"
              onChange={handleFileChange}
            />
          </Button>

          {csvFile && (
            <Button
              variant="contained"
              onClick={handleUpload}
              disabled={isLoading}
              sx={{ fontSize: { xs: "0.8rem", sm: "0.85rem", md: "0.9rem" } }}
            >
              {isLoading ? "Uploading..." : "Submit"}
            </Button>
          )}

          {csvFile && (
            <Typography
              variant="body2"
              sx={{ fontSize: { xs: "0.7rem", sm: "0.8rem" } }}
            >
              {csvFile.name}
            </Typography>
          )}
        </Stack>

        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadSampleCSV}
          startIcon={<Download />}
          sx={{ fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.9rem" } }}
        >
          Sample Employee List
        </Button>
      </Stack>
      {/* Conditionally render the start and end date fields once the employee list is loaded */}
      <Stack
        sx={{
          display: "flex",
          justifyContent: showDateFields ? "space-between" : "flex-end",
          alignItems: "flex-start",
          flexDirection: { sx: "column", sm: "column", md: "row" },
          width: "100%",
        }}
      >
        {showDateFields && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              flex: 1,
            }}
          >
            {" "}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                px: 1,
                gap: { xs: 1, sm: 2 },
                mt: 2,
              }}
            >
              <Typography variant="body1">Enter Survey Dates:</Typography>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                size="small"
                sx={{ width: { xs: "100%", sm: "auto" } }}
              />
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                size="small"
                sx={{ width: { xs: "100%", sm: "auto" } }}
              />
              <Button
                variant="contained"
                onClick={handleDateSubmit}
                disabled={dateSubmitLoading}
                sx={{
                  fontSize: {
                    xs: "0.7rem",
                    sm: "0.8rem",
                    md: "0.9rem",
                    lg: "0.9rem",
                  },
                  padding: {
                    xs: "4px 8px",
                    sm: "5px 10px",
                    md: "6px 12px",
                    lg: "6px 12px",
                  },
                }}
              >
                {dateSubmitLoading ? "Submitting..." : "Submit Dates"}
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                px: 1,
                gap: { xs: 1, sm: 2 },
                mt: 1,
              }}
            >
              <Typography variant="caption">
                Email with the survey link will be sent to each employee on the
                start date of the survey.
              </Typography>
            </Box>
          </Box>
        )}
        {/* Top-level button to send survey reminder email to all employees */}
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              px: 1,
              mt: 2,
              width: { xs: "100%", md: "auto" },
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendReminderAll}
              sx={{
                fontSize: {
                  xs: "0.7rem",
                  sm: "0.8rem",
                  md: "0.9rem",
                  lg: "0.9rem",
                },
                padding: {
                  xs: "4px 8px",
                  sm: "5px 10px",
                  md: "6px 12px",
                  lg: "6px 12px",
                },
              }}
              disabled={!disableReminder()}
            >
              {buttonText}
            </Button>
          </Box>
        </div>
      </Stack>
      {/* employee list table */}
      <TableContainer component={Paper} sx={{ mt: 4, overflowX: "auto" }}>
        <Table
          sx={{ minWidth: { xs: 300, sm: 650 } }}
          aria-label="employee table"
        >
          <TableHead>
            <TableRow>
              {["Name", "Email", "Copy Link", "Generate Report", "Action"].map(
                (header, index) => (
                  <TableCell
                    align="center"
                    key={index}
                    sx={{
                      fontSize: {
                        xs: "0.8rem",
                        sm: "0.9rem",
                        md: "1rem",
                        lg: "1rem",
                      },
                      textAlign: "center",
                    }}
                  >
                    <b>{header}</b>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {employees?.length > 0 ? (
              employees.map((employee) => (
                <TableRow key={employee?.employeeKey}>
                  <TableCell
                    align="center"
                    sx={{
                      textAlign: "center",
                      fontSize: {
                        xs: "0.75rem",
                        sm: "0.85rem",
                        md: "0.95rem",
                        lg: "0.95rem",
                      },
                    }}
                  >
                    {employee?.associate_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      textAlign: "center",
                      fontSize: {
                        xs: "0.75rem",
                        sm: "0.85rem",
                        md: "0.95rem",
                        lg: "0.95rem",
                      },
                    }}
                  >
                    {employee?.associate_email}
                  </TableCell>
                  <TableCell align="center" sx={{ textAlign: "center" }}>
                    <CopyToClipboard
                      text={copiedLinkText(employee)}
                      onCopy={handleCopy}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          fontSize: {
                            xs: "0.7rem",
                            sm: "0.8rem",
                            md: "0.9rem",
                            lg: "0.9rem",
                          },
                          padding: {
                            xs: "4px 8px",
                            sm: "5px 10px",
                            md: "6px 12px",
                            lg: "6px 12px",
                          },
                        }}
                        onClick={() => {
                          setCopiedLinkWithKey(employee?.employeeKey);
                          setTimeout(() => {
                            setCopiedLinkWithKey("");
                          }, 3000);
                        }}
                        disabled={
                          !!employee?.level2_id ||
                          employee?.employeeKey === copiedLinkWithKey
                        }
                      >
                        {employee?.employeeKey === copiedLinkWithKey
                          ? "Link Copied"
                          : "Copy Link"}
                      </Button>
                    </CopyToClipboard>
                  </TableCell>
                  <TableCell align="center" sx={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleGenerateReport(employee)}
                      disabled={!!!employee?.level2_id}
                      sx={{
                        fontSize: {
                          xs: "0.7rem",
                          sm: "0.8rem",
                          md: "0.9rem",
                          lg: "0.9rem",
                        },
                        padding: {
                          xs: "4px 8px",
                          sm: "5px 10px",
                          md: "6px 12px",
                          lg: "6px 12px",
                        },
                      }}
                    >
                      {reportLoading[employee.employeeKey]
                        ? "Working..."
                        : "Generate Report"}
                    </Button>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleDeleteSurvey(employee)}
                      disabled={!!employee?.level1_id}
                      sx={{
                        backgroundColor: "error.main",
                        color: "white",
                        boxShadow: "none",
                        "&:hover": { backgroundColor: "error.dark" },
                        "&:disabled": {
                          backgroundColor: "grey.300",
                          color: "grey.500",
                        },
                      }}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "No Employees Found"
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* snackbars */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage("")}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessMessage("")}
          severity="success"
          variant="filled"
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={3000}
        onClose={() => setErrorMessage("")}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrorMessage("")}
          severity="error"
          variant="filled"
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EmployeesList;
