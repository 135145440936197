import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  TextareaAutosize,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../components/navbar/Layout";
import image1 from "../../assets/game board.png";
import { useDispatch, useSelector } from "react-redux";
import { setLevel1Data } from "../../redux/slices/level1DataSlice";
import { useTheme } from "@emotion/react";

// Placeholder for displaying coins on specific positions
const CoinDropPlaceholder = ({ x, y, coins }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: y,
        left: x,
        width: { xs: "50px", sm: "50px", md: "60px" },
        height: { xs: "50px", sm: "50px", md: "60px" },
        borderRadius: "50%",
        backgroundColor: "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {coins && (
        <Tooltip
          title={coins?.join(", ") || null}
          arrow
          sx={{
            "& .MuiTooltip-tooltip": {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              color: "white",
              fontSize: "12px",
              maxWidth: "200px",
              padding: "8px",
              lineHeight: "1.4",
            },
          }}
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -8],
                },
              },
            ],
          }}
        >
          <Typography
            sx={{
              maxWidth: "100%",
              minWidth: "30px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: { xs: "8px", sm: "10px", md: "10px" },
            }}
          >
            {coins?.length > 1 ? coins?.length : coins[coins?.length - 1]}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

// Mapped Image Component that holds the coins
const DroppableImage = ({ imageSrc, placeholders, placedCoins }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {/* Image */}
      <img
        src={imageSrc}
        alt="Game Board"
        style={{
          width: "100%",
          height: "auto",
          pointerEvents: "none",
          borderRadius: "12px",
          boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
        }}
      />

      {/* Circular Placeholders */}
      {placeholders?.map((pos, index) => (
        <CoinDropPlaceholder
          key={pos.id}
          x={pos.x}
          y={pos.y}
          coins={placedCoins[pos.id] || []}
        />
      ))}
    </Box>
  );
};

const Page2 = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const existingLevel1Data = useSelector(
    (state) => state.level1Data?.level1Data
  );
  const placedCoins = location.state?.placedCoins || existingLevel1Data;
  const [comments_1, setComments_1] = useState(existingLevel1Data?.comments_1);

  const placeholders = [
    { id: "customer_1_coins", x: "27%", y: "8%" },
    { id: "customer_2_coins", x: "13%", y: "17%" },
    { id: "customer_3_coins", x: "12%", y: "35%" },
    { id: "customer_4_coins", x: "24%", y: "27%" },
    { id: "customer_employee_coins", x: "43%", y: "19%" },
    { id: "customer_employee_strategy_coins", x: "43%", y: "37%" },
    { id: "customer_strategy_coins", x: "28%", y: "44%" },
    { id: "employee_1_coins", x: "59%", y: "9%" },
    { id: "employee_2_coins", x: "73%", y: "17%" },
    { id: "employee_3_coins", x: "62%", y: "27%" },
    { id: "employee_4_coins", x: "74%", y: "35%" },
    { id: "employee_strategy_coins", x: "57%", y: "44%" },
    { id: "strategy_1_coins", x: "43%", y: "59%" },
    { id: "strategy_2_coins", x: "27%", y: "63%" },
    { id: "strategy_3_coins", x: "59%", y: "62%" },
    { id: "strategy_4_coins", x: "43%", y: "74%" },
  ];

  const handleNext = () => {
    const updatedLevel1Data = { ...existingLevel1Data, comments_1: comments_1 };
    // Dispatch the action to update the level1Data in Redux store
    dispatch(setLevel1Data(updatedLevel1Data));
    navigate("/level1/page5");
  };

  const handleBack = () => {
    navigate("/level1/page1");
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
          marginBottom: { sm: "28px", md: "40px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            marginBottom: { sm: "28px", md: "40px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            {/* Welcome Message */}
            <Box
              sx={{
                display: "flex",
                padding: "24px 0",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
                gap: { xs: "16px", sm: "0" },
                position: "relative",
              }}
            >
              <Stack
                direction="row"
                sx={{
                  background: "#FDEED2",
                  border: "2px solid #F7B73B",
                  borderRadius: "999px",
                  alignItems: "center",
                  padding: "4px 16px",
                  fontSize: "16px",
                }}
              >
                Level1 - Q&A session
              </Stack>
              {/* Buttons */}
              {isLargeScreen && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    sx={{
                      alignSelf: "center",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      borderRadius: "8px",
                      width: { xs: "100%", sm: "160px" },
                      height: "40px",
                    }}
                  >
                    Next
                  </Button>
                  <Button
                    onClick={handleBack}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid var(--Primary-black, #0F1621)",
                      background: "#FFF",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      color: "#0F1621",
                      width: { xs: "100%", sm: "160px" },
                      height: "40px",
                      marginTop: "12px",
                      ":hover": {
                        background: "#FFF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Back
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
              marginTop: { xs: "28px", md: "40px" },
              marginBottom: { xs: "60px", sm: "60px", md: "10px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "column", md: "row" },
                justifyContent: "center",
                alignItems: { xs: "center", sm: "center", md: "start" },
                gap: 8,
                width: "100%",
                padding: "20px 0",
              }}
            >
              {/* left text and textarea */}
              <Stack
                sx={{
                  width: { xs: "100%", sm: "80%", md: "70%" },
                  paddingX: { xs: 0, sm: 2, md: 4 },
                  maxWidth: "500px",
                  gap: 2,
                }}
              >
                {/* text */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      width: "24px",
                      height: "24px",
                      flexShrink: 0,
                      background: "#ECECEC",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  >
                    1
                  </div>
                  <Stack sx={{ gap: 2, textAlign: "start" }}>
                    <Typography
                      sx={{
                        color: "#747474",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      About the Venn diagram
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2D2C2C",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 700,
                      }}
                    >
                      Can you explain the challenges of the current approach as
                      you see it
                    </Typography>
                  </Stack>
                </div>
                <TextareaAutosize
                  placeholder="There's too much focus on customers and not enough on employees, with the strategy lacking clear direction. The overlap between employees and strategy misses key areas like training, which is currently overlooked. Additionally, there's no recognition or reward for the efforts employees put in, which is also missing."
                  value={comments_1}
                  onChange={(e) => setComments_1(e.target.value)}
                  minRows={12}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "12px",
                    padding: "8px",
                    boxSizing: "border-box",
                    marginLeft: { xs: 0, sm: 0, md: "34px" },
                    maxWidth: { xs: "300px", sm: "350px", md: "500px" },
                    p: 2,
                  }}
                />
              </Stack>
              {/* image */}
              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "500px",
                  maxHeight: "500px",
                }}
              >
                <Box
                  sx={{
                    flex: { xs: "1 1 auto", md: "1 1 50%" },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    maxWidth: "100%",
                  }}
                >
                  <DroppableImage
                    imageSrc={image1}
                    placeholders={placeholders}
                    placedCoins={placedCoins}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: { sm: "center", md: "space-between" },
                alignItems: { sm: "center", md: "flex-start" },
                gap: 8,
                width: { sm: "100%", md: "80%" },
                padding: "20px 0",
              }}
            >
              {/* levels */}
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                  width: 220,
                  maxHeight: 100,
                }}
              >
                <Typography
                  sx={{ paddingLeft: 2, fontSize: 16, marginRight: "10px" }}
                >
                  1/2
                </Typography>
                <Box
                  sx={{
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#0F1621",
                  }}
                />
                <Box
                  sx={{
                    marginLeft: "4px",
                    height: "10px",
                    borderRadius: "1rem",
                    width: 100,
                    backgroundColor: "#D9D9D9",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                />
              </Box>
              {/* buttons */}
              {!isLargeScreen && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                    flexDirection: { xs: "column", sm: "row" },
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={handleBack}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid var(--Primary-black, #0F1621)",
                      background: "#FFF",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      color: "#0F1621",
                      width: { xs: "100%", sm: "160px" },
                      height: "40px",
                      marginBottom: { xs: "16px", sm: "0" },
                      ":hover": {
                        background: "#FFF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    sx={{
                      alignSelf: "center",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      borderRadius: "8px",
                      width: { xs: "100%", sm: "160px" },
                      height: "40px",
                    }}
                  >
                    Next
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Page2;
