import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/navbar/Layout";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import image1 from "../../assets/game board.png";
import angelImg from "../../assets/angel.png";
import demonImg from "../../assets/demon.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setLevel3CoinsDetails,
  setLevel3CoinsWithSticker,
} from "../../redux/slices/level3DataSlice";
import { useStickerContext } from "../../context/level2/stickerContext";
import { useTheme } from "@emotion/react";

const placeholders = [
  { id: "customer_1_coins", x: "27%", y: "8%" },
  { id: "customer_2_coins", x: "13%", y: "17%" },
  { id: "customer_3_coins", x: "12%", y: "35%" },
  { id: "customer_4_coins", x: "24%", y: "27%" },
  { id: "customer_employee_coins", x: "43%", y: "19%" },
  { id: "customer_employee_strategy_coins", x: "43%", y: "37%" },
  { id: "customer_strategy_coins", x: "28%", y: "44%" },
  { id: "employee_1_coins", x: "59%", y: "9%" },
  { id: "employee_2_coins", x: "73%", y: "17%" },
  { id: "employee_3_coins", x: "62%", y: "27%" },
  { id: "employee_4_coins", x: "74%", y: "35%" },
  { id: "employee_strategy_coins", x: "57%", y: "44%" },
  { id: "strategy_1_coins", x: "43%", y: "59%" },
  { id: "strategy_2_coins", x: "27%", y: "63%" },
  { id: "strategy_3_coins", x: "59%", y: "62%" },
  { id: "strategy_4_coins", x: "43%", y: "74%" },
];

// ----------------------------------------------------------------
// Draggable sticker that is already placed on a coin
// ----------------------------------------------------------------
const DraggablePlacedSticker = ({ stickerType, coinIndex, placeholderId }) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "PLACED_STICKER",
      // Include an explicit itemType property so we can distinguish it later
      item: {
        stickerType,
        coinIndex,
        placeholderId,
        itemType: "PLACED_STICKER",
      },
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    }),
    [stickerType, coinIndex, placeholderId]
  );

  const stickerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "1.5vw", // 5% of the viewport width
    height: "1.5vw", // Maintain square aspect ratio
    maxWidth: "40px",
    maxHeight: "40px",
    minWidth: "16px",
    minHeight: "16px",
    backgroundColor: stickerType === "angel" ? "#FDEED2" : "#DDEEDF",
    opacity: isDragging ? 0.5 : 1,
    cursor: "move",
    touchAction: "none", // Prevents unwanted gestures on mobile
    backgroundImage: `url(${stickerType === "angel" ? angelImg : demonImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return <Box ref={drag} sx={stickerStyle} />;
};

// ----------------------------------------------------------------
// Sticker Panel (for multi-coin placeholders)
// ----------------------------------------------------------------
const StickerPanel = ({
  coins,
  placeholderId,
  onClose,
  onStickerDrop,
  onStickerMove,
}) => {
  const CoinWithStickerDrop = ({ coin, index }) => {
    const [{ isOver }, drop] = useDrop(
      () => ({
        accept: ["STICKER", "PLACED_STICKER"],
        drop: (item) => {
          if (item.itemType === "PLACED_STICKER") {
            // Move an already placed sticker.
            onStickerMove(
              index,
              item.placeholderId,
              item.coinIndex,
              item.stickerType
            );
          } else {
            onStickerDrop(index, item.stickerType);
          }
        },
        collect: (monitor) => ({ isOver: monitor.isOver() }),
      }),
      [coin]
    );

    return (
      <Box
        ref={drop}
        sx={{
          width: "80px",
          height: "80px",
          borderRadius: "50%",
          border: "2px dashed gray",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "10px",
          backgroundColor: isOver ? "rgba(0,255,0,0.2)" : "white",
        }}
      >
        <Typography variant="body2">{coin.label}</Typography>
        {coin?.sticker ? (
          <Box sx={{ mt: 0.5, width: "30px", height: "30px" }}>
            <DraggablePlacedSticker
              stickerType={coin.sticker}
              coinIndex={index}
              placeholderId={placeholderId}
            />
          </Box>
        ) : (
          <Typography variant="caption" sx={{ mt: 0.5, color: "gray" }}>
            Drop Sticker
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography variant="body">Place Your Sticker on a Coin</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {coins.map((coin, index) => (
          <CoinWithStickerDrop key={index} coin={coin} index={index} />
        ))}
      </Box>
      <Button
        variant="contained"
        size="small"
        onClick={onClose}
        sx={{ justifySelf: "center", display: "block", marginTop: "12px" }}
      >
        Close
      </Button>
    </Box>
  );
};

// ----------------------------------------------------------------
// Single Coin Display (for single-coin placeholders)
// ----------------------------------------------------------------
const SingleCoinDisplay = ({ coin, placeholderId }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "black",
        fontSize: "7pt",
      }}
    >
      <Typography
        sx={{
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: { xs: "8px", sm: "10px", md: "10px" },
        }}
      >
        {coin.label}
      </Typography>
      {coin.sticker && (
        <Box sx={{ mt: 0.5, width: "20px", height: "20px" }}>
          <DraggablePlacedSticker
            stickerType={coin.sticker}
            coinIndex={0}
            placeholderId={placeholderId}
          />
        </Box>
      )}
    </Box>
  );
};

// ----------------------------------------------------------------
// Draggable Sticker (from Global Pool)
// ----------------------------------------------------------------
const DraggableSticker = ({ stickerType }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "STICKER",
    // Include an explicit itemType so drop targets know it comes from the pool.
    item: { stickerType, itemType: "STICKER" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const stickerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: stickerType === "angel" ? "#FDEED2" : "#DDEEDF",
    // border: "1px solid gray",
    opacity: isDragging ? 0.5 : 1,
    cursor: "move",
    touchAction: "none", // Prevents unwanted gestures on mobile
    backgroundImage: `url(${stickerType === "angel" ? angelImg : demonImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return <Box ref={drag} sx={stickerStyle} />;
};

// ----------------------------------------------------------------
// Draggable Coin Placeholder (for the board)
// ----------------------------------------------------------------
const DraggableCoinPlaceholder = ({ label, sticker }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "black",
        fontSize: "7pt",
        width: { xs: "40px", sm: "50px", md: "50px", lg: "60px" },
        height: { xs: "40px", sm: "50px", md: "50px", lg: "60px" },
        borderRadius: "50%",
      }}
    >
      <Typography
        sx={{
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: { xs: "8px", sm: "10px", md: "10px" },
        }}
      >
        {label}
      </Typography>
      {sticker && (
        <Box
          sx={{
            mt: 0.5,
            width: "20px",
            height: "20px",
          }}
        >
          <img
            src={sticker === "angel" ? angelImg : demonImg}
            alt={sticker}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      )}
    </Box>
  );
};

// ----------------------------------------------------------------
// Coin Drop Placeholder (the drop target on the board)
// ----------------------------------------------------------------
const CoinDropPlaceholder = ({
  x,
  y,
  placeholderId,
  coins,
  onPlaceholderClick,
  onDirectStickerDrop,
  onStickerMove,
}) => {
  // For single-coin placeholders, accept both new and placed stickers.
  const acceptedTypes = coins.length === 1 ? ["STICKER", "PLACED_STICKER"] : [];
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: acceptedTypes,
      drop: (item) => {
        if (coins.length === 1) {
          if (item.itemType === "PLACED_STICKER") {
            // Move from one placeholder to here.
            onStickerMove &&
              onStickerMove(
                placeholderId,
                item.placeholderId,
                item.coinIndex,
                item.stickerType
              );
          } else {
            onDirectStickerDrop(placeholderId, item.stickerType);
          }
        }
      },
      collect: (monitor) => ({ isOver: monitor.isOver() }),
    }),
    [coins, placeholderId, onDirectStickerDrop, onStickerMove]
  );

  return (
    <Box
      ref={drop}
      onClick={() => {
        if (coins && coins.length > 1) onPlaceholderClick(placeholderId);
      }}
      sx={{
        position: "absolute",
        top: y,
        left: x,
        width: { xs: "50px", sm: "50px", md: "60px" },
        height: { xs: "50px", sm: "50px", md: "60px" },
        borderRadius: "50%",
        backgroundColor: isOver ? "rgba(0, 255, 0, 0.2)" : "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: coins && coins.length > 1 ? "pointer" : "default",
      }}
    >
      {coins && coins.length > 0 && (
        <Tooltip
          title={coins
            .map((coin) =>
              coin.sticker ? `${coin.label} (${coin.sticker})` : coin.label
            )
            .join(", ")}
          arrow
        >
          <div>
            {coins.length > 1 ? (
              <DraggableCoinPlaceholder label={coins.length} />
            ) : (
              <SingleCoinDisplay
                coin={coins[0]}
                placeholderId={placeholderId}
              />
            )}
          </div>
        </Tooltip>
      )}
    </Box>
  );
};

// ----------------------------------------------------------------
// Droppable Image Component (the game board)
// ----------------------------------------------------------------
const DroppableImage = ({
  imageSrc,
  placeholders,
  placedCoinsWithSticker,
  onPlaceholderClick,
  onDirectStickerDrop,
  onStickerMove,
}) => {
  return (
    <Box sx={{ position: "relative" }}>
      <img
        src={imageSrc}
        alt="Game Board"
        style={{
          width: "100%",
          height: "auto",
          borderRadius: "12px",
          boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
        }}
      />
      {placeholders.map((pos) => (
        <CoinDropPlaceholder
          key={pos.id}
          x={pos.x}
          y={pos.y}
          placeholderId={pos.id}
          coins={placedCoinsWithSticker[pos.id] || []}
          onPlaceholderClick={onPlaceholderClick}
          onDirectStickerDrop={onDirectStickerDrop}
          onStickerMove={onStickerMove}
        />
      ))}
    </Box>
  );
};

// ----------------------------------------------------------------
// Global Sticker Pool (shows available stickers)
// ----------------------------------------------------------------
const GlobalStickerPool = ({
  availableAngels,
  availableDemons,
  handleStickerReturnToPool,
}) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "PLACED_STICKER",
    drop: (item) => {
      handleStickerReturnToPool(item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <Box
      ref={drop}
      sx={{
        width: { xs: "95%", md: "300px" },
        flexShrink: 0,
        p: 1,
        border: isOver ? "2px dashed green" : "none",
      }}
    >
      <Typography
        sx={{
          color: "#0F1621",
          fontSize: "16px",
          fontWeight: 600,
          mb: 1,
        }}
      >
        Angel{" "}
        <span style={{ color: "#747474", fontSize: "14px" }}>(Strength)</span>
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          gap: "20px",
        }}
      >
        {Array.from({ length: availableAngels }).map((_, index) => (
          <DraggableSticker key={`angel-${index}`} stickerType="angel" />
        ))}
      </Box>
      <Typography
        sx={{
          color: "#0F1621",
          fontSize: "16px",
          fontWeight: 600,
          mt: 2,
          mb: 1,
        }}
      >
        Demon{" "}
        <span style={{ color: "#747474", fontSize: "14px" }}>
          (Area that we can improve or is weak)
        </span>
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          gap: "20px",
        }}
      >
        {Array.from({ length: availableDemons }).map((_, index) => (
          <DraggableSticker key={`demon-${index}`} stickerType="demon" />
        ))}
      </Box>
    </Box>
  );
};

// ----------------------------------------------------------------
// Page6 Component
// ----------------------------------------------------------------
const Page6 = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employeeDetails = JSON.parse(localStorage.getItem("employee"));

  const level3CoinswithSticker = useSelector(
    (state) => state.level3Data?.level3Data?.level3CoinswithSticker
  );
  const level3CoinsDetails = useSelector(
    (state) => state.level3Data?.level3Data?.level3CoinsDetails
  );

  // Local state is initialized from Redux.
  const [placedCoinsWithSticker, setPlacedCoinsWithStickers] = useState(
    level3CoinswithSticker
  );

  // For multi-coin placeholders, store the selected placeholder ID.
  const [selectedPlaceholderId, setSelectedPlaceholderId] = useState(null);

  // Global available sticker counts from context.
  const {
    availableAngels,
    setAvailableAngels,
    availableDemons,
    setAvailableDemons,
  } = useStickerContext();

  const handleProceed = () => {
    dispatch(setLevel3CoinsWithSticker(placedCoinsWithSticker));

    const transformCoinData = () => {
      const result = {};
      Object.keys(placedCoinsWithSticker)?.forEach((key) => {
        placedCoinsWithSticker[key]?.forEach((coin) => {
          result[coin.label] = {
            type: coin.sticker, // 'angel', 'demon', or null
            effected_by: null,
            whatStop: "",
            whatStart: "",
            whatContinue: "",
          };
        });
      });
      return result;
    };

    const newCoinStructure = transformCoinData();
    const mergeLevel3CoinsDetails = (existingDetails = {}, newDetails) => {
      const merged = {};
      Object.keys(newDetails).forEach((coinLabel) => {
        const existingCoin = existingDetails[coinLabel] || {};
        merged[coinLabel] = {
          type: newDetails[coinLabel].type,
          effected_by: existingCoin.hasOwnProperty("effected_by")
            ? existingCoin.effected_by
            : newDetails[coinLabel].effected_by,
          whatStop: existingCoin.hasOwnProperty("whatStop")
            ? existingCoin.whatStop
            : newDetails[coinLabel].whatStop,
          whatStart: existingCoin.hasOwnProperty("whatStart")
            ? existingCoin.whatStart
            : newDetails[coinLabel].whatStart,
          whatContinue: existingCoin.hasOwnProperty("whatContinue")
            ? existingCoin.whatContinue
            : newDetails[coinLabel].whatContinue,
        };
      });
      return merged;
    };
    const mergedDetails = mergeLevel3CoinsDetails(
      level3CoinsDetails,
      newCoinStructure
    );

    dispatch(setLevel3CoinsDetails(mergedDetails));
    navigate("/level3/page7");
  };

  const handleBack = () => {
    dispatch(setLevel3CoinsWithSticker(placedCoinsWithSticker));
    navigate("/level3/page5");
  };

  // Opens the inline sticker panel for multi-coin placeholders.
  const handlePlaceholderClick = (placeholderId) => {
    const coins = placedCoinsWithSticker[placeholderId];
    if (coins && coins.length > 1) {
      setSelectedPlaceholderId(placeholderId);
    }
  };

  // ----------------------------------------------------------------
  // For multi-coin placeholders: assign (or replace) a sticker via the panel.
  const handleStickerDrop = (coinIndex, stickerType) => {
    setPlacedCoinsWithStickers((prev) => {
      const updated = { ...prev };
      const coinsArray = [...(updated[selectedPlaceholderId] || [])];
      const coin = coinsArray[coinIndex];
      if (coin) {
        let updatedCoin = { ...coin };
        if (updatedCoin.sticker) {
          if (updatedCoin.sticker === stickerType) {
            // If the same sticker is dropped, remove it.
            updatedCoin.sticker = null;
            if (stickerType === "angel") {
              setAvailableAngels((prev) => prev + 1);
            } else if (stickerType === "demon") {
              setAvailableDemons((prev) => prev + 1);
            }
          } else {
            // Replace the sticker (return the old one to pool).
            const oldSticker = updatedCoin.sticker;
            updatedCoin.sticker = stickerType;
            if (oldSticker === "angel") {
              setAvailableAngels((prev) => prev + 1);
            } else if (oldSticker === "demon") {
              setAvailableDemons((prev) => prev + 1);
            }
            if (stickerType === "angel" && availableAngels > 0) {
              setAvailableAngels((prev) => prev - 1);
            } else if (stickerType === "demon" && availableDemons > 0) {
              setAvailableDemons((prev) => prev - 1);
            }
          }
        } else {
          // No sticker assigned; assign new one.
          updatedCoin.sticker = stickerType;
          if (stickerType === "angel" && availableAngels > 0) {
            setAvailableAngels((prev) => prev - 1);
          } else if (stickerType === "demon" && availableDemons > 0) {
            setAvailableDemons((prev) => prev - 1);
          }
        }
        coinsArray[coinIndex] = updatedCoin;
      }
      updated[selectedPlaceholderId] = coinsArray;
      dispatch(setLevel3CoinsWithSticker(updated));
      return updated;
    });
  };

  // ----------------------------------------------------------------
  // For single-coin placeholders (on the board)
  const handleDirectStickerDrop = (placeholderId, stickerType) => {
    setPlacedCoinsWithStickers((prev) => {
      const updated = { ...prev };
      const coinsArray = updated[placeholderId]
        ? [...updated[placeholderId]]
        : [];
      if (coinsArray.length === 1) {
        let coin = { ...coinsArray[0] };
        if (coin.sticker) {
          if (coin.sticker === stickerType) {
            coin = { ...coin, sticker: null };
            if (stickerType === "angel") {
              setAvailableAngels((prev) => prev + 1);
            } else if (stickerType === "demon") {
              setAvailableDemons((prev) => prev + 1);
            }
          } else {
            const oldSticker = coin.sticker;
            coin = { ...coin, sticker: stickerType };
            if (oldSticker === "angel") {
              setAvailableAngels((prev) => prev + 1);
            } else if (oldSticker === "demon") {
              setAvailableDemons((prev) => prev + 1);
            }
            if (stickerType === "angel" && availableAngels > 0) {
              setAvailableAngels((prev) => prev - 1);
            } else if (stickerType === "demon" && availableDemons > 0) {
              setAvailableDemons((prev) => prev - 1);
            }
          }
        } else {
          coin = { ...coin, sticker: stickerType };
          if (stickerType === "angel" && availableAngels > 0) {
            setAvailableAngels((prev) => prev - 1);
          } else if (stickerType === "demon" && availableDemons > 0) {
            setAvailableDemons((prev) => prev - 1);
          }
        }
        updated[placeholderId] = [coin];
      }
      dispatch(setLevel3CoinsWithSticker(updated));
      return updated;
    });
  };

  // ----------------------------------------------------------------
  // Move a placed sticker from one placeholder to a single-coin placeholder.
  const handleStickerMoveSingle = (
    targetPlaceholderId,
    sourcePlaceholderId,
    sourceCoinIndex,
    stickerType
  ) => {
    setPlacedCoinsWithStickers((prev) => {
      const updated = { ...prev };
      // Remove the sticker from the source.
      if (updated[sourcePlaceholderId]) {
        const sourceCoins = [...updated[sourcePlaceholderId]];
        if (
          sourceCoins[sourceCoinIndex] &&
          sourceCoins[sourceCoinIndex].sticker
        ) {
          sourceCoins[sourceCoinIndex] = {
            ...sourceCoins[sourceCoinIndex],
            sticker: null,
          };
        }
        updated[sourcePlaceholderId] = sourceCoins;
      }
      // Update the target placeholder (which is single-coin).
      if (
        updated[targetPlaceholderId] &&
        updated[targetPlaceholderId].length === 1
      ) {
        let targetCoin = { ...updated[targetPlaceholderId][0] };
        if (targetCoin.sticker) {
          // Return the old sticker to pool.
          if (targetCoin.sticker === "angel") {
            setAvailableAngels((prev) => prev + 1);
          } else if (targetCoin.sticker === "demon") {
            setAvailableDemons((prev) => prev + 1);
          }
        }
        targetCoin.sticker = stickerType;
        updated[targetPlaceholderId] = [targetCoin];
      }
      dispatch(setLevel3CoinsWithSticker(updated));
      return updated;
    });
  };

  // ----------------------------------------------------------------
  // Move a placed sticker within the multi-coin sticker panel.
  const handleStickerMoveMulti = (
    targetCoinIndex,
    sourcePlaceholderId,
    sourceCoinIndex,
    stickerType
  ) => {
    setPlacedCoinsWithStickers((prev) => {
      const updated = { ...prev };
      // Remove the sticker from the source.
      if (updated[sourcePlaceholderId]) {
        const sourceCoins = [...updated[sourcePlaceholderId]];
        if (
          sourceCoins[sourceCoinIndex] &&
          sourceCoins[sourceCoinIndex].sticker
        ) {
          sourceCoins[sourceCoinIndex] = {
            ...sourceCoins[sourceCoinIndex],
            sticker: null,
          };
        }
        updated[sourcePlaceholderId] = sourceCoins;
      }
      // Update the target coin in the currently selected placeholder.
      if (updated[selectedPlaceholderId]) {
        const targetCoins = [...updated[selectedPlaceholderId]];
        if (targetCoins[targetCoinIndex]) {
          let targetCoin = { ...targetCoins[targetCoinIndex] };
          if (targetCoin.sticker) {
            if (targetCoin.sticker === "angel") {
              setAvailableAngels((prev) => prev + 1);
            } else if (targetCoin.sticker === "demon") {
              setAvailableDemons((prev) => prev + 1);
            }
          }
          targetCoin.sticker = stickerType;
          targetCoins[targetCoinIndex] = targetCoin;
        }
        updated[selectedPlaceholderId] = targetCoins;
      }
      dispatch(setLevel3CoinsWithSticker(updated));
      return updated;
    });
  };

  const handleCloseStickerPanel = () => {
    setSelectedPlaceholderId(null);
  };

  // ----------------------------------------------------------------
  // Return a placed sticker to the global pool.
  const handleStickerReturnToPool = (item) => {
    const { stickerType, coinIndex, placeholderId } = item;
    setPlacedCoinsWithStickers((prev) => {
      const updated = { ...prev };
      if (updated[placeholderId]) {
        const coinsArray = [...updated[placeholderId]];
        if (coinsArray[coinIndex] && coinsArray[coinIndex].sticker) {
          coinsArray[coinIndex] = { ...coinsArray[coinIndex], sticker: null };
        }
        updated[placeholderId] = coinsArray;
      }
      dispatch(setLevel3CoinsWithSticker(updated));
      return updated;
    });
    if (stickerType === "angel") {
      setAvailableAngels((prev) => prev + 1);
    } else if (stickerType === "demon") {
      setAvailableDemons((prev) => prev + 1);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Layout>
        <Box
          sx={{
            width: "100%",
            marginBottom: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {/* welcome */}
          <Box
            sx={{
              width: "100%",
              marginBottom: "40px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: "100%",
                padding: "24px 0",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                justifyContent: "space-around",
                gap: { xs: "16px", sm: "0" },
              }}
            >
              <Stack
                direction="row"
                sx={{
                  background: "#FDEED2",
                  border: "2px solid #F7B73B",
                  borderRadius: "999px",
                  alignItems: "center",
                  padding: "4px 16px",
                  fontSize: "16px",
                }}
              >
                {employeeDetails?.employeeKey && "Level 3 - "}
                Identify strength & weakness
              </Stack>
              {isLargeScreen && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <Button
                    onClick={handleProceed}
                    variant="contained"
                    sx={{
                      alignSelf: "center",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      borderRadius: "8px",
                      width: { xs: "100%", sm: "120px" },
                      height: "32px",
                    }}
                  >
                    Proceed
                  </Button>
                  <Button
                    onClick={handleBack}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid var(--Primary-black, #0F1621)",
                      background: "#FFF",
                      boxShadow: "3px 3px 0px 0px #0F1621",
                      color: "#0F1621",
                      width: { xs: "100%", sm: "120px" },
                      height: "32px",
                      marginTop: "12px",
                      ":hover": {
                        background: "#FFF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Back
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 2, md: 4 },
              justifyContent: "center",
              alignItems: "flex-start",
              width: "100%",
              p: "20px 0",
            }}
          >
            {/* Block 1: Image Container */}
            <Box
              sx={{
                maxWidth: { xs: "95%", md: "400px", lg: "500px" },
                maxHeight: { xs: "auto", md: "400px", lg: "500px" },
                flexShrink: 0,
              }}
            >
              <DroppableImage
                imageSrc={image1}
                placeholders={placeholders}
                placedCoinsWithSticker={placedCoinsWithSticker}
                onPlaceholderClick={handlePlaceholderClick}
                onDirectStickerDrop={handleDirectStickerDrop}
                onStickerMove={handleStickerMoveSingle} // for single-coin drops on board
              />
            </Box>

            {/* Block 2: Inline Sticker Panel */}
            <Box
              sx={{
                width: { xs: "95%", md: "200px" },
                flexShrink: 0,
                background: "#f9f9f9",
                border: "1px solid #ccc",
                borderRadius: "8px",
                visibility: selectedPlaceholderId ? "visible" : "hidden",
                p: 2,
              }}
            >
              {selectedPlaceholderId ? (
                <StickerPanel
                  coins={placedCoinsWithSticker[selectedPlaceholderId]}
                  placeholderId={selectedPlaceholderId}
                  onClose={handleCloseStickerPanel}
                  onStickerDrop={handleStickerDrop}
                  onStickerMove={handleStickerMoveMulti} // for moves within the panel
                />
              ) : (
                <Box sx={{ height: "100%" }} />
              )}
            </Box>

            {/* Block 3: Global Sticker Pool */}
            <GlobalStickerPool
              availableAngels={availableAngels}
              availableDemons={availableDemons}
              handleStickerReturnToPool={handleStickerReturnToPool}
            />
            {/* buttons */}
            {!isLargeScreen && (
              <Box
                sx={{
                  margin: "40px 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  flexDirection: { xs: "column", sm: "row" },
                  width: "100%",
                }}
              >
                <Button
                  onClick={handleBack}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid var(--Primary-black, #0F1621)",
                    background: "#FFF",
                    boxShadow: "3px 3px 0px 0px #0F1621",
                    color: "#0F1621",
                    width: { xs: "100%", sm: "160px" },
                    height: "40px",
                    ":hover": {
                      background: "#FFF",
                      boxShadow: "none",
                    },
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={handleProceed}
                  variant="contained"
                  sx={{
                    alignSelf: "center",
                    boxShadow: "3px 3px 0px 0px #0F1621",
                    borderRadius: "8px",
                    width: { xs: "100%", sm: "160px" },
                    height: "40px",
                  }}
                >
                  Proceed
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Layout>
    </DndProvider>
  );
};

export default Page6;
