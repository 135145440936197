import { createContext, useState } from "react";

const EmployeeContext = createContext();

export const EmployeeProvider = ({ children }) => {
  const [employeeDetails, setEmployeeDetails] = useState(null);

  return (
    <EmployeeContext.Provider value={{ employeeDetails, setEmployeeDetails }}>
      {children}
    </EmployeeContext.Provider>
  );
};

export default EmployeeContext;
