import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  InputAdornment,
  IconButton,
  Dialog,
  Grid,
} from "@mui/material";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LogoHeader from "../../components/LogoHeader";
import logo from "../../assets/riddlebox.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { key } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [validationError, setValidationError] = useState("");
  const [companyDetails, setCompanyDetails] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/confirmRegistration/${key}`
        );
        setCompanyDetails(response.data);
        setEmail(response.data?.admin_email);
      } catch (err) {
        setError(
          err.response?.data?.message ||
            "Failed to fetch company details. Please check the confirmation link."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [key]);

  const handleSubmit = async () => {
    if (!email || !password) {
      setValidationError("Email and Password can't be empty");
      return;
    }
    if (password !== confirmPassword) {
      setValidationError("Passwords do not match!");
      return;
    }
    if (email === password) {
      setValidationError("Password cannot be the same as the email!");
      return;
    }

    setValidationError("");
    setError("");
    setLoading(true);

    try {
      const payload = new URLSearchParams({
        email: email,
        password: password,
        key: key,
      }).toString();

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/updatePassword`,
        payload,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.data.success === true) {
        setOpenSuccessDialog(true);
        // setTimeout(() => {
        //   navigate("/login");
        // }, 1000);
      } else {
        response.data.error && setError(response.data.error);
      }
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "Failed to set password. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
    navigate("/");
  };

  return (
    <>
      <LogoHeader />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#f5f5f5",
          padding: 3,
        }}
      >
        <Card
          sx={{
            maxWidth: 500,
            width: "100%",
            borderRadius: 3,
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: 3,
              }}
            >
              Update Password
            </Typography>

            {error && (
              <Alert severity="error" sx={{ marginBottom: 2 }}>
                {error.trim().toLowerCase() === "invalid username" ? (
                  <>
                    It seems like you have already reset the password. If you'd
                    like to reset it again, click the button below.
                    <div style={{ marginTop: "10px" }}>
                      <button
                        onClick={() => navigate("/forgot-password")}
                        style={{
                          width: "100%",
                          padding: "10px 20px",
                          fontSize: "14px",
                          fontWeight: "bold",
                          borderRadius: "8px",
                          border: "none",
                          backgroundColor: "#d32f2f",
                          color: "#fff",
                          cursor: "pointer",
                          transition: "background-color 0.3s ease",
                          display: "block",
                          margin: "auto",
                        }}
                        onMouseOver={(e) =>
                          (e.target.style.backgroundColor = "#b71c1c")
                        }
                        onMouseOut={(e) =>
                          (e.target.style.backgroundColor = "#d32f2f")
                        }
                      >
                        Reset my password
                      </button>
                    </div>
                  </>
                ) : (
                  error
                )}
              </Alert>
            )}

            {validationError && (
              <Alert severity="warning" sx={{ marginBottom: 2 }}>
                {validationError}
              </Alert>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <TextField
                label="Email"
                type="email"
                placeholder="Please enter your email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "14px", fontWeight: 500 },
                }}
              />

              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                placeholder="Please enter your password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "14px", fontWeight: 500 },
                }}
              />
              <TextField
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm your password"
                fullWidth
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "14px", fontWeight: 500 },
                }}
              />

              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading}
                sx={{
                  textTransform: "none",
                  padding: 1.5,
                  fontSize: "16px",
                  fontWeight: 600,
                  background: "#3a569a",
                  color: "white",
                  ":hover": {
                    background: "#3a569a",
                    color: "#fff",
                  },
                }}
              >
                {loading ? <CircularProgress size={24} /> : "Update Password"}
              </Button>
            </Box>
          </CardContent>
        </Card>
        {/* Success Dialog */}
        <Dialog
          open={openSuccessDialog}
          onClose={handleCloseSuccessDialog}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: { p: { xs: 4, sm: 6 }, borderRadius: 3, textAlign: "center" },
          }}
        >
          <Grid container spacing={3} alignItems="center">
            {/* Left Section: Title & Button */}
            <Grid
              item
              xs={12}
              sm={7}
              sx={{ textAlign: { xs: "center", sm: "left" } }}
            >
              <Typography
                variant="h5"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", sm: "flex-start" },
                  gap: 1,
                  fontWeight: "bold",
                  color: "#80005e",
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                <CheckCircleIcon
                  sx={{ color: "#80005e", fontSize: "2.5rem" }}
                />
                Your password has been reset
              </Typography>

              <Button
                onClick={handleCloseSuccessDialog}
                variant="contained"
                sx={{
                  mt: 4,
                  backgroundColor: "#80005e",
                  color: "white",
                  px: 3,
                  py: 1,
                  borderRadius: 2,
                  textTransform: "none",
                  fontSize: "1rem",
                  "&:hover": { backgroundColor: "#a0006e" },
                  width: { xs: "100%", sm: "auto" },
                }}
              >
                Go to My Account
              </Button>
            </Grid>

            {/* Right Section: Logo */}
            <Grid
              item
              xs={12}
              sm={5}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{ maxHeight: "150px", maxWidth: "100%" }}
              />
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    </>
  );
};

export default ResetPassword;
