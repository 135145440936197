import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
// level 1
import Welcome from "./pages/level1/Welcome";
import ScrollToTop from "./components/ScrollToTop";
import GettingStarted from "./pages/level1/GettingStarted";
import HowToPlay from "./pages/level1/HowToPlay";
import Page1 from "./pages/level1/Page1";
import Page2 from "./pages/level1/Page2";
import Page3 from "./pages/level1/Page3";
import Page4 from "./pages/level1/Page4";
import Page5 from "./pages/level1/Page5";
// level 2
import L2Welcome from "./pages/level2/Welcome";
import L2Page1 from "./pages/level2/Page1";
import L2Page2 from "./pages/level2/Page2";
import L2Page3 from "./pages/level2/Page3";
import L2Page4 from "./pages/level2/Page4";
import L2GettingStarted from "./pages/level2/GettingStarted";
import L2Page5 from "./pages/level2/Page5";
import L2Page6 from "./pages/level2/Page6";
import L2Page7 from "./pages/level2/Page7";
import L2Page8 from "./pages/level2/Page8";
import L2Page9 from "./pages/level2/Page9";
import L2Page10 from "./pages/level2/Page10";
import L2Page11 from "./pages/level2/Page11";
import L2Page12 from "./pages/level2/Page12";
import L2Page13 from "./pages/level2/Page13";
import L2Page14 from "./pages/level2/Page14";
import L2Result from "./pages/level2/Result";
// level 3
import L3Welcome from "./pages/level3/Welcome";
import L3Page1 from "./pages/level3/Page1";
import L3Page2 from "./pages/level3/Page2";
import L3Page3 from "./pages/level3/Page3";
import L3Page4 from "./pages/level3/Page4";
import L3GettingStarted from "./pages/level3/GettingStarted";
import L3Page5 from "./pages/level3/Page5";
import L3Page6 from "./pages/level3/Page6";
import L3Page7 from "./pages/level3/Page7";
import L3Page8 from "./pages/level3/Page8";
import L3Page9 from "./pages/level3/Page9";
import L3Page14 from "./pages/level3/Page14";
import L3Result from "./pages/level3/Result";
// management-view-data
import ViewDataPage1 from "./pages/dataManagementView/Page1";
import ViewDataPage2 from "./pages/dataManagementView/Page2";
import ViewDataPage3 from "./pages/dataManagementView/Page3";
import ViewDataPage4 from "./pages/dataManagementView/Page4";
import ViewDataPage5 from "./pages/dataManagementView/Page5";
import ViewDataPage6 from "./pages/dataManagementView/Page6";
// others
import AdminDashboard from "./pages/dashboard/Index";
import ForgotPassword from "./pages/resetPassword/ForgotPassword";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import ConfirmRegistration from "./pages/signup/ConfirmRegistration";
import { EmployeeProvider } from "./context/EmployeeContext";
import { StickerProvider } from "./context/level2/stickerContext";
import ManagementStickerProvider from "./context/dataManagmentView/managementStickerContext";

const theme = createTheme({
  typography: {
    fontFamily: "'Manrope', sans-serif",
  },
  palette: {
    primary: {
      main: "#4799A9",
    },
    secondary: {
      main: "#FFC107",
    },
    custom: {
      teal: "#4799A9",
      lightTeal: "#7FBFBC",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#4799A9",
          "&:hover": {
            backgroundColor: "#357f85",
          },
        },
      },
    },
  },
});

const PrivateRoute = ({ children }) => {
  const isAuthenticated = JSON.parse(localStorage.getItem("companyData"));

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/" replace />;
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <EmployeeProvider>
        <StickerProvider>
          <ManagementStickerProvider>
            <BrowserRouter>
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/register" element={<Signup />} />
                <Route
                  path="/confirmRegistration/:key"
                  element={<ConfirmRegistration />}
                />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/resetPassword/:key" element={<ResetPassword />} />
                {/* level 1 */}
                <Route path="/doSurvey/:key" element={<Welcome />} />
                <Route
                  path="/admin-panel"
                  element={
                    <PrivateRoute>
                      <AdminDashboard />
                    </PrivateRoute>
                  }
                />
                <Route path="/level1/how-to-play" element={<HowToPlay />} />
                <Route
                  path="/level1/getting-started"
                  element={<GettingStarted />}
                />
                <Route path="/level1/page1" element={<Page1 />} />
                <Route path="/level1/page2" element={<Page2 />} />
                <Route path="/level1/page3" element={<Page3 />} />
                <Route path="/level1/page4" element={<Page4 />} />
                <Route path="/level1/page5" element={<Page5 />} />
                {/* level 2 */}
                <Route path="/level2/welcome/:key" element={<L2Welcome />} />
                <Route path="/level2/page1" element={<L2Page1 />} />
                <Route path="/level2/page2" element={<L2Page2 />} />
                <Route path="/level2/page3" element={<L2Page3 />} />
                <Route path="/level2/page4" element={<L2Page4 />} />
                <Route
                  path="/level2/getting-started"
                  element={<L2GettingStarted />}
                />
                <Route path="/level2/page5" element={<L2Page5 />} />
                <Route path="/level2/page6" element={<L2Page6 />} />
                <Route path="/level2/page7" element={<L2Page7 />} />
                <Route path="/level2/page8" element={<L2Page8 />} />
                <Route path="/level2/page9" element={<L2Page9 />} />
                <Route path="/level2/page10" element={<L2Page10 />} />
                <Route path="/level2/page11" element={<L2Page11 />} />
                <Route path="/level2/page12" element={<L2Page12 />} />
                <Route path="/level2/page13" element={<L2Page13 />} />
                <Route path="/level2/page14" element={<L2Page14 />} />
                <Route path="/level2/result" element={<L2Result />} />
                {/* level 3 */}
                <Route path="/level3/welcome" element={<L3Welcome />} />
                <Route path="/level3/page1" element={<L3Page1 />} />
                <Route path="/level3/page2" element={<L3Page2 />} />
                <Route path="/level3/page3" element={<L3Page3 />} />
                <Route path="/level3/page4" element={<L3Page4 />} />
                <Route
                  path="/level3/getting-started"
                  element={<L3GettingStarted />}
                />
                <Route path="/level3/page5" element={<L3Page5 />} />
                <Route path="/level3/page6" element={<L3Page6 />} />
                <Route path="/level3/page7" element={<L3Page7 />} />
                <Route path="/level3/page8" element={<L3Page8 />} />
                <Route path="/level3/page9" element={<L3Page9 />} />
                <Route path="/level3/page14" element={<L3Page14 />} />
                <Route path="/level3/result" element={<L3Result />} />
                {/* data-management-view */}
                <Route
                  path="/data-management-view/page1"
                  element={<ViewDataPage1 />}
                />
                <Route
                  path="/data-management-view/page2"
                  element={<ViewDataPage2 />}
                />
                <Route
                  path="/data-management-view/page3"
                  element={<ViewDataPage3 />}
                />
                <Route
                  path="/data-management-view/page4"
                  element={<ViewDataPage4 />}
                />
                <Route
                  path="/data-management-view/page5"
                  element={<ViewDataPage5 />}
                />
                <Route
                  path="/data-management-view/page6"
                  element={<ViewDataPage6 />}
                />
              </Routes>
            </BrowserRouter>
          </ManagementStickerProvider>
        </StickerProvider>
      </EmployeeProvider>
    </ThemeProvider>
  );
};

export default App;
