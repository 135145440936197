import { Box } from "@mui/material";
import React from "react";
import logo from "../assets/riddlebox.png";

const LogoHeader = () => {
  return (
    <Box
      sx={{
        backgroundImage: "linear-gradient(to right, #fff 20%, #3a569a)",
        padding: "10px 20px",
        position: "fixed",
        width: "100%",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        zIndex: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "10px",
        fontSize: "12px",
      }}
    >
      <Box sx={{ display: "flex", height: "70px" }}>
        <img src={logo} alt="Kaya Logo" style={{ objectFit: "cover" }} />
      </Box>
    </Box>
  );
};

export default LogoHeader;
