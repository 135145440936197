import React from "react";
import { Stack, Button, Typography, Box } from "@mui/material";
import Layout from "../../components/navbar/Layout";
import { Check } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Result = () => {
  const employeeDetails = JSON.parse(localStorage.getItem("employee"));
  const companyDetails = JSON.parse(localStorage.getItem("companyData"));
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/level3/page14");
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: { xs: 2, md: 2 },
          mb: 6,
        }}
      >
        <Stack
          spacing={2}
          alignItems="center"
          sx={{ width: "100%", maxWidth: 800 }}
        >
          {/* Success Icon */}
          <Box
            sx={{
              width: 80,
              height: 80,
              borderRadius: "50%",
              backgroundColor: "primary.main",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Check sx={{ fontSize: 30, color: "white" }} />
          </Box>

          {/* Welcome Message */}
          <Typography
            variant="h4"
            component="h1"
            fontWeight="bold"
            textAlign="center"
          >
            Congratulations{" "}
            {employeeDetails?.associate_name || companyDetails?.admin_name}!
          </Typography>
          <Typography variant="body1" textAlign="center" sx={{ px: 2 }}>
            You have successfully completed all the levels of our board game
          </Typography>

          {/* Video Section */}
          <Box
            sx={{
              width: "100%",
              maxWidth: 846,
              borderRadius: "16px",
              overflow: "hidden",
              boxShadow: 3,
            }}
          >
            <video
              width="100%"
              height="auto"
              autoPlay
              muted
              loop
              controls
              style={{ display: "block" }}
            >
              <source src={``} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
          {/* Back Button */}
          <Button
            onClick={handleBack}
            variant="contained"
            sx={{
              boxShadow: "3px 3px 0px 0px #0F1621",
              borderRadius: "8px",
              width: { xs: "100%", sm: "160px" },
              height: "40px",
            }}
          >
            Back
          </Button>
        </Stack>
      </Box>
    </Layout>
  );
};

export default Result;
