import React, { useContext, useState } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Avatar, Badge, Box, Menu, MenuItem, Divider } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import logo from "../../assets/riddlebox.png";
import EmployeeContext from "../../context/EmployeeContext";

const Navbar = () => {
  const { employeeDetails } = useContext(EmployeeContext);
  const [unreadNotifications, setUnreadNotifications] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <nav className="mx-0 py-5 md:mx-20 md:py-5 px-4 md:px-0 border-b border-[#C8D3F4] sticky top-0 z-50 bg-white">
      <Box className="flex items-center justify-between flex-wrap">
        {/* Logo Section */}
        <div className="flex items-center justify-center flex-shrink-0">
          <img src={logo} alt="Logo" className="object-cover h-[80px]" />
        </div>

        {/* Right Section */}
        <div className="flex items-center gap-6">
          {/* <Box
            sx={{
              border: "1px solid lightgray",
              borderRadius: "50%",
              padding: "10px",
              width: "60px",
              height: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "@media (max-width: 600px)": {
                width: "50px",
                height: "50px",
              },
            }}
          >
            <Badge
              color="error"
              variant={unreadNotifications ? "dot" : "standard"}
              overlap="circular"
            >
              <NotificationsIcon
                className="text-gray-600 cursor-pointer"
                fontSize="large"
              />
            </Badge>
          </Box> */}

          {/* User Info with Dropdown */}
          <div
            className="flex items-center gap-3 border border-gray-200 rounded-lg py-2 px-4 cursor-pointer"
            onClick={handleClick}
          >
            {employeeDetails?.photo ||
            JSON.parse(localStorage.getItem("companyData"))?.logo ? (
              <img
                src={
                  employeeDetails?.photo ||
                  JSON.parse(localStorage.getItem("companyData"))?.logo
                }
                alt="Profile"
                className="h-10 w-10"
              />
            ) : (
              <Avatar alt="Profile" className="h-10 w-10 ">
                {employeeDetails?.associate_name?.charAt(0).toUpperCase()}
              </Avatar>
            )}

            <div className="hidden sm:block">
              <p className="text-gray-800 font-semibold">
                {employeeDetails?.associate_name ||
                  JSON.parse(localStorage.getItem("companyData"))?.company_name}
              </p>
            </div>
          </div>

          {/* Dropdown Menu */}
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            {/* User Info for Small Screens */}
            <div className="p-4 flex items-center gap-3 sm:hidden">
              {/* <Avatar
                src={employeeDetails?.photo}
                alt="Profile"
                className="h-12 w-12"
              /> */}
              <div>
                <p className="text-gray-800 font-semibold">
                  {employeeDetails?.associate_name ||
                    JSON.parse(localStorage.getItem("companyData"))
                      ?.company_name}
                </p>
                {/* <p className="text-sm text-gray-500">{user.post}</p> */}
              </div>
            </div>
          </Menu>
        </div>
      </Box>
    </nav>
  );
};

export default Navbar;
