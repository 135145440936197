import React, { useEffect, useRef, useState } from "react";
import {
  Stack,
  Button,
  Typography,
  Box,
  Tooltip,
  Modal,
  TextField,
  Divider,
  IconButton,
  Tabs,
  Tab,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/navbar/Layout";
import { AccessTime } from "@mui/icons-material";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import image1 from "../../assets/game board.png";
import { HTML5Backend } from "react-dnd-html5-backend";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { motion } from "framer-motion";
import { fetchLevel1Coins } from "../../redux/slices/level1CoinsSlice";
import { useDispatch, useSelector } from "react-redux";
import ReactAudioPlayer from "react-audio-player";
import { useTheme } from "@emotion/react";

// const AudioPlayerWithBackground = () => {
//   const audioRef = useRef(null);
//   const [isPlaying, setIsPlaying] = useState(true);
//   const [isBuffering, setIsBuffering] = useState(false);

//   // UseEffect for autoplay and unmute logic
//   useEffect(() => {
//     if (audioRef.current) {
//       // Start muted to get past autoplay restrictions
//       audioRef.current.muted = true;

//       // Wait for 10ms before unmuting to ensure autoplay works
//       setTimeout(() => {
//         if (audioRef.current) {
//           audioRef.current.muted = false; // Unmute after a small delay
//         }
//       }, 10);
//     }
//   }, []);

//   const handlePlayPause = () => {
//     if (audioRef.current) {
//       if (isPlaying) {
//         audioRef.current.pause();
//       } else {
//         audioRef.current.play();
//       }
//       setIsPlaying(!isPlaying);
//     }
//   };

//   const handleCanPlay = () => {
//     setIsBuffering(false); // Audio is ready to play
//   };

//   const handleWaiting = () => {
//     setIsBuffering(true); // Audio is buffering
//   };

//   return (
//     <div
//       style={{
//         position: "relative",
//         width: "auto",
//         height: "320px",
//         borderRadius: "1rem",
//         backgroundImage:
//           "url(https://images.pexels.com/photos/956999/milky-way-starry-sky-night-sky-star-956999.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)",
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         cursor: "pointer",
//         flexDirection: "column",
//       }}
//     >
//       {/* MUI Audio Player with Custom Controls */}
//       <audio
//         ref={audioRef}
//         src={audioFile}
//         autoPlay
//         loop
//         controls
//         onCanPlay={handleCanPlay}
//         onWaiting={handleWaiting}
//         style={{
//           display: "none", // Hide default controls
//         }}
//       />

//       {/* Centered Play/Pause Button */}
//       <div
//         style={{
//           position: "absolute",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           width: "100%",
//           height: "100%",
//         }}
//       >
//         <IconButton
//           onClick={handlePlayPause}
//           style={{
//             backgroundColor: "rgba(0, 0, 0, 0.7)",
//             borderRadius: "50%",
//             padding: "20px",
//             color: "white",
//             fontSize: "40px",
//           }}
//         >
//           {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
//         </IconButton>
//       </div>

//       {/* Buffering Indicator */}
//       {isBuffering && (
//         <div
//           style={{
//             position: "absolute",
//             top: "50%",
//             transform: "translateY(-50%)",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             color: "white",
//           }}
//         >
//           <CircularProgress style={{ color: "white" }} />
//         </div>
//       )}
//     </div>
//   );
// };

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

// Draggable Coin component
const DraggableCoin = ({ label, index, onDrag }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "COIN",
    item: { label, index }, // Include the index to identify the coin
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        fill: "#FBFFFF",
        strokeWidth: "1.01px",
        stroke: "#0F1621",
        filter: "drop-shadow(2.02px 2.02px 0px #0F1621)",
        width: "clamp(48px, 12vw, 68px)",
        height: "clamp(48px, 12vw, 68px)",
        flexShrink: "0",
        borderRadius: "50%",
        backgroundColor: "#FBFFFF",
        fontSize: "clamp(10px, 2vw, 14px)",
        fontWeight: "bold",
        color: "#0F1621",
        transition: "all 0.3s ease-in-out",
        whiteSpace: "normal",
        wordWrap: "break-word",
        lineHeight: "1.2",
        padding: "5px",
        boxSizing: "border-box",
        overflow: "hidden",
        maxWidth: "100%",
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <Typography
        sx={{
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: {
            xs: "8px",
            sm: "8px",
            md: "10px",
            lg: "10px",
          },
        }}
      >
        {label}
      </Typography>
    </div>
  );
};

// Draggable Coin for placeholders (only text)
const DraggableCoinPlaceholder = ({ label, index, onDrag }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "COIN",
    item: { label, index }, // Include the index to identify the coin
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Box
      ref={drag}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        fontSize: "clamp(10px, 2vw, 14px)",
        transition: "all 0.3s ease-in-out",
        whiteSpace: "normal",
        boxSizing: "border-box",
        opacity: isDragging ? 0.5 : 1,
        color: "black",
        fontSize: "7pt",
        wordWrap: "break-word",
        overflowWrap: "break-word",
        whiteSpace: "normal",
        width: { xs: "30px", sm: "30px", md: "40px" },
        height: { xs: "30px", sm: "30px", md: "40px" },
        lineHeight: "1.1",
        borderRadius: "50%",
        "&:hover": {
          background: "rgba(0, 255, 0, 0.2)",
        },
      }}
    >
      <span
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "8px",
        }}
      >
        {label}
      </span>
    </Box>
  );
};

// Placeholder for dropping coins on specific positions
const CoinDropPlaceholder = ({
  x,
  y,
  placeholderId,
  coins,
  onDrop,
  onDragEnd,
}) => {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "COIN",
      drop: (item) => onDrop(item.label, placeholderId), // Pass the coin label and index
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [coins]
  );

  const handleDragEnd = (e) => {
    if (coins?.length > 0) {
      const removedCoin = coins[coins.length - 1]; // Get the last coin in the placeholder
      onDragEnd(removedCoin, placeholderId); // Call the onDragEnd function to remove the coin from placedCoins
    }
  };

  return (
    <Box
      ref={drop}
      sx={{
        position: "absolute",
        top: y,
        left: x,
        width: { xs: "50px", sm: "50px", md: "60px" },
        height: { xs: "50px", sm: "50px", md: "60px" },
        borderRadius: "50%",
        backgroundColor: isOver ? "rgba(0, 255, 0, 0.2)" : "transparent",
        // border: "1px solid lightgray",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      draggable={coins?.length > 0} // Make it draggable only if there are coins
      onDragEnd={handleDragEnd} // Handle drag end to remove the coin when dragged out
    >
      {coins && (
        <Tooltip
          title={coins.join(", ")}
          arrow
          sx={{
            "& .MuiTooltip-tooltip": {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              color: "white",
              fontSize: "12px",
              maxWidth: "200px",
              padding: "8px",
              lineHeight: "1.4",
            },
          }}
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -8],
                },
              },
            ],
          }}
        >
          <div
            style={
              {
                // color: "gray",
                // fontSize: "14px",
                // wordWrap: "break-word",
                // overflowWrap: "break-word",
                // whiteSpace: "normal",
                // maxWidth: "60px",
                // lineHeight: "1.1",
              }
            }
          >
            {/* {coins?.length > 1 ? coins?.length : coins[coins.length - 1]} */}
            <DraggableCoinPlaceholder
              label={
                coins?.length > 1 ? coins?.length : coins[coins.length - 1]
              } // Display the count or the last coin label
              // index={index}
              onDrag={handleDragEnd}
            />
          </div>
        </Tooltip>
      )}
    </Box>
  );
};

// Droppable Image Component that holds the coins
const DroppableImage = ({ imageSrc, placeholders, setCoins }) => {
  const [placedCoins, setPlacedCoins] = useState(
    Array(placeholders.length).fill([])
  );

  // Function to handle coin drop on the placeholders
  const handleCoinDrop = (coinLabel, placeholderId) => {
    // setPlacedCoins((prevCoins) => {
    //   const updatedCoins = [...prevCoins];
    //   updatedCoins[index] = [...updatedCoins[index], coinLabel];
    //   return updatedCoins;
    // });
    setPlacedCoins((prevCoins) => ({
      ...prevCoins,
      [placeholderId]: [...(prevCoins[placeholderId] || []), coinLabel], // Append coin
    }));
    setCoins((prevCoins) =>
      prevCoins?.filter((coin) => coin.label !== coinLabel)
    );
  };

  // Function to handle the placeholder being dragged out (undo)
  const handlePlaceholderDragEnd = (coinLabel, placeholderId) => {
    // Remove the coin from the placed coins
    // setPlacedCoins((prevCoins) => {
    //   const updatedCoins = [...prevCoins];
    //   updatedCoins[index] = updatedCoins[index].filter(
    //     (coin) => coin !== coinLabel
    //   );
    //   return updatedCoins;
    // });

    setPlacedCoins((prevCoins) => {
      const updatedCoins = { ...prevCoins };
      updatedCoins[placeholderId] = updatedCoins[placeholderId].filter(
        (coin) => coin !== coinLabel
      ); // Remove coin
      return updatedCoins;
    });

    // Add the coin back to the available setCoins
    setCoins((prevCoins) => [...prevCoins, { label: coinLabel }]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {/* Image */}
      <img
        src={imageSrc}
        alt="Game Board"
        style={{
          width: "100%",
          height: "auto",
          pointerEvents: "none",
          borderRadius: "12px",
          boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
        }}
      />

      {/* Circular Placeholders */}
      {placeholders?.map((pos, index) => (
        <CoinDropPlaceholder
          key={pos.id}
          x={pos.x}
          y={pos.y}
          placeholderId={pos.id}
          coins={placedCoins[pos.id] || []}
          onDrop={handleCoinDrop}
          onDragEnd={handlePlaceholderDragEnd} // Pass the function for undo (drag out)
        />
      ))}
    </Box>
  );
};

const HowToPlay = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const { level1Coins } = useSelector((state) => state?.level1Coins);
  const employeeDetails = JSON.parse(localStorage.getItem("employee"));
  const [timer, setTimer] = useState(900);
  const [showModal, setShowModal] = useState(false);
  const [newCoin, setNewCoin] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    setCoins(level1Coins);
  }, [level1Coins]);

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handleAudioEnd = () => setIsPlaying(false);
      audio.addEventListener("ended", handleAudioEnd);
      return () => {
        audio.removeEventListener("ended", handleAudioEnd);
      };
    }
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (_, newIndex) => {
    setTabIndex(newIndex);
  };

  const placeholders = [
    { id: "customer_1_coins", x: "26%", y: "6%" },
    { id: "customer_2_coins", x: "12%", y: "15%" },
    { id: "customer_3_coins", x: "11%", y: "33%" },
    { id: "customer_4_coins", x: "23%", y: "25%" },
    { id: "customer_employee_coins", x: "42%", y: "17%" },
    { id: "customer_employee_strategy_coins", x: "42%", y: "35%" },
    { id: "customer_strategy_coins", x: "27%", y: "42%" },
    { id: "employee_1_coins", x: "58%", y: "7%" },
    { id: "employee_2_coins", x: "72%", y: "15%" },
    { id: "employee_3_coins", x: "61%", y: "25%" },
    { id: "employee_4_coins", x: "73%", y: "33%" },
    { id: "employee_strategy_coins", x: "56%", y: "42%" },
    { id: "strategy_1_coins", x: "42%", y: "57%" },
    { id: "strategy_2_coins", x: "26%", y: "61%" },
    { id: "strategy_3_coins", x: "58%", y: "60%" },
    { id: "strategy_4_coins", x: "42%", y: "72%" },
  ];
  // const [coins, setCoins] = useState([
  //   { id: "level1_1_Advertising", label: "Advertising" },
  //   { id: "level1_1_Agility", label: "Agility" },
  //   { id: "level1_1_Auditing", label: "Auditing" },
  //   { id: "level1_1_BottomlineFocus", label: "Bottomline focus" },
  //   { id: "level1_1_Brand", label: "Brand" },
  //   { id: "level1_1_BreadthOfProducts", label: "Breadth of products" },
  //   { id: "level1_1_BudgetingSystem", label: "Budgeting System" },
  //   { id: "level1_1_CapitalOptimization", label: "Capital Optimization" },
  //   { id: "level1_1_ChangeLeadership", label: "Change Leadership" },
  //   { id: "level1_1_CommercialFocus", label: "Commercial focus" },
  //   { id: "level1_1_Commitment", label: "Commitment" },
  //   { id: "level1_1_CompetitorAnalysis", label: "Competitor Analysis" },
  //   { id: "level1_1_ComplaintResolution", label: "Complaint Resolution" },
  //   { id: "level1_1_Compliance", label: "Compliance" },
  //   { id: "level1_1_ConsensusBuilding", label: "Consensus Building" },
  //   { id: "level1_1_ContingencyPlanning", label: "Contingency Planning" },
  //   { id: "level1_1_CostFocus", label: "Cost focus" },
  //   { id: "level1_1_Creativity", label: "Creativity" },
  //   { id: "level1_1_CustomerEngagement", label: "Customer Engagement" },
  //   { id: "level1_1_CustomerFocus", label: "Customer focus" },
  //   { id: "level1_1_CustomerRetention", label: "Customer retention" },
  //   { id: "level1_1_CybersecurityPlan", label: "Cybersecurity Plan" },
  //   { id: "level1_1_DataAnalytics", label: "Data Analytics" },
  //   { id: "level1_1_DecisionMaking", label: "Decision making" },
  //   { id: "level1_1_EffectiveCommunication", label: "Effective communication" },
  //   { id: "level1_1_EfficientProcedures", label: "Efficient procedures" },
  //   { id: "level1_1_EmotionalIntelligence", label: "Emotional Intelligence" },
  //   { id: "level1_1_EmployeeEngagement", label: "Employee engagement" },
  //   { id: "level1_1_EmployeeRetention", label: "Employee Retention" },
  //   { id: "level1_1_EqualOpportunities", label: "Equal opportunities" },
  //   { id: "level1_1_EthicalStandards", label: "Ethical standards" },
  //   { id: "level1_1_FinancialControl", label: "Financial control" },
  //   { id: "level1_1_GoalAlignment", label: "Goal Alignment" },
  //   { id: "level1_1_HealthAndSafety", label: "Health and Safety" },
  //   { id: "level1_1_Innovation", label: "Innovation" },
  //   { id: "level1_1_ITAlignment", label: "IT Alignment" },
  //   { id: "level1_1_Leadership", label: "Leadership" },
  //   { id: "level1_1_LeadershipQuality", label: "Leadership Quality" },
  //   { id: "level1_1_LegalCompliance", label: "Legal Compliance" },
  //   { id: "level1_1_Lobbying", label: "Lobbying" },
  //   { id: "level1_1_Loyalty", label: "Loyalty" },
  //   { id: "level1_1_MarketAnalysis", label: "Market Analysis" },
  //   { id: "level1_1_MarketExpansion", label: "Market Expansion" },
  //   { id: "level1_1_Marketing", label: "Marketing" },
  //   { id: "level1_1_Mission", label: "Mission" },
  //   { id: "level1_1_Networking", label: "Networking" },
  //   { id: "level1_1_OperationalControl", label: "Operational control" },
  //   { id: "level1_1_OrganisationStructure", label: "Organisation Structure" },
  //   { id: "level1_1_Partnerships", label: "Partnerships" },
  //   { id: "level1_1_PerformanceManagement", label: "Performance management" },
  //   { id: "level1_1_PostSaleSupport", label: "Post-Sale Support" },
  //   { id: "level1_1_PR", label: "PR" },
  //   { id: "level1_1_PricingStrategy", label: "Pricing Strategy" },
  //   { id: "level1_1_ProcessOptimization", label: "Process Optimization" },
  //   {
  //     id: "level1_1_ProductServiceDevelopment",
  //     label: "Product & Service development",
  //   },
  //   { id: "level1_1_ProductFocus", label: "Product focus" },
  //   { id: "level1_1_ProductKnowledge", label: "Product Knowledge" },
  //   { id: "level1_1_Prospecting", label: "Prospecting" },
  //   { id: "level1_1_Purpose", label: "Purpose" },
  //   { id: "level1_1_QualityControl", label: "Quality Control" },
  //   { id: "level1_1_Recruitment", label: "Recruitment" },
  //   { id: "level1_1_Research", label: "Research" },
  //   { id: "level1_1_Respect", label: "Respect" },
  //   { id: "level1_1_ResultFocus", label: "Result focus" },
  //   { id: "level1_1_RewardRecognition", label: "Reward & Recognition" },
  //   { id: "level1_1_RiskManagement", label: "Risk Management" },
  //   { id: "level1_1_Sales", label: "Sales" },
  //   { id: "level1_1_Satisfaction", label: "Satisfaction" },
  //   { id: "level1_1_SocialResponsibility", label: "Social Responsibility" },
  //   { id: "level1_1_StakeholderRelations", label: "Stakeholder Relations" },
  //   { id: "level1_1_StandardsProtocols", label: "Standards & Protocols" },
  //   { id: "level1_1_StrategicPlanning", label: "Strategic Planning" },
  //   {
  //     id: "level1_1_SustainableProductivity",
  //     label: "Sustainable productivity",
  //   },
  //   { id: "level1_1_TalentManagement", label: "Talent Management" },
  //   { id: "level1_1_TeamCollaboration", label: "Team Collaboration" },
  //   { id: "level1_1_Trust", label: "Trust" },
  //   { id: "level1_1_Values", label: "Values" },
  //   { id: "level1_1_Vision", label: "Vision" },
  //   { id: "level1_1_Winning", label: "Winning" },
  // ]);
  const [coins, setCoins] = useState([]);
  const [selectedLetter, setSelectedLetter] = useState("A");
  const filteredCoins = selectedLetter
    ? coins?.filter((coin) =>
        coin?.label?.toLowerCase()?.startsWith(selectedLetter?.toLowerCase())
      )
    : [];

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleNext = () => {
    navigate("/level1/getting-started");
  };

  const handleBack = () => {
    const key = employeeDetails?.employeeKey; // get key from the local storage
    navigate(`/doSurvey/${key}`);
  };

  const handleAddCoin = () => {
    if (newCoin.trim() && newCoin?.length < 15) {
      const newCoinObj = {
        id: `level1_1_${newCoin.trim()}`,
        label: newCoin.trim(),
      };
      setCoins((prev) => [...prev, newCoinObj]);
      setNewCoin("");
      // setShowModal(false);
    }
  };

  const handleSaveCoin = () => {};
  const handleDeleteCoin = (index) => {
    setCoins(coins.filter((_, i) => i !== index));
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          marginBottom: "40px",
          gap: 2,
        }}
      >
        {/* left */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            textAlign: "left",
            width: { xs: "100%", sm: "100%", md: "40%" },
            marginBottom: { xs: "auto", sm: "auto", md: "60px" },
            paddingY: { xs: "0", sm: "8px", md: "20px" },
            borderRadius: "8px",
            height: "100%",
            overflowY: "auto",
          }}
        >
          <Typography variant="body1" gutterBottom sx={{ lineHeight: "1.3" }}>
            Welcome, everyone! We’re excited to have you here for this engaging
            board game experience.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: "8px" }}>
            Here’s how things will work:
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ fontWeight: 600, lineHeight: "1.3", marginBottom: "8px" }}
          >
            Game Overview:
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: "8px" }}>
            You’ll be playing <strong>two levels</strong>. Each level involves
            placing coins on the board and answering a few questions.
          </Typography>
          <Divider sx={{ marginBottom: "20px" }} />

          <div>
            {/* Heading */}
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 600, lineHeight: "1.3", marginBottom: "16px" }}
            >
              Level 1 Instructions:
            </Typography>

            {/* Tabs for Read and Hear Instructions */}
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="Instructions tabs"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#EFE4B0",
                },
              }}
              sx={{
                borderBottom: "2px solid #ccc",
              }}
            >
              <Tab
                label="Read Instructions"
                sx={{
                  padding: "10px 20px",
                  fontWeight: "bold",
                  backgroundColor: tabIndex === 0 ? "#EFE4B0" : "white",
                  color: tabIndex === 0 ? "black" : "black",
                  borderRadius: "5px",
                  "&.Mui-selected": {
                    backgroundColor: "#EFE4B0",
                    color: "black",
                  },
                }}
              />
              <Tab
                label="Listen to Instructions"
                sx={{
                  padding: "10px 20px",
                  fontWeight: "bold",
                  backgroundColor: tabIndex === 1 ? "#EFE4B0" : "white",
                  color: tabIndex === 1 ? "black" : "black",
                  borderRadius: "5px",
                  "&.Mui-selected": {
                    backgroundColor: "#EFE4B0",
                    color: "black",
                  },
                }}
              />
            </Tabs>

            <Box sx={{ margin: "16px auto" }}>
              {/* Read Instructions Tab Content */}
              {tabIndex === 0 && (
                <>
                  <ol style={{ paddingLeft: "8%", listStyleType: "decimal" }}>
                    <li>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{ marginBottom: "8px" }}
                      >
                        <strong>Objective:</strong>
                      </Typography>
                      <ul
                        style={{ paddingLeft: "8%", listStyleType: "circle" }}
                      >
                        <li>
                          <Typography
                            variant="body1"
                            paragraph
                            sx={{ marginBottom: "8px" }}
                          >
                            Place the coins on the board to represent the
                            current state of the strategy or problem you are
                            aiming to solve.
                          </Typography>
                        </li>
                        <li>
                          <Typography
                            variant="body1"
                            paragraph
                            sx={{ marginBottom: "8px" }}
                          >
                            The board features a <strong>Venn diagram</strong>,
                            and you can place the coins in any area of the
                            diagram as you see fit. Use your best judgment and
                            intuition to decide where each coin belongs.
                          </Typography>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{ marginBottom: "8px" }}
                      >
                        <strong>Timing:</strong>
                      </Typography>
                      <ul
                        style={{ paddingLeft: "8%", listStyleType: "circle" }}
                      >
                        <li>
                          <Typography
                            variant="body1"
                            paragraph
                            sx={{ marginBottom: "8px" }}
                          >
                            This level is <strong>timed</strong>, so please
                            complete it within the time given.
                          </Typography>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{ marginBottom: "8px" }}
                      >
                        <strong>Follow-Up Questions:</strong>
                      </Typography>
                      <ul
                        style={{ paddingLeft: "8%", listStyleType: "circle" }}
                      >
                        <li>
                          <Typography
                            variant="body1"
                            paragraph
                            sx={{ marginBottom: "8px" }}
                          >
                            Once you’ve placed the coins, you’ll answer a few
                            questions based on how the coins are arranged on the
                            board.
                          </Typography>
                        </li>
                        <li>
                          <Typography
                            variant="body1"
                            paragraph
                            sx={{ marginBottom: "8px" }}
                          >
                            <strong>Important:</strong> These questions are{" "}
                            <strong>not timed</strong> — please take your time
                            to think through and answer them carefully.
                          </Typography>
                        </li>
                      </ul>
                    </li>
                  </ol>
                  <Divider sx={{ marginBottom: "20px" }} />

                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontWeight: 600,
                      lineHeight: "1.3",
                      marginBottom: "8px",
                    }}
                  >
                    Next Steps:
                  </Typography>
                  <ul style={{ paddingLeft: "8%", listStyleType: "disc" }}>
                    <li>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ marginBottom: "8px" }}
                      >
                        Once Level 1 is complete, you’ll receive instructions
                        for <strong>Level 2</strong>.
                      </Typography>
                    </li>
                  </ul>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ marginBottom: "8px" }}
                  >
                    Get ready, and have fun with the process! Let’s begin Level
                    1!
                  </Typography>
                </>
              )}

              {/* Hear Instructions Tab Content */}
              {tabIndex === 1 && (
                <ReactAudioPlayer
                  src={`${process.env.REACT_APP_BASE_URL}/videos/level_1_audio.mp3`}
                  autoPlay
                  controls
                  style={{
                    width: "100%",
                    marginTop: "16px",
                  }}
                />
              )}
            </Box>
          </div>
        </Box>

        {/* right */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            textAlign: "center",
            width: { xs: "100%", sm: "100%", md: "60%" },
            marginBottom: "60px",
            position: "relative",
          }}
        >
          {/* Buttons */}
          {isLargeScreen && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <Button
                onClick={handleNext}
                variant="contained"
                sx={{
                  alignSelf: "center",
                  boxShadow: "3px 3px 0px 0px #0F1621",
                  borderRadius: "8px",
                  width: { xs: "100%", sm: "120px" },
                  height: "32px",
                }}
              >
                Next
              </Button>
              <Button
                onClick={handleBack}
                sx={{
                  borderRadius: "8px",
                  border: "1px solid var(--Primary-black, #0F1621)",
                  background: "#FFF",
                  boxShadow: "3px 3px 0px 0px #0F1621",
                  color: "#0F1621",
                  width: { xs: "100%", sm: "120px" },
                  height: "32px",
                  marginTop: "12px",
                  ":hover": {
                    background: "#FFF",
                    boxShadow: "none",
                  },
                }}
              >
                Back
              </Button>
            </Box>
          )}
          {/* Welcome Message */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "24px 0",
                maxWidth: "680px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                justifySelf: "center",
              }}
            >
              <div
                style={{
                  padding: "4px 16px",
                  borderRadius: "999px",
                  background: "#FDEED2",
                  border: "2px solid #F7B73B",
                  marginBottom: "16px",
                }}
              >
                <p>Level 1</p>
              </div>
              <Typography variant="h4" fontWeight="bold">
                How to Play?
              </Typography>
              <Typography sx={{ margin: "16px 0", fontSize: "16px" }}>
                Create a picture of your strategy or problem you are trying to
                tackle by placing coins on the game board. Each level is timed,
                so finish within the given time.
              </Typography>
            </Box>
          </Box>

          {/* body */}
          <DndProvider backend={HTML5Backend}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "center",
                  alignItems: { xs: "center", sm: "center", md: "flex-start" },
                  gap: 4,
                  width: { sm: "100%", md: "100%" },
                  padding: "20px 0",
                }}
              >
                {/* First Div (Image) */}
                <Box
                  sx={{
                    width: { xs: "100%", md: "54%" },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    maxWidth: "400px",
                    maxHeight: "400px",
                  }}
                >
                  {/* Image Container */}
                  <Box
                    sx={{
                      flex: { xs: "1 1 auto", md: "1 1 50%" },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      maxWidth: "100%",
                    }}
                  >
                    <DroppableImage
                      imageSrc={image1}
                      placeholders={placeholders}
                      setCoins={setCoins}
                    />
                  </Box>
                  <Box
                    sx={{
                      marginTop: "40px",
                      display: "flex",
                      alignItems: "center",
                      width: 220,
                      maxHeight: 100,
                    }}
                  >
                    <Typography
                      sx={{ paddingLeft: 2, fontSize: 16, marginRight: "10px" }}
                    >
                      1/2
                    </Typography>
                    <Box
                      sx={{
                        height: "10px",
                        borderRadius: "1rem",
                        width: 100,
                        backgroundColor: "#0F1621", // Filled bar with color
                      }}
                    />
                    <Box
                      sx={{
                        marginLeft: "4px",
                        height: "10px",
                        borderRadius: "1rem",
                        width: 100,
                        backgroundColor: "#D9D9D9",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    ></Box>
                  </Box>
                </Box>

                {/* Second Div (Title and Time) */}
                <Box
                  sx={{
                    width: { xs: "100%", md: "46%" },
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    marginTop: { xs: "40px", sm: "40px", md: 0 },
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    <Typography sx={{ marginRight: "4px" }}>
                      Given time:{" "}
                    </Typography>
                    <Typography
                      sx={{
                        background: "#FAF1F2",
                        color: "#FF5F5F",
                        padding: "2px 4px",
                      }}
                    >
                      <AccessTime
                        sx={{
                          verticalAlign: "middle",
                          marginRight: "4px",
                          fontSize: "16px",
                        }}
                      />
                      15:00
                    </Typography>
                  </div>
                  <hr
                    style={{ border: "1px solid #D7D7D7", margin: "10px 0" }}
                  />
                  {/* Coins Section */}
                  <Stack
                    sx={{
                      flex: { xs: "1 1 auto", md: "1 1 50%" },
                      // paddingX: { xs: 0, sm: 2, md: 4 },
                      gap: 2,
                      marginTop: "10px",
                      justifyContent: "flex-start",
                    }}
                  >
                    {/* Coins Header */}
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        maxWidth: "450px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#747474",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                      >
                        Coins
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowModal(true)}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#FBFFFF",
                            // border: "2px solid #407BFF",
                            fill: "#FBFFFF",
                            strokeWidth: "1.01px",
                            stroke: "#0F1621",
                            filter: "drop-shadow(2.02px 2.02px 0px #0F1621)",
                            width: "clamp(48px, 12vw, 68px)",
                            height: "clamp(48px, 12vw, 68px)",
                            flexShrink: "0",
                            borderRadius: "50%",
                            backgroundColor: "#FBFFFF",
                            fontSize: "clamp(10px, 2vw, 14px)",
                            fontWeight: "bold",
                            color: "#0F1621",
                            transition: "all 0.3s ease-in-out",
                          }}
                        >
                          <Typography sx={{ fontSize: "32px" }}>+</Typography>
                        </div>
                      </Box>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "2%",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        maxWidth: "450px",
                        marginBottom: "16px",
                      }}
                    >
                      {alphabet?.map((letter) => (
                        <Box
                          key={letter}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "24px",
                            height: "32px",
                            color:
                              selectedLetter === letter ? "red" : "#000080",
                            fontWeight: "bold",
                            cursor: "pointer",
                            borderBottom:
                              selectedLetter === letter
                                ? "2px solid red"
                                : "2px solid #000080",
                          }}
                          onClick={() => setSelectedLetter(letter)}
                        >
                          {letter}
                        </Box>
                      ))}
                    </div>

                    {/* Coins Grid */}
                    <div
                      style={{
                        display: "flex",
                        gap: "12px",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        maxWidth: "450px",
                      }}
                    >
                      {filteredCoins?.map((coin, index) => (
                        <DraggableCoin
                          key={coin.id}
                          label={coin.label}
                          index={index}
                        />
                      ))}
                    </div>
                  </Stack>
                  {/* Buttons */}
                  {!isLargeScreen && (
                    <Box
                      sx={{
                        marginTop: "60px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 2,
                        flexDirection: { xs: "column", sm: "row" },
                        width: "100%",
                      }}
                    >
                      <Button
                        onClick={handleBack}
                        sx={{
                          borderRadius: "8px",
                          border: "1px solid var(--Primary-black, #0F1621)",
                          background: "#FFF",
                          boxShadow: "3px 3px 0px 0px #0F1621",
                          color: "#0F1621",
                          width: { xs: "100%", sm: "160px" },
                          height: "40px",
                          marginBottom: { xs: "16px", sm: "0" },
                          ":hover": {
                            background: "#FFF",
                            boxShadow: "none",
                          },
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        onClick={handleNext}
                        variant="contained"
                        sx={{
                          alignSelf: "center",
                          boxShadow: "3px 3px 0px 0px #0F1621",
                          borderRadius: "8px",
                          width: { xs: "100%", sm: "160px" },
                          height: "40px",
                        }}
                      >
                        Next
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </DndProvider>
        </Box>
      </Box>
      {/* modal */}
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            boxShadow: 24,
            px: { xs: 1, sm: 2, md: 4 },
            py: { xs: 2, sm: 4, md: 6 },
            maxWidth: { xs: "100%", sm: "400px", md: "600px" },
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add the New Coin
            </Typography>
            <p
              onClick={() => setShowModal(false)}
              style={{
                cursor: "pointer",
                fontSize: "20px",
                fontWeight: "bold",
                margin: "0",
                transition: "color 0.3s ease",
              }}
            >
              X
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              borderRadius: "8px",
              border: "1px solid #B4B4B4",
              padding: "12px",
              marginBottom: "20px",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                maxWidth: "calc(100% - 120px)",
                borderRight: "1px solid #E1E1E1",
                paddingRight: "15px",
              }}
            >
              <TextField
                fullWidth
                value={newCoin}
                onChange={(e) => setNewCoin(e.target.value)}
                sx={{
                  mb: 1,
                  maxWidth: "calc(100% - 60px)",
                  "& .MuiInputBase-root": {
                    border: "none",
                    padding: "0",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    display: "none",
                  },
                  "& .MuiInputBase-input": {
                    color: "#333",
                    padding: "4px",
                  },
                }}
                placeholder="Enter coin name"
              />
              {/* coins Count */}
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  marginLeft: "8px",
                  whiteSpace: "nowrap",
                }}
              >
                {newCoin?.length} / 15
              </Typography>
            </div>

            <Button
              onClick={handleAddCoin}
              sx={{
                minWidth: "auto",
                padding: "6px 12px",
                background: "transparent",
                color: "#0F1621",
                fontSize: "16px",
                fontWeight: 700,
                textTransform: "capitalize",
                ":hover": {
                  background: "#FFFFFF",
                },
                width: "100%",
                maxWidth: "120px",
              }}
              disabled={newCoin.length === 0 || newCoin.length > 15}
            >
              Add Coin
            </Button>
          </div>

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
            {coins?.map((coin, index) => (
              <Box
                key={coin.id}
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  width: "clamp(48px, 12vw, 68px)",
                  height: "clamp(48px, 12vw, 68px)",
                  borderRadius: "50%",
                  backgroundColor: "#FBFFFF",
                  boxShadow: "2px 2px 0px #0F1621",
                  color: "#0F1621",
                  fontWeight: "bold",
                  textAlign: "center",
                  padding: "10px",
                  wordWrap: "break-word",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  lineHeight: "1.2",
                }}
              >
                <span
                  style={{
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "10px",
                  }}
                >
                  {coin.label}
                </span>
                <Button
                  onClick={() => handleDeleteCoin(index)}
                  sx={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    fontSize: "12px",
                    backgroundColor: "red",
                    color: "white",
                    minWidth: "auto",
                    padding: "2px 5px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "darkred" },
                  }}
                >
                  ×
                </Button>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              onClick={handleSaveCoin}
              variant="contained"
              sx={{
                borderRadius: "8px",
                boxShadow: "3px 3px 0px 0px #0F1621",
                textTransform: "capitalize",
                padding: "8px 24px",
              }}
            >
              Save Coins
            </Button>
          </Box>
        </Box>
      </Modal>
    </Layout>
  );
};

export default HowToPlay;
